import styled from 'styled-components';

import {mediaQueriesSizes, colors} from "../../../assets/styles/GlobalStyles.js";
import Typography from '../../Typography';

export const StyledBlockWrapper = styled.div`
  background-image:linear-gradient(180deg, ${colors.secondaryAlternate} 0%, ${colors.primary} 50%, ${colors.primary} 100%);

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    background: ${colors.secondaryAlternate};
  }
`

export const StyledBlock = styled.div`
  padding-top: 60px;
  background-image:url('/static-images/what-is-a-clinical-trial-4-mobile.svg'), linear-gradient(180deg, ${colors.secondaryAlternate} 0%, ${colors.primary} 100%);;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 200vw 200vw;
  min-height: 200vw;

  @media (min-width: ${mediaQueriesSizes.sm}px) {
    padding-top: 170px;
    min-height: 0;
  }
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding-top: 200px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {

  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    background-position: top 0 right -170px;
    padding-top: 200px;
    background-image:url('/static-images/what-is-a-clinical-trial-3.webp');
    background-repeat: no-repeat;
    background-size: 85%;
   
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    background-size: 75%;
    background-position: top 0 right -40px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    background-size: 70%;
    background-position: top 0 right -20px;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 50px;
    
  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin-bottom: 20px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin: 0 auto 20px;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin-left: 0;
    margin-right: 0;
    max-width:440px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    margin-bottom: -20px;
    max-width:440px;
  } 
`

export const TrialsWrapper = styled.div`
  padding-bottom: 150px;  
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin: auto;
    padding-bottom: 50px; 
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    margin-left: auto;
    margin-right: 0;
    max-width:670px;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    max-width:740px;
  }
  

`;

export const TrialsContainer = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;