import React from 'react';

import { StepStyle, StepCount, StepTitle } from './style';
import Typography from '../Typography';

const Step = ({ children, title, count }) => {
  return (
    <StepStyle>
      <div>
        <StepCount type="heading1">{count}</StepCount>
      </div>
      <div>
        <StepTitle type="heading4">{title}</StepTitle>
        <Typography type="heading4">
          {children}
        </Typography>
      </div>
    </StepStyle>
  )
}

export default Step;
