import styled from 'styled-components';

import {mediaQueriesSizes,colors} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  padding: 65px 0 15px;
  background: ${colors.primary};


  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 75px 0 125px;
  }

  h2{
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    text-align: left;
    text-transform: capitalize;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.1em;
      text-align: left;
      text-transform: uppercase;    
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 16px;
      line-height: 19px;      
    }
  }

`;


export const FAQWrapper = styled.div`
    width: 100%;
    margin: 35px 0 30px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin: 30px 0;
    }
    
`;

export const Faq = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background:white;
  padding: 40px 25px;
  margin-bottom:10px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  min-height: 128px;


  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 55px 60px;
    margin-bottom:15px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    justify-content: start;

    &.active {
      flex-direction: row;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    padding: 60px 60px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    padding: 65px 60px;
  }

  @media (hover: hover) {
    &:hover{
      opacity: 0.6;
    }
  }
  

  h3{
  
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    position: relative;
    padding-right:50px;    

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 19px;
      line-height: 32px;
      font-weight: 300;
      padding-right:40px;    
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      font-size: 32px;
      line-height: 32px;
    } 

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 36px;
      line-height: 36px;
    }    
  }
  
  span.info{
    transition: 0.25s;
    position: absolute;
    right: 25px;
    width: 42px;
    height: 42px;
    display:block;
    border:black solid 1px;
    border-radius: 50%;
    display:flex;
    cursor:pointer;
    
    @media (min-width: ${mediaQueriesSizes.md}px) {
      right: 40px;
      top: 51px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      top: 56px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      top: 64px;
    }

    &:before,
    &:after{
      position: absolute;
      top:50%;
      left:calc(50% - 7.5px);
      width:15px;
      height:1px;
      display:block;
      background:black;
      content:"";
      transition: 0.25s;

    }
    
    &:before{
      transform:rotate(90deg);
    }
  }

  
  p {
    height: 0;
    overflow: hidden;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 0;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 14px;
      line-height: 23px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 16px;
      line-height: 26px;
    }      
  }
  
  
  &.active{
    &:hover {
      opacity: 1;

    }
    span{
      transform:rotate(45deg);
      top:30px;
    }
    p{
      height: auto;
      padding: 50px 0;
    }
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
        display:flex;
        
        h3{
          width: 25%;
          font-size: 22px;
          font-weight: 300;
          line-height: 36px;

          @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 19px;
            line-height: 32px;
          }

          @media (min-width: ${mediaQueriesSizes.xxxl}px) {
            font-size: 22px;
            line-height: 36px;            
          }
        }
        p{
          font-size: 22px;
          font-weight: 300;
          line-height: 36px;
          width: 60%;
          padding: 0;

          @media (min-width: ${mediaQueriesSizes.md}px) {
            font-size: 19px;
            line-height: 32px;
          }

          @media (min-width: ${mediaQueriesSizes.xxxl}px) {
            font-size: 22px;
            line-height: 36px;            
          }          

        }
        
    }

  }
  
  
`;

export const Container = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
