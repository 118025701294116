import axios from 'axios';
import { getUrlParameter } from './url';

function getUserLanguage() {
  return new Promise((resolve) => {
    const nav = window.navigator;
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

    if (Array.isArray(nav.languages)) {
      for (let i = 0; i < nav.languages.length; i += 1) {
        const language = nav.languages[i];

        if (language && language.length) {
          resolve(language);
        }
      }
    }

    for (let i = 0; i < browserLanguagePropertyKeys.length; i += 1) {
      const language = nav[browserLanguagePropertyKeys[i]];

      if (language && language.length) {
        resolve(language);
      }
    }

    resolve(null);
  });
};

export const getUsersCountry = async () => {
    try {
      const details = await axios(`https://api.ipdata.co/${getUrlParameter('ip')}?api-key=${process.env.GATSBY_API_KEY}`);
      const language = await getUserLanguage();
  
      return {
        ...details.data,
        language_code: language,
      };
    } catch (error) {
      return {
        country_code: null,
        language_code: null,
      };
    }
};

export const getCountry = function() {
  return typeof window !== 'undefined' && window ? localStorage.getItem('country') : null;
};

export const setCountry = function(country) {

  if (typeof window !== 'undefined') {
    window.addEventListener('CookiebotOnConsentReady', () => {
      if (window.CookieConsent && window.CookieConsent.consent) {
        const consent = window.CookieConsent.consent;
        if (consent.necessary === true) {
          return typeof window !== 'undefined' && window ? localStorage.setItem('country', country) : null;
        }
      }
    }, false);
  }
};

