import styled, { keyframes } from 'styled-components';
import {mediaQueriesSizes,colors} from "../../assets/styles/GlobalStyles";

export const ScrollDownBtn = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  bottom: 5%;
  left: 2%;
  z-index: 4;
  color: white;
  text-transform: uppercase;
  font-size: 22px;
  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    display: flex;
  }
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  display: none;
`

export const StyledBlock = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${colors.purple};
  background-image: url(${props => props.imgMobile});
  background-position: top center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  position: relative;
  background-size: cover;
  z-index: 10;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    background-image: url(${props => props.img});
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
  }

  &.navbar {
    height: calc(100vh - 66px);
    height: calc(var(--vh, 1vh) * 100 - 66px);

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      height: calc(100vh - 72px);
      height: calc(var(--vh, 1vh) * 100 - 72px);
      min-height: 500px;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      height: calc(100vh - 94px);
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      height: calc(100vh - 118px)
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 70px;
    width: 100%;

    @media (max-width: ${mediaQueriesSizes.sm}px) {
      padding: 0 35px;

      html[lang=es-US] & {
        bottom: 80px;
      }
    }
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      margin-bottom: 0;
      position: relative;
      bottom: auto;
      z-index: 1;         
      bottom: -40px;
      width: 1230px;
    }
    
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      padding-left: 15px;
      padding-right: 15px;         
      width: 1570px;
    }
    
    .button-container{
      margin-top:32px;
      display:none;
      @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
        display: inline-block; 
      }  
      
      svg{
        color: black;
      }
    }
  }
`;

export const NavBar = styled.div`
  background: linear-gradient(180deg, #F4FAFC 0%, #D4EDF4 100%);
  height: 66px;
  width: 100%;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    height: 72px;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    height: 94px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    height: 118px;
  }
`;

export const Subtitle = styled.h1`
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: center;
    color: ${colors.white};
    margin-bottom: 20px;

    html[lang=es-US] & {
      margin-bottom: 10px;
    }

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      text-align: left;
      font-size: 32px;
      font-weight: 600;
      line-height: 75px;

      html[lang=es-US] & {
        margin-bottom: 20px;
      }      
    }
`;

export const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  color: ${colors.white};
  margin: 0;
  text-align: center;
  
  @media (max-width: ${mediaQueriesSizes.sm}px) {
    html[lang=es-US] & {
      font-size: 22px;
      line-height: 1.2;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxs}px) {
    font-size: 34px;
    text-align: left;
    line-height: 1;
  }

  @media (min-width: ${mediaQueriesSizes.xs}px) {
    font-size: 47px;
    line-height: 46px;
    max-width: 100%;
    text-align: left;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {

  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
  }

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    font-size: clamp(40px, 3.75vw, 60px);
    font-weight: 600;
    text-align: left;
    line-height: 1;
    letter-spacing: 0;
    max-width: 30vw;    
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    font-size: 70px;
    max-width: 700px;
    
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    font-size: 80px;
    line-height: 1;
    max-width: 760px;
    
    @media (max-height: 700px) {
      font-size: 60px;
    }

  }
`;

export const Copy = styled.p`
  font-family: 'PolySans';
  font-style: normal;
  font-weight: 400;
  color: ${colors.white};
  font-size: 16px;
  line-height: 16px;
  text-shadow: 0px 0px 13px rgb(0 0 0);

  @media (min-width: ${mediaQueriesSizes.xxs}px) {
    font-size: 19px;
    line-height: 20px;
    margin: 0 ;
  }
  
  @media (min-width: ${mediaQueriesSizes.xs}px) {
    font-size: 20px;
    line-height: 20px;
    margin: 10px 0 0 ;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    font-size: 28px;
    line-height: 28px;
    margin-top: 24px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    font-size: 32px;
    line-height: 32px;
    margin-top: 28px;
  }

`;

const VideofadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const VideoBlock = styled.video`
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    animation-name: ${VideofadeIn};
    animation-duration: 0.8s;
    animation-iteration-count: 1;
    display: block;
    object-fit: cover;
    object-position: center;
  }
`;
