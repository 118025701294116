import React, {useEffect, useState, useRef} from 'react';

import Info from "./assets/info.svg";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {
    StyledBlock,
    ListWrap,
    CirclesContainer
} from './styles';

gsap.registerPlugin(ScrollTrigger);

const BulletList = ({list, backgroundImage}) => {

    const [infoSelected, setInfoSelected] = useState(false);
    const infoSVG = decodeURIComponent(Info);

    const createMarkUp = (content) => {
        return {__html: content};
    }

    useEffect(() => {
        gsap.from(elementRefs, {
            scrollTrigger: elementRefs[0],
            scaleX: 0,
            duration: 1,
            transformOrigin: "left center",
            ease: "ease-in-out",
            stagger: 0.3
        });
    }, []);

    const addInfoBtn = (item, key) => {
        if (item.info) {
            return (
                <span

                    className={
                        key === infoSelected ? 'info active' : 'info'
                    }

                    onClick={() => {
                        if (infoSelected === key) {
                            setInfoSelected(false)
                        } else {
                            setInfoSelected(key)
                        }
                    }}>
          <span
              className={
                  key === infoSelected ? 'speech-bubble active' : 'speech-bubble'
              }
          >{item.info}</span>
        </span>
            )
        } else {
            return (<></>);
        }
    };

    const elementRefs = [];

    const setRef = (ref) => {
        elementRefs.push(ref);
    }

    return (
        <StyledBlock className={'component-BulletList'}>
            <div className={'container'}>
                <div className={'row'}>
                    <ListWrap className={`column column--narrow`}
                              bgimg={'/static-images/tick.png'}
                              close={'/static-images/close.png'}
                              info={infoSVG}>
                        <h3>{list.title}</h3>
                        <ul>
                            {list.items.map((item, key) => {
                                return (
                                    <li key={key}>
                                        <span className={'line'} ref={setRef}></span>
                                        <span dangerouslySetInnerHTML={createMarkUp(item.copy)}/>
                                        {addInfoBtn(item, key)}
                                    </li>
                                )
                            })}
                            <li>
                                <span className={'line'} ref={setRef}></span>
                            </li>
                        </ul>
                    </ListWrap>
                </div>
            </div>
           <CirclesContainer backgroundImage={backgroundImage} />
        </StyledBlock>
    );
}

export default BulletList;
