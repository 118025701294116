import React, {useEffect} from 'react';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import { navigate } from "gatsby";
import { Button } from "@lxlabs/faze-screener-components-library";
import countries from "../../../data/countries";
import { setCountry } from "../../utils/location";
import { gsap } from "gsap";

import {
  StyledBlock,
  BackgroundBubble
} from './styles';

const CountrySelector = ({title, copy}) => {

  useEffect(() => {

    gsap.from(elementRefs, {
      opacity: 0,
      delay: 0.5,
      duration: 1, 
      ease: "none",      
      stagger: 0.5
    });
  }, []);

  // save the country code to local storage and redirect to where the user wants to go
  const location = useLocation();
  const saveCountry = (country) => {
    const redirectTo = location?.state?.redirectTo ?? '/' + country;
    setCountry(country);
    navigate(redirectTo);
  }

  const elementRefs = [];

  const setRef = (ref) => {
    elementRefs.push(ref);
  }

  // create buttons for the country selector
  const countryButtons = countries.map((country, i) => 
  
    <li key={i} ref={setRef}>
      <Button
          id={'country-select-'+country.url}
          className={'country-select country-select-'+country.url}
          cyAttribute={'country-select-'+country.url}
          key={i} 
          onClick={() => saveCountry(country.url)}
          type={'outline'}
          iconAlign={'left'}
          iconFlag={true}
          ariaLabel={country.title}
          label={<><img src={country.img} alt={''} />{country.title}</>}
          />
    </li>
  )

  return (
    <StyledBlock>
      <div className={'background'}>
        <BackgroundBubble bubble1 />         
        <BackgroundBubble bubble2 />
      </div>    
      <div className={'container'}>
        <div className={'row'}>
          <div className={'column'}>
            <div className={'content'}>
              <h1>{title}</h1>
              <p>{copy}</p>
              <ul>
                {countryButtons}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </StyledBlock>
  );

}

CountrySelector.propTypes = {
  title: PropTypes.string
};

CountrySelector.defaultProps = {};

export default CountrySelector;
