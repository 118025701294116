import { Form } from "@lxlabs/faze-screener-components-library"
import React, {useEffect} from "react";
import {navigate} from "@reach/router";
import FooterController from "../Footer";


const CandidateForm = (contentCopy) => {

  let submittedAnswers = localStorage.getItem('submittedAnswers');
  let selectedSite = localStorage.getItem('selectedSite');

  if (submittedAnswers !== null) {
    submittedAnswers = JSON.parse(submittedAnswers);
    submittedAnswers = Object.values(submittedAnswers);
  }

  contentCopy = contentCopy.contentCopy;

  let formQuestions = [
    {
      "label": contentCopy.formLabels.firstname,
      "name": "firstName",
      "type": "text",
      "validation": {
        "required": contentCopy.errorCopy.pleaseEnter,
        "minLength": {
          "value": 2,
          "error": contentCopy.formLabels.minimum_char_error
        },
        "match": {
          "value": contentCopy.formValidation.name_match,
          "error": contentCopy.errorCopy.pleaseEnter
        }
      }
    },
    {
      "label": contentCopy.formLabels.lastname,
      "name": "surname",
      "type": "text",
      "validation": {
        "required": contentCopy.errorCopy.pleaseEnter,
        "minLength": {
          "value": 2,
          "error": contentCopy.formLabels.minimum_char_error
        },
        "match": {
          "value": contentCopy.formValidation.name_match,
          "error": contentCopy.errorCopy.pleaseEnter
        }
      }
    },
    {
      "label": contentCopy.formLabels.dateOfBirth_required,
      "type": "dob",
      "name": "dob",
      "validation": {
        "required": contentCopy.errorCopy.pleaseEnter,
      },
      "ageRequirement":"18",
      "fieldLabels": {
        "dayMonthYear":  contentCopy.formLabels.dob,
        "months": contentCopy.monthList
      }
    },
    {
      "label": contentCopy.formLabels.sex,
      "name": "gender",
      "type": "multi-choice",
      "choices": [
        {
          "label": contentCopy.formLabels.male,
          "value": "m"
        },
        {
          "label": contentCopy.formLabels.female,
          "value": "f"
        },
        {
          "label": contentCopy.formLabels.preferNotToSay,
          "value": "pnts"
        }
      ]
    },
    {
      "name": contentCopy.formLabels.address,
      "validation": {
          "required": contentCopy.errorCopy.pleaseEnter
      },
      "fields":[
          {
              "type": "text",
              "name": "addressPostcode",
              "placeholder": "12345",
              "label": contentCopy.formLabels.address_zipcode,
              "error": contentCopy.errorCopy.zipcode
          },
          {
              "type": "select",
              "name": "addressRegion",
              "label": contentCopy.formLabels.address_state,
              "error": contentCopy.errorCopy.state,
              "states": contentCopy.states
          }
      ]
    },
    {
      "label": contentCopy.formLabels.email_required,
      "name": "email",
      "type": "email",
      "validation": {
        "required": contentCopy.errorCopy.pleaseEnter,
        "email": contentCopy.errorCopy.email,
        "minLength": {
          "value": 6,
          "error": contentCopy.formLabels.minimum_char_error
        },
      }
    },
    {
      "label": contentCopy.formLabels.phone_required,
      "name": "phone",
      "type": "tel",
      "validation": {
        "required": contentCopy.errorCopy.pleaseEnter,
        "match": {
          "value": contentCopy.formValidation.phone_match,
          "error": contentCopy.errorCopy.pleaseEnter
        },
        "minNumbers": {
          "value": 8,
          "error": contentCopy.errorCopy.pleaseEnter
        }
      },
    },
    {
      "label": contentCopy.formLabels.mobile,
      "name": "mobile",
      "type": "tel",
      "error": contentCopy.errorCopy.pleaseEnter,
      "validation": {
        "match": {
          "value": contentCopy.formValidation.phone_match,
          "error": contentCopy.errorCopy.pleaseEnter
        },
        "minNumbers": {
          "value": 8,
          "error": contentCopy.errorCopy.pleaseEnter
        }
      },       
    },
    {
      "label": contentCopy.formLabels.hcp_refferal,
      "name": "referredByHcp",
      "type": "multi-choice",
      "choices": [
        {
          "label": contentCopy.formLabels.hcp_refferal_yes,
          "value": true
        },
        {
          "label": contentCopy.formLabels.hcp_refferal_no,
          "value": false
        },
      ],
      "validation": {
        "required": true
      }
    },
    {
      "label": contentCopy.formLabels.contact,
      "name": "preferredContact",
      "type": "multi-choice",
      "validation": {
        "required": true,
      },
      "choices": [
        {
          "label": contentCopy.formLabels.contact_morning,
          "value": "morning"
        },
        {
          "label": contentCopy.formLabels.contact_lunch,
          "value": "lunchtime"
        },
        {
          "label": contentCopy.formLabels.contact_evening,
          "value": "evening"
        }
      ]
    },
    {
      "label": contentCopy.formLabels.contact_method,
      "name": "preferredContactMethod",
      "type": "select-multiple",
      "choices": [
        {
          label: contentCopy.formLabels.contact_method_email,
          value: "email"
        },
        {
          label: contentCopy.formLabels.contact_method_sms,
          value: "sms"
        },
        {
          label: contentCopy.formLabels.contact_method_phone,
          value: "phone"
        }
      ],
      "validation": {
        "required": true
      }
    },

    {
      type: 'checkbox-group',
      backgroundColor: 'light',
      intro: contentCopy.formLabels.consent_location_intro,
      questions: [
        {
          name: 'consentLocationProcessing',
          label: contentCopy.formLabels.consent_location,
          validation: {
            required: contentCopy.formLabels.required,
          }, 
        }
      ]
    },
    {
      type: 'checkbox-group',
      intro: contentCopy.formLabels.consent,
      backgroundColor: 'light',
      questions: [
        {
          name: 'futureTrialsRespiratory',
          label: contentCopy.formLabels.future_respiratory_trials
        },
        {
          name: 'futureTrialsInfectious',
          label: contentCopy.formLabels.future_infectious_disease_trials
        },
      ]
    },
    {
      type: 'checkbox-group',
      intro: '',
      backgroundColor: 'light',
      questions: [
        {
          name: 'consentContactFeedback',
          label: contentCopy.formLabels.consent_feedback
        },
      ]
    }
  ];

  // add any localised configuration changes here...
  // en-GB
  // - remove postal code placeholder
  // - remove required from when to contact field
  if (contentCopy.config.lang === 'en-GB') {
    formQuestions[4].fields[0].placeholder = "";
    formQuestions[10].validation.required = false;
  // es-US
  // - remove required from when to contact field
  } else if (contentCopy.config.lang === 'es-US') {
    formQuestions[10].validation.required = false;
  }

  return (
    <>
      <Form
        typeOfForm={contentCopy.formPage.typeOfForm}
        copyData={contentCopy.formPage.copyData}
        formQuestions={formQuestions}
        candidateReady={true}
        errorHandle={(error) => {            
          const response = error.response.data;

          if (response.error.duplicate) {
              navigate(`/${contentCopy.config.lang}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.alreadyregistered}`,{
                  state: { response: response } 
              });
          }
        }}
        button={{
          submitLabel: contentCopy.buttonLabels.submit,
          changeSiteLabel: contentCopy.buttonLabels.changeSite,
          completionHandle: () => {
            navigate(`/${contentCopy.config.lang}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.eligible}/${contentCopy.config.urls.thankyou}`);
          },
          changeSiteHandle: () => {
            navigate(`/${contentCopy.config.lang}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.eligible}`);
          }
        }}
        submittedAnswers={submittedAnswers}
        siteData={selectedSite}
        logo={{
          style: contentCopy.logo,
          link: contentCopy.logoLink
        }}
        apiUrl={process.env.GATSBY_SCREENER_API_URL}
        langCode={contentCopy.config.lang}
        studyCode={contentCopy.config.studyCode}
        unit={contentCopy.config.unit}
        className={'show-site-selector'}
        consentFields={[
          'consentLocationProcessing',
          'futureTrialsRespiratory',
          'futureTrialsInfectious',
          'consentContactFeedback'
        ]}
        suppliedConsentField={'consentLocationProcessing'}
      />
      <FooterController addLowerMargin={true} button={false}/>
    </>
  )

}

export default CandidateForm;
