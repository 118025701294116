import styled from 'styled-components';

import {colors, mediaQueriesSizes} from "../../../assets/styles/GlobalStyles.js";


export const Wrapper = styled.div`
  width:100%;
  position:relative;  
  background: ${colors.primaryAlternate};
  padding:15px 0 60px;
  
  @media (min-width:${mediaQueriesSizes.lg}px) {
    background: ${colors.primaryAlternate};
    padding:95px 0;
  }

  .about-btn{
    @media (min-width:${mediaQueriesSizes.xl}px) {
      max-width:320px;
    }
  }
  
  .container {
    z-index: 1;
    position: relative;
  }

  h3{
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;

    
    @media (min-width:${mediaQueriesSizes.xl}px) {

    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform:uppercase;
    
    }

    @media (min-width:${mediaQueriesSizes.xxxl}px) {
      font-size: 16px;
      line-height: 19px;      
    }
  }
`;

export const ContentWrap = styled.div`
    width: 100%;  

    @media (min-width:${mediaQueriesSizes.xl}px) {
      display:flex;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 92%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      width: 100%;
    }    
`;

export const Cards = styled.div`
  margin-bottom:60px;
`;

export const CardWrap = styled.div`
  margin-top: 35px;
`;

export const BubbleWrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const Bubble = styled.div`
  background:white;
  border-radius:50%;
  margin-left:auto;
  position:relative;
  z-index:5;
  display:none;
  margin-top: 55px;
  
  img{
    margin:0 auto;
    width: auto;
    max-width: 90%;
  }
  
  &.active{
    @media (min-width:${mediaQueriesSizes.xl}px) {
    display: flex;
    align-items: center;
    }
  }

  @media (min-width:${mediaQueriesSizes.xl}px) {
    width: 450px;
    height: 450px;
    img {
      max-width: 72%;
    }
    :first-child { 
    img {
      margin-top: -10%;
    }
  }

  }
  @media (min-width:${mediaQueriesSizes.xxl}px) {
    width: 690px;
    height: 690px;
  }

 

`;

export const Circle = styled.div`
  position: absolute;
  display:none;
  z-index: 0;
  border-radius: 50%;    

  @media (min-width:${mediaQueriesSizes.xxl}px) {
    display:block
  }

  &.side{
    top: 10%;
    right: 2vw;
    height: 12vw;
    width: 12vw;   
    background: linear-gradient(180deg, ${colors.white} 0%, rgba(233, 239, 241, 0) 100%);
    transform: rotate(77.86deg);    
  }

  &.top{
    top: 22%;
    left: 40vw;
    height: 10vw;
    width: 10vw;     
    background: linear-gradient(180deg, #DAF5FE 0%, rgba(233, 239, 241, 0) 100%);
    transform: rotate(-17.75deg);
  }

  &.blue{
    bottom: -6.5%;
    right: 4vw;
    height: 23vw;
    width: 23vw;     
    background: linear-gradient(180deg, ${colors.secondaryAlternate} 0%, rgba(233, 239, 241, 0) 100%);
    transform: rotate(146.26deg);

  }

`;

export const Card = styled.div`
  
  width:100%;
  height:auto;
  padding: 30px 25px 40px;
  
  background: white;
  cursor: pointer;
  transition:0.4s;
  border-radius:20px;
  margin-bottom: 20px;
  
  &.active{
    opacity:1;
    box-shadow: 3px 3px 10px 0px rgba(149, 220, 239, 0.5);
  }
  
  img{
    height: 200px;
    width: 200px;
    margin:0 auto 25px;
    display:block;
  }
  
  h2{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 25px;

  }
  
  p{
    
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  @media (min-width:${mediaQueriesSizes.lg}px) {
    padding-bottom: 50px;
    padding-right: 60px;
  }

  @media (min-width:${mediaQueriesSizes.xl}px) {
    width: 550px;
    height: auto;
    opacity: 0.3;
    padding: 140px 60px;
    display: flex;
    align-items: center;
    
    &:hover{
      opacity:0.6;
    }

    img{
      display:none;  
    }
    
    h2{
      margin-bottom: 21px;
      font-size: 32px;
      font-style: normal;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: left;
    }
    
    p{
      font-size: 19px;
      font-weight: 300;
      line-height: 32px;
      text-align: left;
    }
    
  }
  @media (min-width:${mediaQueriesSizes.xxl}px) {
    width: 650px;
  }
  
  @media (min-width:${mediaQueriesSizes.xxxl}px) {
    h2{
      font-size: 36px;
      line-height: 36px;
    }
    
    p{
      font-size: 22px;
      line-height: 36px;

    }
  }
  
  
`;


export const ButtonWrap = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
