import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {Button} from "@lxlabs/faze-screener-components-library";
import {PageContext} from "../../Context/pageContext";

import {
    StyledBlock,
    Title,
    Copy,
    NavBar,
    Subtitle,
    VideoBlock,
    ScrollDownBtn,
} from './styles';

const FullScreenMedia = ({ mediaURL, mediaURLMobile, title, subtitle, copy, navBar, videoUrl, videoLoop, button }) => {

    const pageContext = useContext(PageContext);

    const debounce = function(fn, ms) {
        let timer;
        return function() {
            clearTimeout(timer)
            timer = setTimeout(function() {
                timer = null;
                fn.apply(this, arguments);
            }, ms)
        };
    }

    const updateVh = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() => {
        updateVh();
        const debouncedResize = debounce(() => {
            updateVh();
        }, 500);
        window.addEventListener('resize', debouncedResize, {passive: true});
        return () => {
            window.removeEventListener('resize', debouncedResize);
        }
    }, []);

    const ScrollDown = () => {
        window.scrollTo({
            top: window.innerHeight,
            behavior: "smooth",
        });
    }

    return (
        <>
        {navBar &&
            <NavBar/>
        }
        <StyledBlock img={mediaURL} imgMobile={mediaURLMobile} className={navBar ? 'navbar' : ''}>
            {videoUrl &&
                <>
                <VideoBlock loop={videoLoop} src={videoUrl} autoPlay={true} playsInline={true} muted={true} controls={false} preload="true" type="video/mp4"></VideoBlock>
                </>
            }
            {pageContext.language === "en-US" || pageContext.language === "en-GB" ?
                <ScrollDownBtn onClick={()=>{
                    ScrollDown();
                }} className={'scroll-down-btn'} data-cy="scroll-down-button">
                    Scroll Down
                </ScrollDownBtn>
            :   <></>
            }
            <div className='container'>
                <div className='row'>
                    {subtitle &&
                    <Subtitle>{subtitle}</Subtitle>
                    }
                    {title &&
                    <Title dangerouslySetInnerHTML={{ __html: title}} />
                    }
                    {copy &&
                    <Copy>{copy}</Copy>
                    }
                    {button &&
                        <div className={'button-container'}>
                            <Button
                                className={'sign-up-button'}
                                label={button.label}
                                href={button.href}
                                icon={'ArrowDiagonalUpRight'}
                                cyAttribute="hero_sign-up-now-button"
                            />
                        </div>
                    }
                </div>
            </div>
        </StyledBlock>
        </>
    );
}

FullScreenMedia.propTypes = {

    /**
     * Media Type
     */
    mediaType: PropTypes.oneOf(['video', 'image']).isRequired,

    /**
     * Media URL
     */
    mediaURL: PropTypes.string,

};

FullScreenMedia.defaultProps = {
    videoLoop: false
};

export default FullScreenMedia;
