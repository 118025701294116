import styled from 'styled-components';

import {mediaQueriesSizes,colors} from "../../../assets/styles/GlobalStyles.js";


export const StyledBlock = styled.div`
  height: auto;
  background: ${colors.primaryAlternate};
  display: flex;
  align-items: center;
  padding-top:120px;
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding-top:50px;
    min-height: 100vh;
  }
  
  .row{
      display:flex;
      flex-direction: column; 
      width: 100%;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        flex-direction: row;
      }
  
      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        width: 92%;
        margin-left: auto;
        margin-right: auto;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        width: 100%;
      }      
  }

  .column{
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 50px;
    
    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin-bottom: 0;
      width:38%;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-bottom: 100px;
    }

    &.column-image {
      @media (min-width: ${mediaQueriesSizes.md}px) {
        margin-bottom: 0;
        width: 62%;
      }      
    }
  }
  
  h1{
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
    margin-bottom: 15px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 44px;
      font-weight: 400;
      line-height: 53px;
      letter-spacing: 0em;
      margin-bottom: 20px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 50px;
      line-height: 60px;          
    }
  }
  
  p{
    font-weight: 400;
    line-height: 32px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 19px;
      font-weight: 400;
      line-height: 32px;
      max-width: 480px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 22px;
      line-height: 36px;      
    }    
  }
  
  img{
    width: auto;
    max-width: 100%;
  }
`;

export const Container = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
