import styled from "styled-components";

import { colors, mediaQueriesSizes } from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  background: ${colors.secondaryAlternate};
  padding: 140px 0;
  position: relative;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    min-height: 100vh;
   
  } 

  .background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;      
  }

  .row {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    padding: 50px 0 0;
    position: relative;
    z-index: 1;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      align-items: start;
      background: none;      
      flex-direction: row;
      margin-top: 50px;
      padding: 0;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin-top: 40px;
    } 

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-top: 125px;
    } 

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      margin-top: 190px;
    }    
  }

  .text-column {  
    order: 2;  
    padding: 20px 0;
    width: 100%;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      align-items: center;
      order: 1;
      padding: 20px 0;
      position: relative;
      width: 50%;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 45%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      width: 56%;
    }  

    h1 {
      font-size: 16px;
      font-weight: 300;
      line-height: 16px;
      margin: 30px 0 0;
      max-width: 710px;
      text-align: left;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.1em;
        line-height: 17px;
        margin: 0;      
      }  

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      margin: 30px 0 0;   
      max-width: 800px;
      text-align: left;
      margin-top: 29px;

      @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 28px;
        line-height: 34px;
        margin-top: 32px;
      } 

      @media (min-width: ${mediaQueriesSizes.xl}px) {
        font-size: 38px;
        line-height: 46px;
      } 

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        font-size: 44px;
        line-height: 52px;
      }          
      
      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 50px;
        line-height: 60px;
      }          
    }

  }

  .image-container {
    display: flex;
    justify-content: center;
    order: 1;
    padding: 0 20px;
    width: 100%;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      order: 2;
      margin-bottom: 16vw;
      margin-top: -30px;
      width: 43.1%;
      margin-left: auto;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin-top: -40px;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-top: -50px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      margin-top: -60px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      margin-top: -163px;
      width: 50%;
    }

    img {
      max-width: 250px;
      width: 100%;


      @media (min-width: ${mediaQueriesSizes.lg}px) {
        max-width: 400px;
      }

      @media (min-width: ${mediaQueriesSizes.xl}px) {
        max-width: 450px;
      }

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        max-width: 500px;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        max-width: 540px;
      }      
    }    
  }
`;

export const CirclesContainer = styled.div`
  display: none;

  @media(min-width: ${mediaQueriesSizes.xl}px) {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    background-image:url('/static-images/featured-trial-blue-circles-1.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom -200px right 0;
    background-size: 428px 766px;
    z-index: 0;
  }

  @media(min-width: ${mediaQueriesSizes.xxl}px) {
    background-position: bottom -260px right 0;
    background-size: 514px 920px;
  }
`;

export const BackgroundBubble = styled.div`  
  border-radius: 50%;
  position: absolute;

${props => props.bubble1 && `
  background: ${colors.primaryAlternate};
  height: 100%;
  aspect-ratio: 1 / 1;
  transform: translateX(-50%);
  left: 50%;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    background: ${colors.primaryAlternate};
    top: -23vw;
    left: -17vw;
    height: 79vw;
    width: 79vw;
    aspect-ratio: unset;
    transform: unset;
  }
`}
`;
