import React, {useEffect, useRef} from 'react';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {
    StyledBlock,
    Bubble,
} from './styles';

gsap.registerPlugin(ScrollTrigger);

const TextWithBubbleGroups = ({ children}) => {
    useEffect(() => {
        gsap.from(elementRef.current, {
            scrollTrigger: {
                trigger: triggerRef.current,
                start: "top bottom",
                end: "+=500",
                ease: "none",
                scrub: 1
            },
            borderRadius: 0
        });
    }, []);

    const elementRef = useRef();
    const triggerRef = useRef();

    return (
        <StyledBlock>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'column text-column'}>
                        <Bubble ref={elementRef}/>
                        <div ref={triggerRef} className={'text-container'}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </StyledBlock>
    );

}

TextWithBubbleGroups.propTypes = {
};

TextWithBubbleGroups.defaultProps = {};

export default TextWithBubbleGroups;
