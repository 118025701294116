import React, {useEffect, useState} from 'react';
import { Button } from "@lxlabs/faze-screener-components-library";

import {
    StyledBlock,
    ColumnContent,
    Bubble,
    TextComponentInner
} from './styles';

const TextComponentTwoColumns = ({rightHandContent, leftHandContent}) => {

    const createMarkUp = (content) => {
        return {__html: content};
    }

    const Image = (data) => {

        if (data.image) {
            return (
                <img src={data.image} alt={data.imageAlt} loading="lazy"/>
            )
        } else {
            return (
                <></>
            )
        }

    }

    const CreateButton = (data) => {

        if (data.button) {
            return (
                <Button
                    cyAttribute={data.button.dataCy}
                    label={data.button.copy}
                    onClick={() => window.open(data.button.link)}
                    size={data.button.size}
                    type={data.button.type}
                />
            )
        } else {
            return (
                <></>
            )
        }
    }

    useEffect(() => {
    }, []);

    return (
        <StyledBlock>
            <div className={'container'}>
                <TextComponentInner>
                    <div className={'row'}>
                        <ColumnContent className='column'>

                            {Image(leftHandContent)}
                            <div className={'content'} dangerouslySetInnerHTML={createMarkUp(leftHandContent.copy)}/>
                            {CreateButton(leftHandContent)}


                        </ColumnContent>
                        <ColumnContent className='column'>

                            {Image(rightHandContent)}
                            <div className={'content'} dangerouslySetInnerHTML={createMarkUp(rightHandContent.copy)}/>
                            {CreateButton(rightHandContent)}
                            <Bubble/>

                        </ColumnContent>
                    </div>
                </TextComponentInner>
            </div>
        </StyledBlock>
    );
}

export default TextComponentTwoColumns;
