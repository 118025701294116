const screenerQuestions = [
  {
    url: "1",
    questionType: "MultipleChoice",
    question: {
      subtitle: "Pregunta 1 de 14",
      copy: "¿Algunas de las siguientes afirmaciones lo describen a usted hoy?",
      defaultCopy: "Do any of the following statements apply to you today?",
      description: "Marque todas las opciones que correspondan",
      metaTitle: "Pregunta 1 de 14",
      metaDescription: "Pregunta 1 de 14",
    },
    answerArrangement: "separate-multi-answer",
    answer: {
      data: [
        {
          copy: `Tengo cáncer`,
          value: "I have cancer",
          result: "proceed",
        },
        {
          copy: "He recibido un trasplante de órgano o de células madre",
          value: "I have had an organ transplant or stem cell transplant",
          result: "proceed",
        },
        {
          copy: "Tomo medicamentos que debilitan mi sistema inmunitario (“medicamentos inmunosupresores”)",
          value: "I am taking medicines that make my immune system weaker (\"immunosuppressive medicines\")",
          result: "proceed",
        },
        {
          copy: "He recibido terapia de células T con receptores quiméricos de antígenos (CAR-T)",
          value: "I have received CAR-T therapy",
          result: "proceed",
        },
        {
          copy: "He recibido tratamiento de disminución de linfocitos B, como rituximab (Rituxan®), ocrelizumab (Ocrevus®), ofatumumab (Kesimpta®) o alemtuzumab (Lemtrada®) el año pasado",
          value: "I have received B-cell depleting therapy such as rituximab (Rituxan®), ocrelizumab (Ocrevus®), ofatumumab (Kesimpta®), or alemtuzumab (Lemtrada®) within the past year",
          result: "proceed",
        },
        {
          copy: `Mi sistema inmunitario no funciona correctamente porque tengo una enfermedad, como el síndrome de DiGeorge, el síndrome de Wiskott-Aldrich, inmunodeficiencia o agammaglobulinemia, hemodiálisis`,
          value: "My immune system does not function properly because I have a condition such as DiGeorge syndrome, Wiskott-Aldrich syndrome, immunodeficiency,  agammaglobulinemia, or I am on hemodialysis",
          result: "proceed",
        },
        {
          copy: "Ninguna de las anteriores",
          value: "None of the above",
          result: "reject",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "2",
    questionType: "YesOrNo",
    question: {
      subtitle: "Pregunta 2 de 14",
      copy: "¿Tiene usted el VIH (virus de inmunodeficiencia humana)?",
      defaultCopy: "Do you have HIV (human immunodeficiency virus)?",
      metaTitle: "Pregunta 2 de 14",
      metaDescription: "Pregunta 2 de 14",
    },
    answer: {
      arrangement: "inline",
      data: [
        {
          copy: "Sí",
          value: "Yes",
          result: "reject",
        },
        {
          copy: "No",
          value: "No",
          result: "proceed",
        },
      ],
    },
  },

  {
    url: "3",
    questionType: "MultipleChoice",
    question: {
      subtitle: "Pregunta 3 de 14",
      copy: "¿Algunas de las siguientes afirmaciones lo describen a usted?",
      defaultCopy: "Do any of the following statements apply to you?",
      description: "Marque todas las opciones que correspondan",
      metaTitle: "Pregunta 3 de 14",
      metaDescription: "Pregunta 3 de 14",
    },
    answerArrangement: "separate-multi-answer",
    answer: {
      data: [
        {
          copy: `Tengo asma`,
          value: "I have asthma",
          result: "proceed",
        },
        {
          copy: "Tengo EPOC",
          value: "I have COPD",
          result: "proceed",
        },
        {
          copy: "Tengo esclerosis múltiple (EM)",
          value: "I have multiple sclerosis (MS)",
          result: "proceed",
        },
        {
          copy: "Tengo artritis",
          value: "I have arthritis",
          result: "proceed",
        },
        {
          copy: "Tengo espondilitis anquilosante (EA)",
          value: "I have ankylosing spondylitis (AS)",
          result: "proceed",
        },
        {
          copy: `Tengo artritis psoriásica`,
          value: "I have psoriatic arthritis",
          result: "proceed",
        },
        {
          copy: `Tengo artritis reumatoide (AR)`,
          value: "I have rheumatoid arthritis (RA)",
          result: "proceed",
        },
        {
          copy: `Tengo psoriasis en placas`,
          value: "I have plaque psoriasis",
          result: "proceed",
        },
        {
          copy: `Tengo lupus`,
          value: "I have lupus",
          result: "proceed",
        },
        {
          copy: `Tengo la enfermedad de Crohn`,
          value: "I have Crohn's disease",
          result: "proceed",
        },
        {
          copy: `Tengo colitis ulcerosa (CU)`,
          value: "I have ulcerative colitis (UC)",
          result: "proceed",
        },
        {
          copy: `Tengo la enfermedad renal crónica (ERC)`,
          value: "I have chronic kidney disease (CKD)",
          result: "proceed",
        },
        {
          copy: `Tengo diabetes tipo 2`,
          value: "I have type 2 diabetes",
          result: "proceed",
        },
        {
          copy: `Tengo la enfermedad cardiovascular`,
          value: "I have cardiovascular disease",
          result: "proceed",
        },
        {
          copy: `Tengo esteatohepatitis no alcohólica (EHNA)`,
          value: "I have non-alcoholic steatohepatitis (NASH)",
          result: "proceed",
        },
        {
          copy: "No tengo ninguna de las anteriores",
          value: "I have none of the above",
          result: "proceed",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "4",
    questionType: "YesOrNo",
    question: {
      subtitle: "Pregunta 4 de 14",
      copy: "¿Pesa al menos 40 kg (88 lb)?",
      defaultCopy: "Do you weigh at least 40 kg (88 lb)?",
      metaTitle: "Pregunta 4 de 14",
      metaDescription: "Pregunta 4 de 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "SÍ",
          value: "YES",
          result: "proceed",
        },
        {
          copy: "NO",
          value: "NO",
          result: "reject",
        },
        {
          copy: "NO ESTOY SEGURO",
          value: "NOT SURE",
          result: "reject",
        },
      ],
    },
  },
  {
    url: "5",
    questionType: "YesOrNo",
    question: {
      subtitle: "Pregunta 5 de 14",
      copy: "¿Alguna vez tuvo una reacción adversa (mala) al tratamiento con un anticuerpo monoclonal?",
      defaultCopy: "Have you ever had an adverse (bad) reaction to treatment with a monoclonal antibody?",
      description: "Los tratamientos con anticuerpos monoclonales se usan para tratar el cáncer, las enfermedades autoinmunes y las enfermedades infecciosas, y suelen terminar en “mab” (como infliximab o rituximab).",
      metaTitle: "Pregunta 5 de 14",
      metaDescription: "Pregunta 5 de 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "SÍ",
          value: "YES",
          result: "reject", 
        },
        {
          copy: "NO",
          value: "NO",
          result: "proceed",
        },
        {
          copy: "NO ESTOY SEGURO",
          value: "NOT SURE",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "6",
    questionType: "YesOrNo",
    question: {
      subtitle: "Pregunta 6 de 14",
      copy: '¿Algún profesional médico le extrajo una unidad o más <span style="white-space: nowrap;">(450 ml/1 pt)</span> de sangre en los últimos 30 días?',
      defaultCopy: 'Has a medical professional taken one unit or more <span style="white-space: nowrap;">(450 mL/1 pt)</span> blood from you within the last 30 days?',
      metaTitle: "Pregunta 6 de 14",
      metaDescription: "Pregunta 6 de 14",
    },
    answer: {
      arrangement: "inline",
      data: [
        {
          copy: "Sí",
          value: "Yes",
          result: "reject", 
        },
        {
          copy: "No",
          value: "No",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "7",
    questionType: "MultiCheckbox",
    question: {
      subtitle: "Pregunta 7 de 14",
      copy: "¿Algunas de las siguientes afirmaciones lo describen a usted hoy?",
      defaultCopy: "Do any of the following statements apply to you today?",
      description: "Marque todas las opciones que correspondan",
      metaTitle: "Pregunta 7 de 14",
      metaDescription: "Pregunta 7 de 14",
    },
    answer: {
      arrangement: "separate-multi-answer",
      data: [
        {
          title: "Estoy embarazada",
          value: "I am pregnant",
          result: "reject",
        },
        {
          title: "Estoy amamantando",
          value: "I am breastfeeding",
          result: "reject",
        },
        {
          title: "Puedo quedar embarazada y no utilizo ningún método anticonceptivo eficaz ni me abstengo de tener relaciones sexuales",
          value: "I am able to become pregnant and I am not using an effective method of contraception or abstaining from sexual intercourse",
          result: "reject",
        },
        {
          title: "Ninguna de las anteriores",
          value: "None of the above",
          result: "proceed",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "8",
    questionType: "MultipleChoice",
    question: {
      subtitle: "Pregunta 8 de 14",
      copy: "¿Algunas de las siguientes afirmaciones lo describen a usted hoy?",
      defaultCopy: "Do any of the following statements apply to you today?",
      description: "Elija todas las opciones que correspondan",
      metaTitle: "Pregunta 8 de 14",
      metaDescription: "Pregunta 8 de 14",
    },
    answerArrangement: "separate",
    answer: {
      data: [
        {
          copy: "He recibido una vacuna contra la COVID-19 en los últimos 3 meses",
          value: "I have received a COVID-19 vaccine within the last 3 months",
          result: "reject",
        },
        {
          copy: "He tenido COVID-19 en los últimos 3 meses (confirmado por una prueba en el hogar o de laboratorio)",
          value: "I have had COVID-19 within the last 3 months (confirmed by a home or laboratory test)",
          result: "reject",
        },
        {
          copy: "He recibido tratamiento con plasma convaleciente para la COVID-19 en los últimos 6 meses.",
          value: "I have received convalescent COVID-19 plasma treatment in the past 6 months.",
          result: "reject",
        },
        {
          copy: "He recibido tratamiento con anticuerpos monoclonales para la COVID-19 (por ejemplo, Evusheld®, REGEN-COV® o Actemra®) en los últimos 6 meses",
          value: "I have received monoclonal antibody treatment for COVID-19 (for example, Evusheld®, REGEN-COV® or Actemra®) in the last 6 months",
          result: "reject",
        },
        {
          copy: "He recibido un antiviral COVID-19 para profilaxis dentro de al menos 2 semanas",
          value: "I have received a COVID-19 antiviral for prophylaxis within at least 2 weeks",
          result: "reject",
        },
        {
          copy: "Ninguna de las anteriores",
          value: "None of the above",
          result: "proceed",
          action: "clear",
        },
      ],
    },
  },

  {
    url: "9",
    questionType: "YesOrNo",
    question: {
      subtitle: "Pregunta 9 de 14",
      copy: "¿Tiene algún trastorno hemorrágico o antecedentes de hemorragias importantes o hematomas después de las inyecciones?",
      defaultCopy: "Do you have a bleeding disorder or a history of significant bleeding or bruising after injections?",
      metaTitle: "Pregunta 9 de 14",
      metaDescription: "Pregunta 9 de 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "SÍ",
          value: "YES",
          result: "reject", 
        },
        {
          copy: "NO",
          value: "NO",
          result: "proceed",
        },
        {
          copy: "NO ESTOY SEGURO",
          value: "NOT SURE",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "10",
    questionType: "YesOrNo",
    question: {
      subtitle: "Pregunta 10 de 14",
      copy: "¿Recibe tratamiento periódico con inmunoglobulinas (IV/SC) o algún hemoderivado?",
      defaultCopy: "Are you receiving regular immunoglobulin (IV/SC) treatment or any blood products?",
      metaTitle: "Pregunta 10 de 14",
      metaDescription: "Pregunta 10 de 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "SÍ",
          value: "YES",
          result: "reject", 
        },
        {
          copy: "NO",
          value: "NO",
          result: "proceed",
        },
        {
          copy: "NO ESTOY SEGURO",
          value: "NOT SURE",
          result: "proceed",
        },
      ],
    },
  },  
  {
    url: "11",
    questionType: "MultipleChoice",
    question: {
      subtitle: "Pregunta 11 de 14",
      copy: "¿Algunas de las siguientes afirmaciones lo describen a usted hoy?",
      defaultCopy: "Do any of the following statements apply to you today?",
      description: "Elija todas las opciones que correspondan",
      metaTitle: "Pregunta 11 de 14",
      metaDescription: "Pregunta 11 de 14",
    },
    answerArrangement: "separate",
    answer: {
      data: [
        {
          copy: "He tomado un medicamento del ensayo en los últimos 90 días",
          value: "I have taken a trial drug within the last 90 days",
          result: "reject",
        },
        {
          copy: "Tengo previsto tomar un medicamento del ensayo en los próximos 16 meses",
          value: "I plan to take a trial drug within the next 16 months",
          result: "reject",
        },
        {
          copy: "Ninguna de las anteriores",
          value: "None of the above",
          result: "proceed",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "12",
    questionType: "YesOrNo",
    question: {
      subtitle: "Pregunta 12 de 14",
      copy: "Este ensayo durará 15 meses. ¿Podrá seguir asistiendo a la clínica durante este tiempo?",
      defaultCopy: "This trial will run for 15 months. Will you still be able to attend the clinic during this time?",
      metaTitle: "Pregunta 12 de 14",
      metaDescription: "Pregunta 12 de 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "SÍ",
          value: "YES",
          result: "proceed",
        },
        {
          copy: "NO",
          value: "NO",
          result: "proceed",
        },
        {
          copy: "NO ESTOY SEGURO",
          value: "NOT SURE",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "13",
    questionType: "YesOrNo",
    question: {
      subtitle: "Pregunta 13 de 14",
      copy: "¿Qué distancia estaría dispuesto a trasladarse para asistir a las citas de un centro del ensayo?",
      defaultCopy: "How far are you willing to travel to a trial site for appointments?",
      metaTitle: "Pregunta 13 de 14",
      metaDescription: "Pregunta 13 de 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "Hasta 20 millas",
          value: "0-20 miles",
          result: "proceed",
        },
        {
          copy: "Hasta 40 millas",
          value: "20-40 miles",
          result: "proceed",
        },
        {
          copy: "Hasta 60 millas",
          value: "40-60 miles",
          result: "proceed",
        },
        {
          copy: "Hasta 80 millas",
          value: "60-80 miles",
          result: "proceed",
        },
        {
          copy: "Más de 80 millas",
          value: "80+ miles",
          result: "proceed",
        },
        {
          copy: "No puedo viajar",
          value: "I cannot travel",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "14",
    questionType: "TextInput",
    question: {
      subtitle: "Pregunta 14 de 14",
      copy: "¿Cuál es el motivo por el cual quiere participar en un ensayo clínico? (opcional)",
      defaultCopy: "What is your reason for wanting to join a clinical trial? (optional)",
      metaTitle: "Pregunta 14 de 14",
      metaDescription: "Pregunta 14 de 14",
    },
    answer: {
      placeholder: "Escriba su respuesta aquí",
      result: "proceed",
      name: "textarea",
      validation: {
        maxLength: {
          value: 250,
          error: `Tenga en cuenta que la respuesta no puede tener más de 250 caracteres`,
        },
      },
    },
  },
];

export default screenerQuestions;
