import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Button } from "@lxlabs/faze-screener-components-library";
import FooterController from "../Footer";



import {
  StyledBlock,
  AccordionItem,
  AccordionWrapper
} from './styles';

const NotFound = ({title, copy, accordionItems, image, imageAlt, button}) => {

  const [activeAccordion, setActiveAccordion] = useState(false);

  const toggleAccordion = (key, e = false) => {
    if (!e || e.keyCode !== 9) {
      if (e) {
        e.preventDefault();
      }
      if (activeAccordion === key) {
        setActiveAccordion(false);
      } else {
        setActiveAccordion(key);
      }
    }
  }

  useEffect(() => {

    let localCountry = localStorage.getItem('country');

    if(localCountry !== null && localCountry !== 'en-US' && window.location.pathname !== `/${localCountry}/404`){
      setTimeout(()=>{
        window.location.href = `/${localCountry}/404`;
      },1000)
    }else if(localCountry === null && window.location.pathname !== `/404`){
      setTimeout(()=>{
        window.location.href = `/404`;
      },1000)
    }else if(localCountry === 'en-US' && window.location.pathname !== `/404`){
      setTimeout(()=>{
        window.location.href = `/404`;
      },1000)
    }

  }, []);

  return (
    <>
    <StyledBlock>
      <div className={'container'}>
        <div className={'grid-container'}>
          <div className={'header-area text-column'}>
            <h1>{title}</h1>
            <p>{copy}</p>
          </div>
          <div className={'body-area'}>
            <AccordionWrapper>
            {accordionItems.map((accordionItem, key) => {
            return (
              <div key={key}>
                <AccordionItem id={`accordion${key}`} className={key === activeAccordion ? 'accordion-item active' : 'accordion-item'}>
                  <h3 onClick={() => {toggleAccordion(key)}} onKeyDown={(e) => {toggleAccordion(key, e)}} role="button" tabIndex="0" aria-controls={`accordion${key}`} aria-expanded={key === activeAccordion}>{accordionItem.title}
                    <span></span></h3>
                    <div className="accordion-item-content">
                      {accordionItem.copy}
                    </div>
                </AccordionItem>
              </div>
            )
            })}
            </AccordionWrapper>
          
            <Button
              label={button.label}
              type={'outline'}
              href={button.href}
            />
          </div>
          <div className={'image-area'}>
            <div className={'column image-container'}>
              <img src={image} alt={imageAlt} />
            </div>
          </div>
            
        </div>
      </div>
    </StyledBlock>
    <FooterController addLowerMargin={false} button={true} />
    </>
  );

}

NotFound.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  button: PropTypes.object,
  accordionItems: PropTypes.array
};

NotFound.defaultProps = {};

export default NotFound;
