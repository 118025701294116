import styled from 'styled-components';
import {mediaQueriesSizes, colors} from "../../assets/styles/GlobalStyles.js";

export const LogoWrapper = styled.div`
  display:flex;
  align-items: center;

  &.mobile {
    display: flex;

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      display: none;
    }
  }

  &.desktop {
    display: none;

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      display: flex;
      position: absolute;
      top: 90px;
      left: 20px;
      z-index: 100;

      &.noLangBarPosition {
        top: 31px;
      }
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      top: 90px;
      left: 40px;
      
      &.noLangBarPosition {
        top: 28px;
      }
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      top: 100px;
      left: 40px;

      &.noLangBarPosition {
        top: 34px;
      }
    }
  }

  &.logo-black {
    a {
      &.mobile-logo{
        display:block !important;
      }
      &.mobile-logo-white{
        display:none !important;
      }
    }
  }
 

  a {
    align-items: center;

    &.mobile-logo{
      display:flex;
    }

    &.mobile-logo-white{
      display:none;
    }

    &.desktop-logo{
      display:none;
      width: 236px;
    }

  
    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      &.mobile-logo{
        display:none;
      }
      
      &.desktop-logo{
        display:flex;
        top: 20px;
      }
    }    
  }

  &.mobile {

    a.mobile-logo {
        display: none;
    }
    a.mobile-logo-white {
      display: flex;
    }
    
    &.scrolled {
      a.mobile-logo {
        display: flex;
      }
      a.mobile-logo-white {
        display: none;
      }

    }

  }
  
  img{
    height: 28px;

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      height: 32px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      height: 36px;
    }
  
    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      height: 46px
    }
  }
  
  hr{
    width: 1px;
    height: 26px;
    background: ${colors.secondary};
    display: block;
    border: none;
    margin:0 12px;

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      background: ${colors.white};
      margin:0 10px;
    }
    
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      margin: 0 24px;
      height: 28px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      margin: 0 40px;
      height: 32px;
    }
  }

  &.logo-white .desktop-logo svg path {
    fill: #ffffff;
  }
`;
