import React, {useEffect} from 'react';

import {
  StyledBlock,
} from './styles';

const FAQSHero = ({title, copy, image, imageAlt}) => {

  useEffect(() => {

  }, []);

  return (
    <StyledBlock>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'column'}>
            <div>
              <h1>{title}</h1>
              <p>{copy}</p>
            </div>
          </div>
          <div className={'column column-image'}>
            <img src={image} alt=""/>
          </div>
        </div>
      </div>
    </StyledBlock>
  );

}

export default FAQSHero;
