import React, {useEffect} from "react";
import { Form } from "@lxlabs/faze-screener-components-library";
import {navigate} from "@reach/router";
import FooterController from "../Footer";
import styled from 'styled-components'
import {mediaQueriesSizes} from "../../assets/styles/GlobalStyles.js";

const Wrapper = styled.div`
  h1 {
    font-size: 16px;
    line-height: 32px;

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 28px;
      line-height: 40px;
    }
  }
`

const RejectionComponent = ({contentCopy, currentLang, studyCode}) => {
    let submittedAnswers = localStorage.getItem('submittedAnswers');
    if (submittedAnswers !== null) {
        submittedAnswers = JSON.parse(submittedAnswers);
        submittedAnswers = Object.values(submittedAnswers);
    }

    let zipcodePlaceholder = '12345';

    if (contentCopy.config.lang === 'en-GB') {
        zipcodePlaceholder = "";
    }

    const defaultErrorHandle = (error) => {
      console.log(error.response.data);
    }

    let formQuestions = [
        {
            label: contentCopy.formLabels.firstname,
            name: "firstName",
            type: "text",
            validation: {
            required: contentCopy.errorCopy.pleaseEnter,
            minLength: {
                value: 2,
                error: contentCopy.formLabels.minimum_char_error
            },
            match: {
                value: contentCopy.formValidation.name_match,
                error: contentCopy.errorCopy.pleaseEnter
            }
            }
        },
        {
            name: contentCopy.formLabels.address,
            validation: {
                required: contentCopy.errorCopy.pleaseEnter
            },
            fields:[
                {
                    type: "text",
                    name: "addressPostcode",
                    placeholder: zipcodePlaceholder,
                    label: contentCopy.formLabels.address_zipcode,
                    error: contentCopy.errorCopy.zipcode
                },
                {
                    type: "select",
                    name: "addressRegion",
                    label: contentCopy.formLabels.address_state,
                    error: contentCopy.errorCopy.state,
                    states: contentCopy.states
                }
            ]
        },
        {
            label: contentCopy.formLabels.email_required,
            name: "email",
            type: "email",
            validation: {
            required: contentCopy.errorCopy.pleaseEnter,
            email: contentCopy.errorCopy.email,
            minLength: {
                value: 6,
                error: contentCopy.formLabels.minimum_char_error
            },
            }
        },
        {
            label: contentCopy.formLabels.phone_required,
            name: "phone",
            type: "tel",
            validation: {
            required: contentCopy.errorCopy.pleaseEnter,
            minNumbers: {
                value: 8,
                error: contentCopy.formLabels.minimum_char_error
            },
            match: {
                value: contentCopy.formValidation.phone_match,
                error: contentCopy.errorCopy.pleaseEnter
            }
            },
        },
        {
            label: contentCopy.formLabels.mobile2,
            name: "mobile",
            type: "tel",
            error: contentCopy.errorCopy.pleaseEnter,
            validation: {
            match: {
                value: contentCopy.formValidation.phone_match,
                error: contentCopy.errorCopy.pleaseEnter
            },
            minNumbers: {
                value: 8,
                error: contentCopy.formLabels.minimum_char_error
            }
            },
        },
        {
            label: contentCopy.formLabels.therapyAreaIntrest,
            name: "therapyAreaIntrest",
            type: "select-multiple",
            columns: 3,
            choices: [
            {
                label: contentCopy.therapyAreas.respiratory,
                value: "respiratory"
            },
            {
                label: contentCopy.therapyAreas.infectious_diseases,
                value: "infectious diseases"
            }
            ],
            validation: {
            required: true
            }
        },
        {
            type: 'checkbox-group',
            backgroundColor: 'light',
            questions: [
                {
                    name: 'supplied',
                    label: contentCopy.rejectionFormPage.copyData.consent,
                    validation: {
                        required: contentCopy.rejectionFormPage.copyData.consentRequired,
                    }
                }, 
                {
                    name: 'consentContactFeedback',
                    label: contentCopy.formLabels.consent_feedback
                },                     
            ]
        }
    ]

    return (
        <Wrapper>
          <Form
            typeOfForm={contentCopy.rejectionFormPage.typeOfForm}
            copyData={contentCopy.rejectionFormPage.copyData}
            formQuestions={formQuestions}
            errorHandle={defaultErrorHandle}
            button={{
              submitLabel: contentCopy.rejectionFormPage.copyData.submitButtonText,
              completionHandle: () => {
                  console.log('completed');
                navigate(`/${currentLang}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.noteligible}/${contentCopy.config.urls.thankyou}`);
              },
            }}
            submittedAnswers={submittedAnswers}
            logo={{
                style: contentCopy.logo,
                link: contentCopy.logoLink
              }}
            apiUrl={process.env.GATSBY_SCREENER_API_URL}
            langCode={contentCopy.config.lang}
            studyCode={contentCopy.config.studyCode}
            unit={contentCopy.config.unit}
            consentFields={[
                'supplied', 
                'consentContactFeedback'
            ]}
          />
          <FooterController addLowerMargin={true} button={false}/>
        </Wrapper>
    )
};

export default RejectionComponent;