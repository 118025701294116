import styled from 'styled-components';
import {
    colors,
    mediaQueriesSizes,
} from "../../../assets/styles/GlobalStyles.js";

export const Wrapper = styled.div`

  padding: 37px 0;
  background:  ${colors.primary};
  h2{
    font-weight: 400;
    font-size:36px;
    line-height:1;
  }
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 120px 0;
    background:${colors.primary}

    h2{
      font-size: 44px;
      line-height: 53px;
      padding-right: 100px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    h2{
      font-size: 50px;
      line-height: 60px;
    }    
  }

`;

export const StepsWrapper = styled.div`
  
  margin-top:25px;
  display:flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: stretch;
  margin-left: -8px;
  margin-right: -8px;
  flex-wrap: wrap;
  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin-top:45px;
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    flex-wrap: nowrap;
  }
  
`;

export const Step = styled.div`
  
  border-bottom-right-radius: 50px;
  padding:5px;
  margin:0 8px 15px;;
  flex:calc((100% / 2) - 25px);
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    background:white;
    padding:30px;
  }
  
  .imageWrap{
  
    width: 100%;
    background: white;
    //aspect-ratio: 16 / 12.5;
    padding-bottom: 33px;
    padding-top: 32px;
    margin-bottom: 27px;
    border-bottom-right-radius: 45px;
    display:flex;
    align-items: center;
    
    img{
      width: auto;
      height: auto;
      max-width: 200px;
      max-height: 200px;
      margin: 0 auto;
    }
  
    @media (min-width: ${mediaQueriesSizes.md}px) {
      //aspect-ratio: 16 / 10.5;
      margin-bottom: 0;
      padding-bottom: 42px;
      padding-top: 25px;
    }
  }
  
  h3{
    margin-bottom:13px;
    font-size: 20px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin-bottom:15px;
      font-size:32px;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      height: 100px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      height: auto;
    }

    
    

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size:36px;
    }    

    span {
      display:none;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.1875;
      margin-bottom:5px;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        display:block;
      }
    }
  }
  
  p{
    font-size: 16px;
    font-weight: 300;
    margin-bottom:35px;
    line-height: 1.625;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin-bottom:45px;
      font-size: 14px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 16px;
    }

    a {
      color: inherit;
    }
  }

  &.in-active{
    display:none;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      display:block;
    }
  }


`;

export const ButtonWrapper = styled.div`
  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin: 35px 0 50px;
  }
  text-align: center;

  a {
    width: 100%;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 320px;
    }
  }
`;

export const StepDot = styled.div`
  background:rgba(255,255,255,0.5);
  width: 90px;
  text-align:center;
  line-height:40px;
  border-radius:30px;
  &.active{
    background: ${colors.white}
  }
`;

export const DotWrapper = styled.div`
  display:flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin:25px 0 30px;
  
    @media (min-width: ${mediaQueriesSizes.md}px) {
      display:none;
    }

  
`;

export const JoinSimpleStepsInner = styled.div`
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
