import styled from "styled-components";

import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  background: ${colors.primaryAlternate};
  min-height: 90vh;
  padding-top: 80px;
  position: relative;
  overflow: hidden;

  @media (min-width: ${mediaQueriesSizes.md}px) {
      align-items: start;
      border-radius: 0;
      padding-top: 50px;
    }

  .row {
    width: 100%;
    border-radius: 20px;
    padding: 50px 0 40px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      align-items: start;
      border-radius: 0;
      padding-top: 50px;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 70%;
      padding-top: 50px;

    } 

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 70%;
      padding-top: 50px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      width: 70%;
      padding-top: 50px;
    }

  }

  .text-column {
    position: relative;
    margin:0 auto;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    colour: black;
    width:90vw;
    height:90vw;
    max-width:450px;
    max-height:450px;
    border-radius: 50%;
    background: white;
    z-index: 10;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      max-width:600px;
      max-height:600px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      max-width:690px;
      max-height:690px;
    }

    &:after{
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 50%;
        transform: scale(1.4);
        background: white;
                    
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            display:none;
        }
    }

    .circle-left {
      border: 3px solid #FFF7F1;
      background: transparent;
      position: absolute;
      border-radius: 50%;
      height: 300px;
      width: 300px;
      bottom: -357px;
      left: -70px;

      

      @media (min-width: ${mediaQueriesSizes.md}px) {
        height: 448px;
        width: 448px;
        bottom: -407px;
        left: -120px;
        
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        height: 448px;
        width: 448px;
        bottom: -400px;
        left: -100px;

      }
    }

    h1 {
      font-size: 36px;
      font-weight: 400;
      line-height: 36px;
      margin: 0;
      text-align: center;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 44px;
        font-weight: 400;
        line-height: 53px;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 50px;
        line-height: 60px;
        width: 90%;
        text-align: center;
      }      
    }

    p {
      max-width: 80%;
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      margin: 30px 0 0;
      text-align: center;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 16px;
        line-height: 32px;

      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 16px;
        line-height: 26px;

      }      
    }
  }

  .circles-container {
    display: none;

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      z-index: 50;
      display: grid;
      grid-template-columns: 190px 190px;
      grid-template-rows: 360px 360px;
      grid-template-areas:
      "circle1 circle2"
      "circle3 circle4";
      position: absolute;
      right: 0px;
      bottom: -160px;

      .circle-1 {
        grid-area: circle1;
        background: #FFF7F1;
        border-radius: 14rem 0 0 14rem;
      }

      .circle-2 {
        grid-area: circle2;
        background: transparent;
        border: 3px solid #FFF7F1;
        border-radius: 14rem 0 0 14rem;

      }

      .circle-3 {
        grid-area: circle3;
        background: transparent;
        border: 3px solid #FFF7F1;
        border-radius: 14rem 0 0 14rem;
        
      }

      .circle-4 {
        grid-area: circle4;
        background: #FFF7F1;
        border-radius: 14rem 0 0 14rem;
      }
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      grid-template-columns: 224px 224px;
      grid-template-rows: 448px 448px;
    }
    
  }

`;

export const TextBubble = styled.div`
  background: ${colors.white};
  width:100%;
  position:relative;
  z-index:5;
  padding:0 15px;
  max-width:430px;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

    @media (min-width: ${mediaQueriesSizes.sm}px) {
        max-width:420px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
        max-width:520px;
    }

`;