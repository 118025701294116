import React, {useContext, useEffect} from "react";
import {ThankYouScreen} from "@lxlabs/faze-screener-components-library";
import {PageContext} from "../../Context/pageContext";
import FooterController from "../Footer";

const RejectionThankYouComponent = ({contentCopy}) => {

  let pageContext = useContext(PageContext);

  return (
    <>
      <ThankYouScreen
        type={contentCopy.rejectionThankYou.type}
        copyData={contentCopy.rejectionThankYou.copyData}
        logo={{
          style: contentCopy.logo,
          link: contentCopy.logoLink
        }}
        button={contentCopy.rejectionThankYou.button}
      />
      <FooterController addLowerMargin={true} button={false}/>
    </>
  )
};

export default RejectionThankYouComponent;