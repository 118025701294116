import React, {useEffect} from 'react';

import {
  StyledBlock,
  ImageWrap,
  RightColumn,
  LowerBlock,
  UpperBlock,
  TextBlock,
  Bubble,
  Container
} from './styles';

const AboutCOVID19Header = ({title, copy, subtitle, secondarytitle, secondarycopy,image, imageAlt}) => {

  useEffect(() => {
  }, []);


  return (
    <StyledBlock>
      <UpperBlock>
        <Bubble>
          <p>{subtitle}</p>
        </Bubble>
      <TextBlock>
          <div className={'container'}>
            <h1>{title}</h1>
            <p>{copy}</p>
          </div>
        </TextBlock>
      </UpperBlock>
      <LowerBlock>
        <div className={'container'}>
          <Container>
            <ImageWrap>
              <img src={image} alt="" />
            </ImageWrap>
            <RightColumn>
              <h2>{secondarytitle}</h2>
              <p>{secondarycopy}</p>
            </RightColumn>
          </Container>
        </div>
      </LowerBlock>

    </StyledBlock>
  );

}

export default AboutCOVID19Header;
