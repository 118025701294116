import React, {useState, useEffect, useContext} from 'react';
import ScreenerEntranceComponent from './ScreenerEntrance';
import QuestionController from './QuestionController';
import RejectionComponent from './RejectionComponent';
import CandidateEligible from './CandidateEligible';
import CandidateAlreadyRegistered from './CandidateAlreadyRegistered';
import RejectionThankYouComponent from './RejectionThankYouComponent';
import CandidateForm from './CandidateForm';

import {PageContext} from "../../Context/pageContext";

import {Router} from '@reach/router'
import CandidateCreatedThankYou from "./CandidateCreatedThankYou";
import {getUserQuestion,getUserStatus} from "./UserProgress";

const ScreenerControl = (props) => {

  const pageContext = useContext(PageContext);
  let contentCopy,questions,navigation;

  try {
    contentCopy = require(`../../../data/copy/${pageContext.language}.js`);
    questions = require(`../../../data/screener-questions/${pageContext.language}.js`).default;
    navigation = require(`../../../data/navigation/${pageContext.language}.js`);
  } catch (e) {
    contentCopy = require(`../../../data/copy/en-US.js`);
    questions = require(`../../../data/screener-questions/en-US.js`).default;
    navigation = require(`../../../data/navigation/en-US.js`);
  }

  contentCopy = contentCopy.default;

  const logoLink = pageContext.homepagePath;
  contentCopy.logoLink = logoLink;
  contentCopy.logo = navigation.Config.logo;

  const [baseURL, setBaseUrl] = useState('');
  const [currentLang, setCurrentLang] = useState(false);
  const [studyCode, setStudyCode] = useState(false);
  const userProgress = getUserQuestion();
  const userStatus = getUserStatus();

  useEffect(() => {

    if(baseURL === ''){
      setBaseUrl(window.location.href);
    }

    setCurrentLang(pageContext.language);
    setStudyCode(pageContext.studyCode);

    
    window.history.pushState({page_id: userProgress}, '', `${window.location.href}`);


  }, [currentLang,studyCode,userStatus, userProgress]);

  // dynamically cr♥️ate 'proceed' routes which can be accessed, based on user's last answer (proceed/reject) and also number of questions answered (has to have gone through all the questions)
  let routesProceed = null;
  let routesReject = null;


  if (userStatus && userStatus === 'proceed' && userProgress >= questions.length-1 || userStatus == 'force-proceed' ) {
    routesProceed = <>
          <CandidateEligible
            path={`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.eligible}`}
            contentCopy={contentCopy}
          />

          <CandidateForm
            path={`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.eligible}/${contentCopy.config.urls.yourdetails}`}
            contentCopy={contentCopy}
          />

          <CandidateCreatedThankYou
            path={`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.eligible}/${contentCopy.config.urls.thankyou}`}
            contentCopy={contentCopy}
          />      
    </>
  }

  // dynamically create 'reject' routes which can be accessed, based on user's last answer (proceed/reject)
  if (userStatus === 'reject' || userStatus === 'reject-exacerbations' || userStatus == 'force-proceed') {
  
    routesReject =
    <>
      <RejectionComponent
        path={`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.noteligible}/${contentCopy.config.urls.yourdetails}`}
        contentCopy={contentCopy}
        currentLang={pageContext.language}
        studyCode={pageContext.studyCode}
      />
  
      <RejectionThankYouComponent
        path={`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.noteligible}/${contentCopy.config.urls.thankyou}`}
        contentCopy={contentCopy}
      />  
  
    </>
  }

  return (
    <>
      <Router>
          <ScreenerEntranceComponent
            path={`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/`}
            contentCopy={contentCopy}
            />

          <QuestionController
            path={`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.question}/:questionID`}
            contentCopy={contentCopy}
          />

          {routesReject}
          {routesProceed}

          <CandidateAlreadyRegistered 
            path={`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.alreadyregistered}`}
            contentCopy={contentCopy}
          />

      </Router>

    </>
  );
};

export default ScreenerControl;

