const ScreenerCopy = {

  config: {
    lang: 'en-US',
    studyCode: 'AzSup',
    unit: 'miles',
    urls: {
      questionnaire: 'questionnaire',
      eligible: 'eligible',
      yourdetails: 'yourdetails',
      thankyou: 'thankyou',
      noteligible: 'noteligible',
      notyeteligible: 'notyeteligible',
      question: 'question',
      alreadyregistered: 'alreadyregistered',
    }
  },

  buttonLabels: {
    keepInTouch: `Let's keep in touch`,
    findSite: `LET'S GO`,
    findSite2: `LET'S GO`,
    searchAgain: `Search again`,
    select: `SELECT`,
    next: `Next`,
    submit: `Submit your details`,
    changeSite: 'Change Site',
    backToHome: 'Back to homepage',
    entrance: `Let's go`,
    learnMore: `Learn more`
  },

  distances: [
    {
      value: '',
      label: 'Distance'
    },
    {
      value: 25,
      label: 'Within 25 miles'
    },
    {
      value: 50,
      label: 'Within 50 miles'
    },
    {
      value: 75,
      label: 'Within 75 miles'
    },
    {
      value: 100,
      label: 'Within 100 miles'
    },
    {
      value: 200,
      label: 'Within 200 miles'
    },
    {
      value: 5000000,
      label: 'No limit'
    },
  ],

  monthList: [
    {
      number: '01',
      text: 'January'
    },
    {
      number: '02',
      text: 'February'
    },
    {
      number: '03',
      text: 'March'
    },
    {
      number: '04',
      text: 'April'
    },
    {
      number: '05',
      text: 'May'
    },
    {
      number: '06',
      text: 'June'
    },
    {
      number: '07',
      text: 'July'
    },
    {
      number: '08',
      text: 'August'
    },
    {
      number: '09',
      text: 'September'
    },
    {
      number: '10',
      text: 'October'
    },
    {
      number: '11',
      text: 'November'
    },
    {
      number: '12',
      text: 'December'
    },
  ],

  ethnicityList: [
    {
      'value': '',
      'label': '',
    },
    {
      'value': '2',
      'label': 'Alaska Native',
    },
    {
      'value': '2',
      'label': 'American Indian',
    },
    {
      'value': '3',
      'label': 'Asian',
    },
    {
      'value': '4',
      'label': 'Black or African American',
    },
    {
      'value': '5',
      'label': 'Native Hawaiian and other Pacific Islander',
    },
    {
      'value': 'Z',
      'label': 'Prefer not to say',
    },
    {
      'value': '7',
      'label': 'Two or more races',
    },
    {
      'value': '6',
      'label': 'White',
    },
  ],

  states:[
    {
      label: ''
    },
    {
      label: 'Alabama'
    },
    {
      label: 'Alaska',
    },
    {
      label: 'Arizona',
    },
    {
      label: 'Arkansas',
    },
    {
      label: 'California',
    },
    {
      label: 'Colorado',
    },
    {
      label: 'Connecticut',
    },
    {
      label: 'Delaware',
    },
    {
      label: 'Florida',
    },
    {
      label: 'Georgia',
    },
    {
      label: 'Hawaii',
    },
    {
      label: 'Idaho',
    },
    {
      label: 'Illinois',
    },
    {
      label: 'Indiana',
    },
    {
      label: 'Iowa',
    },
    {
      label: 'Kansas',
    },
    {
      label: 'Kentucky',
    },
    {
      label: 'Louisiana',
    },
    {
      label: 'Maine',
    },
    {
      label: 'Maryland',
    },
    {
      label: 'Massachusetts',
    },
    {
      label: 'Michigan',
    },
    {
      label: 'Minnesota',
    },
    {
      label: 'Mississippi',
    },
    {
      label: 'Missouri',
    },
    {
      label: 'Montana',
    },
    {
      label: 'Nebraska',
    },
    {
      label: 'Nevada',
    },
    {
      label: 'New Hampshire',
    },
    {
      label: 'New Jersey',
    },
    {
      label: 'New Mexico',
    },
    {
      label: 'New York',
    },
    {
      label: 'North Carolina',
    },
    {
      label: 'North Dakota',
    },
    {
      label: 'Ohio',
    },
    {
      label: 'Oklahoma',
    },
    {
      label: 'Oregon',
    },
    {
      label: 'Pennsylvania',
    },
    {
      label: 'Rhode Island',
    },
    {
      label: 'South Carolina',
    },
    {
      label: 'South Dakota',
    },
    {
      label: 'Tennessee',
    },
    {
      label: 'Texas',
    },
    {
      label: 'Utah',
    },
    {
      label: 'Vermont',
    },
    {
      label: 'Virginia',
    },
    {
      label: 'Washington',
    },
    {
      label: 'West Virginia',
    },
    {
      label: 'Wisconsin',
    },
    {
      label: 'Wyoming',
    },
  ],

  formLabels: {
    dob:{
      day: 'Day',
      month: 'Month',
      year: 'Year',
    },
    changeSite: 'Change Site',
    firstname: `First name*`,
    lastname: `Last name*`,
    email: `Email address*`,
    email_required: `Email address*`,
    dateOfBirth_required: `Birth month & year`,
    sex: `Sex`,
    male: `Male`,
    female: `Female`,
    preferNotToSay: `Prefer not to say`,
    ethnicity: `Ethnicity`,
    address: `address`,
    address_zipcode: `Zipcode* (this helps us find a trial near you)`,
    address_state:`State`,
    phone_required: `Phone number*`,
    mobile: `Mobile number (if you provide your mobile number, we’ll send you a further SMS shortly to obtain your opt-in consent)`,
    mobile2: 'Mobile number',
    contact: `Best time to contact`,
    contact_morning: `Morning`,
    contact_lunch: `Lunchtime`,
    contact_evening: `Evening`,
    contact_method_email: `Email`,
    contact_method_sms: `SMS`,
    contact_method_phone: `Phone`,
    contact_method: `What is your preferred method of contact?* (select one or more)`,
    hcp_refferal: `Were you referred by a healthcare professional?`,
    hcp_refferal_yes: `Yes`,
    hcp_refferal_no: `No`,
    minimum_char_error:`Needs to be at least *** characters long`,
    consent: `By checking the applicable boxes below, you consent to Havas Faze contacting you (by email, telephone, MMS, SMS or any other messaging channels (such as WhatsApp):`,
    consent_location_intro: 'In order to <b>evaluate your eligibility for the trial you have expressed an interest in</b>, we need to collect and process the <b>health information</b> you provide through the questionnaire and share it with third-party healthcare professionals (who are conducting the applicable trial(s) that you are eligible for). In order to invite you to participate in these trials, we also need to contact you.',
    consent_location: `By checking this box, you consent to Havas Faze collecting and sharing your health information for the above purposes.<br/></br><b>If you do not consent</b>, we will not be able to evaluate your eligibility for the clinical trials offered, to proceed with your application and to invite you to participate in the clinical trial(s).`,
    consent_ethnicity: `By checking this box, you consent to Havas Faze using data about <b>your ethnicity</b> to monitor the diversity of trial participants. We may share ethnicity data in aggregate form (so you cannot be identified) with AstraZeneca.`,
    consent_flareup: `*By checking this box, you consent to AstraZeneca and its partner Havas Faze contacting you to see if: you are eligible for this trial, you have had a recent flare-up, or your health condition changes.`,
    consent_feedback: `By checking this box, you consent to Havas Faze, AstraZeneca (or a third-party partner) contacting you by email, telephone, MMS, SMS or any other messaging channels (such as WhatsApp) to request <b>your feedback</b> about the trial process and what can be improved.`,
    future_respiratory_trials: `about <b>future trials</b> that are <b>Coronavirus or similar respiratory trials</b> run by AstraZeneca and that we are recruiting for.`,
    future_infectious_disease_trials: `about <b>future trials</b> that are <b>Coronavirus or similar infectious disease trials</b> that are run by AstraZeneca and that we are recruiting for.`,
    supplied_future_trials: `*By checking this box, you consent to receive information about future clinical trials run by AstraZeneca concerning COVID conditions. Either Havas Faze or AstraZeneca will contact you by email or telephone / SMS about these future trials.`,
    zipcode: 'Zipcode',
    travelDistance: 'How far would you travel to a trial site?',
    therapyAreaIntrest: 'Which therapy area trials are you interested in? (select one or more)',
    required: 'This field is required',
  },

  therapyAreas: {
    respiratory: 'Respiratory',
    infectious_diseases: 'Infectious diseases',
  },

  formValidation: {
    name_match: "^[A-Za-zÀ-ÖØ-öø-ÿāćčēėęīłńœōśšūŵŷžźżř\\]+[A-Za-zÀ-ÖØ-öø-ÿāćčēėęīłńœōśšūŵŷžźżř.\\- ',]*[A-Za-zÀ-ÖØ-öø-ÿāćčēėęīłńœōśšūŵŷžźżř]+\\s*$",
    phone_match: "^[+]?[- ()0-9]{8,}$"
  },

  errorCopy: {
    email: `Please enter your email address`,
    consent: `Please select the data checkbox`,
    consentFlareup:  `Please select the keep in contact checkbox`,
    required: ` is required`,
    pleaseEnter: `Please enter your`,
    zipcode: `Please enter your zip code`,
    searchRadius: `Please select a search distance using the drop-down options`,
    phone: `Please enter your phone number`,
    dob: `Please select your birth month & year`,
    ethnicity: `Please select your ethnicity from the drop-down options`,
    zipcode: `Please enter your zipcode`,
    state: `Please select your state`,
    travelDistance: 'Please select travel distance'
  },

  location: {
    copyData: {
      title: `You are not eligible`,
      title_2: `Search for a site`,
      title_3: `We've found clinical trial sites near you`,
      title_4: `Looks like our clinical trial sites are further away; try extending your search.`,
      subtitle: `What's next?`,
      subtext:[
        `<span>1</span> Find your nearest clinical trial site`,
        `<span>2</span> Submit your details`
      ],
      footnote: `Don't worry, you're not committing to anything at this stage.`,
      distanceAway: 'miles away',
      zipcodePlaceholder: 'Zipcode',
      resultText: 'Result',
      metaTitle: `Site Search - Not Eligible`,
      metaDescription: `Site Search - Not Eligible`,
    }
  },


  rejectionFormPage: {
    typeOfForm: 'Ineligible',
    copyData: {
      title: `Sorry, you're not eligible for our clinical trial right now, but we'd love to let you know about other AstraZeneca clinical trials which you could join in the future.`,
      requiredText: `Fields marked * are compulsory`,
      expandingTitle: `What your consent includes and how to withdraw your consent`,
      expandingContent: `
        <p>When you fill in this form or check a box above, you consent to Havas Faze and AstraZeneca:</p>
        <ul>
          <li>Sharing your information with third parties we work with, including those running the trial; and</li>
          <li>Transferring your information to countries outside the USA.</li>
        </ul>
        <p>
          You may withdraw any consents that you have given at any time by contacting us at <a data-cy="screener_consent-privacy-link" href="mailto:privacy@covidtrialandyou.com">privacy@covidtrialandyou.com</a> or, for SMS, by replying “Stop.”  If you withdraw your consent, we may no longer be able to use your information for trial recruitment purposes and / or you will no longer receive information about future AstraZeneca clinical trials.  
          <br/><br/>
          For more information about how we use your information, please see our Privacy and Cookies Notice. 
        </p>
      `,
      metaTitle:  `Please enter your details `,
      metaDescription:  `Please enter your details `,
      consent: `By checking this box, you consent to AstraZeneca or Havas Faze contacting you by email, telephone, MMS, SMS or any other messaging channels (such as WhatsApp) about future trials in the therapy areas you have indicated an interest in, that are run by AstraZeneca and that HavasFaze is recruiting for.`,
      consentRequired: 'This field is required',
      submitButtonText: 'LET\'S KEEP IN TOUCH'
    },
  },

  rejectionThankYou: {
    type: 'ThankYou',
    copyData: {
      title: `All done!`,
      copy: `Great, we’ll be in touch when we have a clinical trial that could be a good match for you. In the meantime, we’ll send some helpful information to your inbox.`,
      footNote: ``,
      metaTitle: `Thanks for submitting your details`,
      metaDescription: `We’ll be in touch soon`,
    },
    button: {
      learnMoreLabel: '',
      homePageLabel: 'Back to homepage',
      homePageLink: '/en-US',
    }
  },

  formPage: {
    typeOfForm: 'Eligible',
    copyData: {
      title: `Nearly there...`,
      subTitle: `Enter your details and we'll be in touch to arrange an appointment.`,
      trialTitle: `Clinical trial site selected`,
      legalBox:`
        <p><b>Just so you know!</b> By entering and submitting your information in this form, you are saying you agree to:</p>
        <ul>
          <li>Havas Faze (on behalf of AstraZeneca) using your information from the questionnaire and the form below, to decide whether to invite you to take part in a clinical trial offered on the Covid Trial and You website. Your information may be subject to automated assessment and analysis to help us make this decision. If you don't want your information to be assessed in this way, then we can't include you in a trial.</li>
          <li>Havas Faze (on behalf of AstraZeneca) contacting you about the trial on the Covid Trial and You website and similar trials run by AstraZeneca and that we are recruiting for, by email, telephone, MMS or SMS or other messaging channels.</li>
        </ul>
        <p><span>For more information please see our <a href="/en-US/privacypolicy" data-cy="screener-form_privacy-link" target="_blank">Privacy and Cookies Notice</a>.</span></p>
      `,
      detailsTitle: `Your details`,
      requiredText: `Fields marked * are compulsory`,
      expandingTitle: 'What your consent includes and how to withdraw your consent',
      expandingContent: `
        <p>When you fill in this form or check a box above, you consent to Havas Faze:</p>
        <ul>
          <li>Sharing your information with third parties we work with, including those running the trial; and</li>
          <li>Transferring your information to countries outside the USA.</li>
        </ul>
        <p>You may withdraw any consents that you have given at any time by contacting us at <a data-cy="screener_consent-privacy-link" href="mailto:privacy@covidtrialandyou.com">privacy@covidtrialandyou.com</a>. If you withdraw your consent, we will no longer be able to use your information for trial recruitment purposes and / or you will no longer receive information about future AstraZeneca clinical trials.</p>
        `,
      distanceAway: 'miles away',
      metaTitle:  `Please enter your details`,
      metaDescription:  `Please enter your details`
    },
    // siteData: {
    //   studySiteId: "634043b982defab802db409d",
    //   name: "PORTAL-US",
    //   addressLines: [
    //     "Wilshire Blvd",
    //     "test",
    //     "test",
    //     "test"
    //   ],
    //   addressPostcode: "902 10",
    //   addressCountry: "United States",
    //   distance: 23.88823414131048,
    //   distanceInMiles: 23.88823414131048,
    //   distanceInKm: 38.44429073297661,
    //   lat: 34.0670364,
    //   lng: -118.4093043,
    // },
  },
  successPage: {
    copyData: {
      title: ``,
      title_2: `You may now be eligible`,
      title_3: `We've found clinical trial sites near you`,
      title_4: `Looks like our clinical trial sites are further away; try extending your search.`,
      subtitle: `What's next?`,
      subtext:[
        `<span>1</span> Find your nearest clinical trial site`,
        `<span>2</span> Submit your details`
      ],
      footnote: `Don't worry, you're not committing to anything at this stage.`,
      distanceAway: 'miles away',
      zipcodePlaceholder: 'Zipcode',
      resultText: 'Result',
      metaTitle: `Site Search - Eligible`,
      metaDescription: `Site Search - Eligible`,
    },
  },

  created: {
    type: 'ThankYou',
    copyData: {
      title: `All done!`,
      copy: `You've successfully submitted your details. Our team will review your details, if we don't have a clinical trial that suits you right now, we’ll be in touch when we do. In the meantime, we’ll send  some helpful information to your inbox.`,
      metaTitle:  `Thanks for submitting your details`,
      metaDescription:  `Thanks for submitting your details`
    },
    button: {
      homePageLabel: 'Back To Homepage',
      homePageLink: '/en-US',
    }
  },

  alreadyRegistered: {
    type: 'AlreadyRegistered',
    copyData: {
      title: `You're already registered`,
      copy: [
        `Looks like you’ve already submitted an application.`,
        `Your reference number is: `,
        `We have your details and if we find a suitable trial, we'll be in touch. In the meantime, we’ll send some useful tips and information.`
      ],
      footNote: `If you’d like to withdraw your application, please email: <br/> <a data-cy="screener_consent-privacy-link" href="mailto:privacy@covidtrialandyou.com">privacy@covidtrialandyou.com</a>`,
      metaTitle:  `Sorry, you’re already registered`,
      metaDescription:  `Sorry, you’re already registered`
    }
  },

  entrance: {
    copyData: {
      title: `Want to find out if you could join a clinical trial?`,
      copy: `Answer a few questions and fill out your details. If we have a clinical trial that suits you, we’ll get in touch. If we don’t have one right now, we’ll send some helpful tips and contact you when we do.`,
      disclaimer: `Please note, if you’re eligible for a clinical trial, your details will only be shared with the clinical trial site after speaking to our team. No information will be shared with AstraZeneca.`,
      metaTitle: `Take part in the Supernova clinical trial`,
      metaDescription: `Find out if you can join the Supernova clinical trial`
    }
  },


}

export default ScreenerCopy;
