import React, { forwardRef } from "react"
import { Style } from "./styles";

const Section = forwardRef(({ children, ...props }, ref) => {
  return (
    <Style {...props} ref={ref}>
      <div className="container">
        {children}
      </div>
    </Style>
  )
});

export default Section;