import styled from "styled-components";

import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";
import Typography from "../Typography";

export const Style = styled.div`

`;

export const StepStyle = styled.div`
  background: ${colors.white};
  border: 2px solid ${colors.primary};
  border-radius: 0px 50px 0px 0px;
  display: flex;
  flex-direction: row;
  margin-bottom: -50px;
  padding: 40px 70px 105px 25px;
  text-align: left;

  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    align-items: center;
    padding: 55px 17.5% 105px 17.5%;
  } 

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 55px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      padding-bottom: 55px;
    }
  }
`

export const StepTitle = styled(Typography)`
  font-weight: 400;
  margin-bottom: 10px;
`

export const StepCount = styled(Typography)`
  margin: 0px;
  margin-right: 20px;
  width: 30px;
  text-align: center;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: 50px;
  } 

`