export const getUserQuestion = () => {
    const answers = JSON.parse(localStorage.getItem('submittedAnswers'));
    if (answers) {
        return parseInt(Object.keys(answers).pop()) + 1;
    } else {
        return 1;
    }
}

export const getUserStatus = () => {

    let url = new URL(window.location.href);
    let override = url.searchParams.get("forcevisible");

    if(override !== null){
        return override;
    }

    const answers = JSON.parse(localStorage.getItem('submittedAnswers'));

    if (answers) {
        const answerValues = Object.values(answers).map((answer) => answer.value);
        
        if (answerValues.includes('reject')) {
            return 'reject';
        } else {
            return 'proceed';
        }
    } else {
        return false;
    }


}

export const resetUserStatus = () => {
    return localStorage.removeItem('submittedAnswers')
}