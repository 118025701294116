export const getUrlParameter = function (name) {
    const paramKey = name.replace(/[/, '[').replace(/[]]/, ']');
    const regex = new RegExp(`[\\?&]${paramKey}=([^&#]*)`);
    const results = regex.exec(window.location.search);
  
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getPathNameArray = function (index) {
    return window.location.pathname.split("/")[index];
}