import styled from 'styled-components';
import {mediaQueriesSizes, colors} from "../../assets/styles/GlobalStyles.js";
import {Button} from "@lxlabs/faze-screener-components-library";

export const NavContainer = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
  transition-duration: .5s;

  &.hidden {
    top: -62px;
  }
`

export const LanuageWrap = styled.div`

  display: none;
  background: #140D4F;
  justify-content: end;
  width: 100%;

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    display: flex;
  }
`

export const ButtonStyled = styled(Button)`
  width: auto;
  margin: 10px 10px;
  padding: 10px 60px 10px 20px;
  border-color: white;
  background-image: url('/static-images/right-arrow-no-circle-white.svg');
  background-repeat: no-repeat;
  background-position: right 24px center;
  background-size: 17px;

  &:hover {
    background-image: url('/static-images/right-arrow-no-circle-black.svg') !important;
    background-repeat: no-repeat !important;
    background-position: right 24px center !important;
    background-size: 17px !important;
  }
  

  span {
    color: white;
    text-transform: initial;
  }

  &:hover {
    background: white;

    span {
      color: black;
    }
  }
`

export const HeaderWrap = styled.header`
  pointer-events: all;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 13px 20px;
  position: absolute;
  z-index: 10000;
  transition: 0.5s;

  &.center-nav-mobile {
    justify-content: center;
    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      justify-content: space-between;
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding-bottom: 16px;
    padding-top: 16px;
  }

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    padding: 20px;
    justify-content: flex-end;
    pointer-events: none;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    padding: 20px 40px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    padding: 32px 40px;
  }

  &.shrink-mobile {
    background: ${colors.white};
    box-shadow: rgb(35 39 43 / 16%) 0px 4px 16px 0px;

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      background: none;
      box-shadow: none;
    }
  }

  &.menu-solid-background {
    background: ${colors.white};
    box-shadow: rgb(35 39 43 / 16%) 0px 4px 16px 0px;
   

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      background: none;
      box-shadow: none;
      z-index: 5000;
    }
  }

  .language-btn {
    pointer-events: all;
    width: 40px;
    height: 40px;
    padding: 1px 0 0 0;
    font-size: 12px;
    line-height: 12px;
    background: #e2f3f8;
    color: white;

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-left: 10px;
      width: 55px;
      height: 55px;
      line-height: 23px;
      font-size: 16px;
      line-height: 16px;
      padding: 0;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      margin-left: 20px;
    }

    &.active {
      background: none;
      border: solid 1px black;
      z-index: 18
    }
  }
`;

export const Nav = styled.ul`
  display: inline-block;
  list-style: none;
  padding: 0 5px;
  margin: 0;
  transition: 0.5s;

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    padding: 0 15px 0 15px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    padding: 0 30px 0 20px;
  }

  &.shrink {
    transform: translateY(-100%);
  }

  li {
    display: inline-block;

    &.full {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  a {
    font-size: 14px;
    display: inline-block;
    text-decoration: none;
    position: relative;
    line-height: 16px;
    font-weight: 400;
    color: ${colors.black};
    text-transform: uppercase;

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      font-size: 13px;
      padding: 20px 35px 19px 35px;

      html[lang=es-US] & {
        padding-left: 10px;
        padding-right: 10px;
      }      
    }

    @media (min-width: 1356px) {
      font-size: 14px;

      html[lang=es-US] & {
        padding-left: 15px;
        padding-right: 15px;
      }      
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      font-size: 16px;
      padding: 20px 35px 19px 35px;

      html[lang=es-US] & {
        padding-left: 25px;
        padding-right: 25px;
      }       
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      padding-left: 30px;
      padding-right: 30px;
    }

    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      top: calc(50% - (6px / 2));
      left: 7px;
      border-radius: 50%;
      opacity: 1;
      transform: scale(0) translateY(-30px);
      background: ${colors.primary};

      @media (min-width: ${mediaQueriesSizes.xl}px) {
        left: 15px;

        html[lang=es-US] & {
          left: 0
        }         
      }    

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        html[lang=es-US] & {
          left: 5px;
        }          
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        left: 10px;
      }
    }

    &.active,
    &:hover {

      &:before {
        //transform:scale(1);
        animation: bounceDown 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
      }
    }
  }

  @keyframes bounceDown {
    0% {
      transform: scale(0) translateY(-30px);
    }
    1% {
      transform: scale(1) translateY(-30px);
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const LeftNav = styled.div`
  display: flex;
`;

export const BurgerWrap = styled.div`
  width: 25px;
  display: flex;
  align-items: center;

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 90vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 10;
  left: -100%;
  transition: 0.5s;
  background: ${colors.navy};

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    display: none;
  }

  &.active,
  &:focus-within {
    left: 0;
  }

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      font-family: PolySans;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      a {
        padding: 20px 0;
        display: block;
        text-decoration: none;
        color: ${colors.white};
      }
    }
  }

  .apply-now-btn-mobile-menu {
    width: 100%;
    border-radius: 0;
    position: absolute;
    bottom: 0;
    padding: 25px 20px 24px;
  }


  .language-btn-mobile-menu {
    border-radius: 50px;
    margin: 0 28px 0 28px;
    position: absolute;
    padding: 18px 25px 18px 25px;
    bottom: 90px;
    max-width: 100%;
    left: 0;
    right: 0;
    text-align: left;
}
  }

`;

export const CountryMenu = styled(MobileMenu)`
  pointer-events: all;
  align-items: start;
  left: auto;
  padding: 90px 30px 40px 30px;
  right: -100%;
  transition: right 0.5s;
  width: 83vw;

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    display: flex;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 175px 45px 40px 45px;
    transition: 0.75s;
    width: 526px;
  }

  &.active,
  &:focus-within {
    display: block;
    left: auto;
    right: 0;
    z-index: 17;
  }

  ul {
    li {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.05em;
      line-height: 19px;
      padding: 15px 0;
      text-align: left;

      @media (min-width: ${mediaQueriesSizes.xl}px) {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 36px;
      }

      a {
        cursor: pointer;
        display: inline-block;
        padding: 0;

        &.active {
          border-bottom: 2px solid black;
        }
      }
    }
  }

`;

export const MenuMask = styled.div`
  background: rgba(0, 0, 0, 0.2);
  display: none;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 16;

  &.active {
    display: block;

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      display: none;
    }
  }
`;

export const Burger = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
  width: 100%;
  display: block;
  height: 20px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  z-index: 15;

  &:hover {
    transform: scale(1.25);
  }

  span {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 1px;
    background: ${colors.white};
    transition: 0.5s;

    &:nth-of-type(2),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      top: 50%;
    }

    &:nth-of-type(3) {
      top: calc(100% - 1px);
    }
  }

  &.scrolled {
    span {
      background: ${colors.black};
    }
  }

  &.active {
    span {
      background: ${colors.white};
      top: 50%;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        opacity: 0;
      }

      &:nth-of-type(5) {
        transform: rotate(45deg);
      }

      &:nth-of-type(4) {
        transform: rotate(-45deg);
      }
    }
  }
`;

export const NavWrap = styled.div`
  display: none;
  justify-content: end;
  background: white;
  border-radius: 50px;
  height: 55px;
  overflow: hidden;
  pointer-events: all;

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    display: flex;
  }

  &.shrink {
    background: none;
    pointer-events: none;
  }

  .menu-btn {
    border: solid 2px white;
    width: 200px !important;
    margin-right: 10px;
    padding: 16px 0;
    font-size: 14px;
    pointer-events: all;

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      font-size: 16px;
    }
  }

  .apply-now-btn {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: center;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
    pointer-events: all;

    @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
      font-size: 13px;
    }

    @media (min-width: 1356px) {
      font-size: 14px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      font-size: 16px;
      min-width: 200px !important;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`;

export const ApplyMobileSticky = styled.div`
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: calc(100vw - 40px);
  z-index: 30;

  @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
    display: none;
  }
`;
