import React from 'react';
import { Button } from "@lxlabs/faze-screener-components-library";

import {
    StyledBlock,
    CirlcleContainer
} from './styles';

const AboutClinicalTrial = ({title, copy, button}) => {

    return (
        <StyledBlock>
            <span className={'circle'}></span>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'column text-column'}>
                        <h2>{title}</h2>
                        <p>{copy}</p>
                        <Button
                            cyAttribute={'about-clinical-trials_sign-up-now-button'}
                            label={button.label}
                            type={'outline'}
                            href={button.href}
                        />
                    </div>
                </div>
            </div>
            <CirlcleContainer>
                <span></span>
                <span></span>
                <span></span>
            </CirlcleContainer>
        </StyledBlock>
    );
}

export default AboutClinicalTrial;
