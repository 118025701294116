const ScreenerCopy = {

  config: {
    lang: 'es-US',
    studyCode: 'AzSup',
    unit: 'miles',
    urls: {
      questionnaire: 'cuestionario',
      eligible: 'elegible',
      yourdetails: 'susdatos',
      thankyou: 'gracias',
      noteligible: 'noeselegible',
      notyeteligible: 'aunnoeselegible',
      question: 'preguntas',
      alreadyregistered: 'yaestaregistrado',
    }
  },

  buttonLabels: {
    keepInTouch: `Sigamos en contacto`,
    findSite: `VAMOS`,
    findSite2: `VAMOS`,
    searchAgain: `Buscar nuevamente`,
    select: `SELECCIONAR`,
    next: `Siguiente`,
    submit: `Enviar sus datos`,
    changeSite: 'Cambiar el centro',
    backToHome: 'Volver a la página de inicio',
    entrance: `Empecemos`,
    learnMore: `Obtenga más información`
  },

  distances: [
    {
      value: '',
      label: 'Distancia'
    },
    {
      value: 25,
      label: 'Hasta 25 millas'
    },
    {
      value: 50,
      label: 'Hasta 50 millas'
    },
    {
      value: 75,
      label: 'Hasta 75 millas'
    },
    {
      value: 100,
      label: 'En un radio de 100 millas'
    },
    {
      value: 200,
      label: 'Hasta 200 millas'
    },
    {
      value: 5000000,
      label: 'Sin límites'
    },
  ],

  monthList: [
    {
      number: '01',
      text: 'Enero'
    },
    {
      number: '02',
      text: 'Febrero'
    },
    {
      number: '03',
      text: 'Marzo'
    },
    {
      number: '04',
      text: 'Abril'
    },
    {
      number: '05',
      text: 'Mayo'
    },
    {
      number: '06',
      text: 'Junio'
    },
    {
      number: '07',
      text: 'Julio'
    },
    {
      number: '08',
      text: 'Agosto'
    },
    {
      number: '09',
      text: 'Septiembre'
    },
    {
      number: '10',
      text: 'Octubre'
    },
    {
      number: '11',
      text: 'Noviembre'
    },
    {
      number: '12',
      text: 'Diciembre'
    },
  ],

  ethnicityList: [
    {
      'value': '',
      'label': '',
    },
    {
      'value': '2',
      'label': 'Nativo de Alaska',
    },
    {
      'value': '2',
      'label': 'Indígena estadounidense',
    },
    {
      'value': '3',
      'label': 'Asiático',
    },
    {
      'value': '4',
      'label': 'Negro o afroamericano',
    },
    {
      'value': '5',
      'label': 'Nativo de Hawái y otras islas del Pacífico',
    },
    {
      'value': 'Z',
      'label': 'Prefiero no decir',
    },
    {
      'value': '7',
      'label': 'Dos o más razas',
    },
    {
      'value': '6',
      'label': 'Blanco',
    },
  ],

  states:[
    {
      label: ''
    },
    {
      label: 'Alabama'
    },
    {
      label: 'Alaska',
    },
    {
      label: 'Arizona',
    },
    {
      label: 'Arkansas',
    },
    {
      label: 'California',
    },
    {
      label: 'Colorado',
    },
    {
      label: 'Connecticut',
    },
    {
      label: 'Delaware',
    },
    {
      label: 'Florida',
    },
    {
      label: 'Georgia',
    },
    {
      label: 'Hawái',
    },
    {
      label: 'Idaho',
    },
    {
      label: 'Illinois',
    },
    {
      label: 'Indiana',
    },
    {
      label: 'Iowa',
    },
    {
      label: 'Kansas',
    },
    {
      label: 'Kentucky',
    },
    {
      label: 'Luisiana',
    },
    {
      label: 'Maine',
    },
    {
      label: 'Maryland',
    },
    {
      label: 'Massachusetts',
    },
    {
      label: 'Michigan',
    },
    {
      label: 'Minnesota',
    },
    {
      label: 'Mississippi',
    },
    {
      label: 'Missouri',
    },
    {
      label: 'Montana',
    },
    {
      label: 'Nebraska',
    },
    {
      label: 'Nevada',
    },
    {
      label: 'Nuevo Hampshire',
    },
    {
      label: 'Nueva Jersey',
    },
    {
      label: 'Nuevo México',
    },
    {
      label: 'Nueva York',
    },
    {
      label: 'Carolina del Norte',
    },
    {
      label: 'Dakota del Norte',
    },
    {
      label: 'Ohio',
    },
    {
      label: 'Oklahoma',
    },
    {
      label: 'Oregón',
    },
    {
      label: 'Pensilvania',
    },
    {
      label: 'Rhode Island',
    },
    {
      label: 'Carolina del Sur',
    },
    {
      label: 'Dakota del Sur',
    },
    {
      label: 'Tennessee',
    },
    {
      label: 'Texas',
    },
    {
      label: 'Utah',
    },
    {
      label: 'Vermont',
    },
    {
      label: 'Virginia',
    },
    {
      label: 'Washington',
    },
    {
      label: 'Virginia Occidental',
    },
    {
      label: 'Wisconsin',
    },
    {
      label: 'Wyoming',
    },
  ],

  formLabels: {
    dob:{
      day: 'Día',
      month: 'Mes',
      year: 'Año',
    },
    changeSite: 'Cambiar el centro',
    firstname: `Nombre*`,
    lastname: `Apellido*`,
    email: `Dirección de correo electrónico*`,
    email_required: `Dirección de correo electrónico*`,
    dateOfBirth_required: `Mes y año de nacimiento`,
    sex: `Sexo`,
    male: `Masculino`,
    female: `Femenino`,
    preferNotToSay: `Prefiero no decir`,
    ethnicity: `Grupo étnico`,
    address: `address`,
    address_zipcode: `Código postal* (esto nos ayuda a encontrar un ensayo cerca de su área)`,
    address_state:`Estado`,
    phone_required: `Número de teléfono*`,
    mobile: `Número de teléfono móvil (si nos facilita su número de teléfono móvil, le enviaremos en breve otro SMS para obtener su consentimiento)`,
    mobile2: 'Número de celular',
    contact: `Horario más adecuado para comunicarse`,
    contact_morning: `Mañana`,
    contact_lunch: `Hora del almuerzo`,
    contact_evening: `Noche`,
    contact_method_email: `Correo electrónico`,
    contact_method_sms: `SMS`,
    contact_method_phone: `Teléfono`,
    contact_method: `Cuál es su forma de contacto preferida?`,
    hcp_refferal: `¿Fue remitido por un profesional de atención médica?`,
    hcp_refferal_yes: `Sí`,
    hcp_refferal_no: `No`,
    minimum_char_error:`Debe tener *** caracteres como mínimo.`,
    consent: `Al marcar las casillas correspondientes a continuación, usted acepta que Havas Faze se comunique con usted (por correo electrónico, teléfono, MMS, SMS o cualquier otro canal de mensajería, por ejemplo, WhatsApp):`,
    consent_location_intro: 'Con el fin de <b>evaluar su elegibilidad para el ensayo en el que ha expresado interés</b>, necesitamos recopilar y procesar la <b>información médica</b> que provee en el cuestionario y enviarla a profesionales de atención médica externos (que están llevando a cabo los ensayos correspondientes para los que usted es elegible). Para invitarlo a participar en estos ensayos, también necesitamos comunicarnos con usted.',
    consent_location: `Al marcar esta casilla, usted acepta que Havas Faze recopile y procese su información de salud para los fines indicados anteriormente.<br><br><b>Si no presta su consentimiento</b>, no podremos evaluar su elegibilidad para participar en los ensayos clínicos que se ofrecen, proceder a tramitar su solicitud ni invitarlo a participar en los ensayos clínicos.`,
    consent_ethnicity: `Al marcar esta casilla, usted acepta que Havas Faze utilice datos sobre <b>su origen étnico</b> para supervisar la diversidad de los participantes del ensayo. Podemos compartir datos de etnicidad en forma agregada (para que no pueda ser identificado) con AstraZeneca.`,
    consent_flareup: `*Al marcar esta casilla, usted da su consentimiento para que AstraZeneca y su socio Havas Faze se comuniquen con usted para saber: si es elegible para participar en este ensayo, si recientemente ha tenido algún brote o si su estado de salud ha cambiado.`,
    consent_feedback: `Al marcar esta casilla, usted acepta que Havas Faze, AstraZeneca (o un socio externo) se comunique con usted (por correo electrónico, teléfono, MMS, SMS o cualquier otro canal de mensajería, por ejemplo, WhatsApp) para solicitar <b>sus comentarios</b> sobre el proceso del ensayo y lo que se puede mejorar.`,
    future_respiratory_trials: `para brindarle información acerca de <b>futuros ensayos</b> que son ensayos sobre el <b>coronavirus y enfermedades respiratorias similares</b> realizados por AstraZeneca y para los que estamos inscribiendo participantes.`,
    future_infectious_disease_trials: `para brindarle información acerca de <b>futuros ensayos</b> que son ensayos sobre el <b>coronavirus o enfermedades infecciosas similares</b> realizados por AstraZeneca y para los que estamos inscribiendo participantes.`,
    supplied_future_trials: `*Al marcar esta casilla, acepta recibir información sobre futuros ensayos clínicos realizados por AstraZeneca en relación con las enfermedades de la COVID. Havas Faze o AstraZeneca se comunicarán con usted por correo electrónico o teléfono/SMS para informarle sobre estos futuros ensayos.`,
    zipcode: 'Código postal',
    travelDistance: '¿Hasta dónde viajaría para asistir a un centro del ensayo?',
    therapyAreaIntrest: '¿Qué ensayos del área de terapia le interesan? (seleccione uno o más)',
    required: 'Este campo es obligatorio',
  },

  therapyAreas: {
    respiratory: 'Respiratorio',
    infectious_diseases: 'Enfermedades infecciosas',
  },

  formValidation: {
    name_match: "^[A-Za-zÀ-ÖØ-öø-ÿāćčēėęīłńœōśšūŵŷžźżř\\]+[A-Za-zÀ-ÖØ-öø-ÿāćčēėęīłńœōśšūŵŷžźżř.\\- ',]*[A-Za-zÀ-ÖØ-öø-ÿāćčēėęīłńœōśšūŵŷžźżř]+\\s*$",
    phone_match: "^[+]?[- ()0-9]{8,}$"
  },

  errorCopy: {
    email: `Ingrese su dirección de correo electrónico`,
    consent: `Seleccione la casilla de datos`,
    consentFlareup:  `Seleccione la casilla de seguir en contacto`,
    required: ` es obligatorio`,
    pleaseEnter: `Ingrese su`,
    zipcode: `Ingrese su código postal`,
    searchRadius: `Seleccione una distancia de búsqueda utilizando las opciones desplegables`,
    phone: `Ingrese su número de teléfono`,
    dob: `Seleccione el mes y año de su nacimiento`,
    ethnicity: `Seleccione su grupo étnico entre las opciones desplegables`,
    zipcode: `Ingrese su código postal`,
    state: `Seleccione su estado`,
    travelDistance: 'Seleccione la distancia para los traslados'
  },

  location: {
    copyData: {
      title: `No es elegible`,
      title_2: `Buscar un centro`,
      title_3: `Hemos encontrado centros del ensayo cerca de usted`,
      title_4: `Parece que nuestros centros del ensayo están más lejos; intente ampliar la búsqueda`,
      subtitle: `¿Cuál es el próximo paso?`,
      subtext:[
        `<span>1</span> Busque el centro del ensayo más cercano`,
        `<span>2</span> Envíe sus datos`
      ],
      footnote: `No se preocupe, no se compromete a nada en esta etapa.`,
      distanceAway: 'millas de distancia',
      zipcodePlaceholder: 'Código postal',
      resultText: 'Resultado',
      metaTitle: `Búsqueda de centro: No es elegible`,
      metaDescription: `Búsqueda de centro: No es elegible`,
    }
  },


  rejectionFormPage: {
    typeOfForm: 'Ineligible',
    copyData: {
      title: `Lo sentimos, no es elegible para nuestro ensayo. Pero nos encantaría informarle sobre otros ensayos de AstraZeneca en los que podría participar en el futuro.`,
      requiredText: `Los campos marcados con un * son obligatorios`,
      expandingTitle: `Qué incluye su consentimiento y cómo revocarlo`,
      expandingContent: `
        <p>Al completar este formulario o marcar alguna de las casillas anteriores, presta su consentimiento para permitir a Havas Faze y AstraZeneca hacer lo siguiente:</p>
        <ul>
          <li>Compartir su información con los terceros con quienes trabajemos, incluidos aquellos que realicen el ensayo.</li>
          <li>Transferir su información a otros países.</li>
        </ul>
        <p>
        Puede retirar en cualquier momento un consentimiento que haya prestado comunicándose con nosotros en la siguiente dirección: <a data-cy="screener_consent-privacy-link" href="mailto:privacy@covidtrialandyou.com">privacy@covidtrialandyou.com</a> o por SMS, respondiendo “Stop” (Parar)  Si revoca su consentimiento, ya no podremos utilizar su información como parte de las inscripciones para nuestro ensayo y ya no recibirá información sobre futuros ensayos clínicos de AstraZeneca.&nbsp;&nbsp; <br/><br/> Para obtener más información sobre cómo usaremos su información, consulte nuestro Aviso de privacidad y cookies.&nbsp;
        </p>
      `,
      metaTitle:  `Ingrese sus datos `,
      metaDescription:  `Ingrese sus datos `,
      consent: `Al marcar esta casilla, presta su consentimiento para que AstraZeneca o Havas Faze se comuniquen con usted por correo electrónico, teléfono, MMS, SMS o cualquier otro canal de mensajería (como WhatsApp) para informarle sobre futuros ensayos en las áreas de terapia en las que a expresado interés que sean realizados por AstraZeneca y para los que Havas Faze esté inscribiendo participantes.`,
      consentRequired: 'Este campo es obligatorio',
      submitButtonText: 'SIGAMOS EN CONTACTO'
    },
  },

  rejectionThankYou: {
    type: 'ThankYou',
    copyData: {
      title: `¡Todo listo!`,
      copy: `Excelente, nos comunicaremos con usted cuando tengamos un ensayo que sea adecuado para usted. Mientras tanto, le enviaremos información útil a la bandeja de entrada.`,
      footNote: ``,
      metaTitle: `Gracias por enviar sus datos`,
      metaDescription: `Nos comunicaremos con usted pronto`,
    },
    button: {
      learnMoreLabel: '',
      homePageLabel: 'Volver a la página de inicio',
      homePageLink: '/es-US',
    }
  },

  formPage: {
    typeOfForm: 'Eligible',
    copyData: {
      title: `Falta poco...`,
      subTitle: `Ingrese sus datos y nos comunicaremos con usted para programar una cita.`,
      trialTitle: `Centro del ensayo seleccionado`,
      legalBox:`
        <p><b>¡Hay algo que debe saber!</b> Al ingresar y enviar su información en este formulario, acepta lo siguiente:</p>
        <ul>
          <li>Que Havas Faze (en nombre de AstraZeneca) utilice la información del cuestionario y del formulario a continuación para decidir si lo invitarán a participar en un ensayo clínico que se ofrece en el sitio web COVID Trial and You. Que su información pueda estar sujeta a evaluación y análisis automatizados para ayudarnos a tomar esta decisión. Si usted no quiere que su información se evalúe de esta manera, no podremos incluirlo en un ensayo.</li>
          <li>Que Havas Faze (en nombre de AstraZeneca) se comunique con usted por correo electrónico, teléfono, MMS o SMS u otros canales de mensajería para informarle sobre el ensayo en el sitio web COVID Trial and You y otros ensayos similares realizados por AstraZeneca y para los que estemos inscribiendo participantes.</li>
        </ul>
        <p><span>Para obtener más información, consulte nuestro <a href="/es-US/politicadeprivacidad" target="_blank">Aviso de privacidad y cookies</a>.</span></p>
      `,
      detailsTitle: `Sus datos`,
      requiredText: `Los campos marcados con un * son obligatorios`,
      expandingTitle: 'Qué incluye su consentimiento y cómo revocarlo',
      expandingContent: `
        <p>Al completar este formulario o marcar alguna de las casillas anteriores, usted presta su consentimiento para permitir a Havas Faze hacer lo siguiente:</p>
        <ul>
          <li>Compartir su información con los terceros con quienes trabajemos, incluidos aquellos que realicen el ensayo.</li>
          <li>Transferir su información a otros países.</li>
        </ul>
        <p>Puede retirar su consentimiento en cualquier momento comunicándose con nosotros en la siguiente dirección: <a data-cy="screener_consent-privacy-link" href="mailto:privacy@covidtrialandyou.com">privacy@covidtrialandyou.com</a>. Si revoca su consentimiento, ya no podremos usar su información con fines de reclutamiento para ensayos y ya no recibirá información sobre futuros ensayos clínicos de AstraZeneca.</p>
        `,
      distanceAway: 'millas de distancia',
      metaTitle:  `Ingrese sus datos`,
      metaDescription:  `Ingrese sus datos`
    },
  },
  successPage: {
    copyData: {
      title: ``,
      title_2: `Usted podría ser elegible ahora`,
      title_3: `Hemos encontrado centros del ensayo cerca de usted`,
      title_4: `Parece que nuestros centros del ensayo están más lejos; intente ampliar la búsqueda`,
      subtitle: `¿Cuál es el próximo paso?`,
      subtext:[
        `<span>1</span> Busque el centro del ensayo más cercano`,
        `<span>2</span> Envíe sus datos`
      ],
      footnote: `No se preocupe, no se compromete a nada en esta etapa.`,
      distanceAway: 'millas de distancia',
      zipcodePlaceholder: 'Código postal',
      resultText: 'Resultado',
      metaTitle: `Búsqueda de centro: Elegible`,
      metaDescription: `Búsqueda de centro: Elegible`,
    },
  },

  created: {
    type: 'ThankYou',
    copyData: {
      title: `¡Todo listo!`,
      copy: `Ha enviado correctamente sus datos. Nuestro equipo revisará sus datos. Si no tenemos un ensayo que sea adecuado para usted en este momento, nos comunicaremos cuando tengamos uno. Mientras tanto, le enviaremos información útil a la bandeja de entrada.`,
      metaTitle:  `Gracias por enviar sus datos`,
      metaDescription:  `Gracias por enviar sus datos`
    },
    button: {
      homePageLabel: 'Volver a la página de inicio',
      homePageLink: '/es-US',
    }
  },

  alreadyRegistered: {
    type: 'AlreadyRegistered',
    copyData: {
      title: `Ya se inscribió`,
      copy: [
        `Parece que ya ha enviado una solicitud.`,
        `Su número de referencia es: `,
        `Tenemos sus datos y si encontramos un ensayo adecuado, nos comunicaremos con usted. Mientras tanto, le enviaremos algunos consejos e información útil.`
      ],
      footNote: `Si quiere retirar su solicitud, envíe un correo electrónico a: <br/> <a data-cy="screener_consent-privacy-link" href="mailto:privacy@covidtrialandyou.com">privacy@covidtrialandyou.com</a>.`,
      metaTitle:  `Lo sentimos, usted ya se registró`,
      metaDescription:  `Lo sentimos, usted ya se registró`
    }
  },

  entrance: {
    copyData: {
      title: `¿Quiere obtener información sobre si podría participar en un ensayo?`,
      copy: `Responda algunas preguntas y escriba sus datos. Si tenemos un ensayo que sea adecuado para usted, nos comunicaremos. Si no tenemos uno en este momento, le enviaremos algunos consejos útiles y nos comunicaremos con usted cuando lo encontremos.`,
      disclaimer: `Tenga en cuenta que si es elegible para un ensayo, sus datos solo se enviarán a un centro del ensayo después de hablar con nuestro equipo. No se enviará ninguna información a AstraZeneca.`,
      metaTitle: `Participe en el ensayo clínico Supernova`,
      metaDescription: `Averigüe si puede participar en el ensayo clínico Supernova`
    }
  },


}

export default ScreenerCopy;
