import React, { useRef } from 'react';

import { InputField, CopyButton } from './styles';

const SiteShare = ({ copyButtonText, url }) => {
  const input = useRef(null);

  const siteURL = url || window.location.hostname || process.env.GATSBY_SITE_URL;

  const handleInputClick = () => {
    input.current.select();
  }

  const copyURL = () => {
    handleInputClick();

    navigator.clipboard.writeText(input.current.value);
  }

  return (
    <>
      <InputField ref={input} onClick={() => handleInputClick()} value={siteURL} />
      <CopyButton label={copyButtonText} onClick={() => copyURL()} />
    </>
  )
}

export default SiteShare;