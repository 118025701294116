import React from 'react';
import PropTypes from 'prop-types';

import DesktopLogo from './assets/logo-desktop.jsx';

import {
  LogoWrapper,
} from './styles';

const Logo = ({logo, link, className}) => {

    return (
      <LogoWrapper className={className}>
        <a data-cy="logo" href={link} className={'logo-block mobile-logo'}>
          <img src={'/static-images/logo/logo-mobile.svg'} alt="COVID Trial and You logo" />
        </a>
        <a data-cy="logo" href={link} className={'logo-block mobile-logo-white'}>
          <img src={'/static-images/logo/logo-mobile-white.svg'} alt="COVID Trial and You logo"/>
        </a>
        <a data-cy="logo" href={link} className={'logo-block desktop-logo'}>
          {/* <img src={DesktopLogo} alt="COVID Trial and You logo"/> */}

          <DesktopLogo />
        </a>
      </LogoWrapper>
    )
};

export default Logo;
