import styled from "styled-components";

import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  background: ${colors.secondaryAlternate};
  min-height: 100vh;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  

  .grid-container {
    display: grid;
    width: 100%;
    padding: 100px 0;
    margin: 0px auto;

    grid-template-areas:
    "header"
    "image"
    "body";

    @media (min-width: ${mediaQueriesSizes.md}px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
      "header image"
      "body image";
      padding-top: 200px;
    } 

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 100%;
    } 

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 92%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      width: 100%;
    }
  }

  .header-area {
    grid-area: header;
  }

  .body-area {
    grid-area: body;
  }
  
  .image-area {
    grid-area: image;
  }

  .text-column {
    padding: 20px 0 0;
    position: relative;
    z-index: 1;
    
    h1 {
      font-size: 36px;
      font-weight: 400;
      line-height: 36px;
      margin: 0;
      text-align: center;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 44px;
        font-weight: 400;
        line-height: 53px;
        text-align: left;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 50px;
        line-height: 60px;
      }      
    }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      margin: 30px 0 0;
      text-align: center;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 18px;
        line-height: 31px;        
        text-align: left;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 20px;
        line-height: 35px;        
      }      
    }
  }

  a {
    margin-top: 70px;
    width: 100%;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      margin-top: 80px;
      width: 320px;
    }
  }

  .image-container {
    padding: 90px 20px 0;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      padding: 0 20px;
      
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding: 0 50px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      padding: 0 180px 0 125px;
    }

    img {
      width: 100%;
    }
  }

  .md-hide {
    display: block;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      display: none;
    }
  }
`;

export const AccordionWrapper = styled.div`
  border-top: solid 1px black;
  margin-top: 75px;
  width: 100%;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    margin-top: 65px;
  }  
`;

export const AccordionItem = styled.div`
  &.accordion-item {
    padding: 30px 0;
    border-bottom: solid 1px black;

    h3 {
      cursor: pointer;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      position: relative;
      padding-right: 40px;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 19px;
        line-height: 32px;
        padding: 0 75px 0 25px;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 22px;
        line-height: 36px;
      }

      span {
        transition: 0.25s;
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        display: block;
        border: black solid 1px;
        border-radius: 50%;
        display: flex;
        cursor: pointer;

        @media (min-width: ${mediaQueriesSizes.desktopNav}px) {
          &:hover {
            transform: scale(1.5);
          }
        }

        &:before,
        &:after {
          position: absolute;
          top: 50%;
          left: calc(50% - 7.5px);
          width: 15px;
          height: 1px;
          display: block;
          background: black;
          content: "";
          transition: 0.25s;
        }

        &:before {
          transform: rotate(90deg);
        }

        @media (min-width: ${mediaQueriesSizes.md}px) {
          border: none;
          right: 25px;

          &:before,
          &:after {
            width: 30px;
            left: calc(50% - 15px);
          }
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      margin: 0;
      text-align: left;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 14px;
        line-height: 23px;        
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 16px;
        line-height: 26px;        
      }
    }

    ul {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      margin: 40px 0 20px;     
      padding-left: 16px;
      text-align: left;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 14px;
        line-height: 23px;        
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 16px;
        line-height: 26px;        
      }      
    }

    .accordion-item-content {
      height: 0;      
      overflow: hidden;
      padding: 0 50px 0 0;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        padding: 0 75px 0 25px;
      }
    }

    &.active {
      h3 {
        span {
          transform: rotate(45deg);

          @media (min-width: ${mediaQueriesSizes.md}px) {
            transform: rotate(0deg);

            &:before {
              transform: rotate(0deg);
            }
          }
        }
      }

      .accordion-item-content {
        height: auto;
      }
    }
  }
`;
