export const isRequired = false;

export const Config = {
    logo: 'combined'
}

export const FooterNav = {
    links: [
        {
            title: 'Homepage',
            to: '/en-GB/'
        },
        {
            title: 'SUPERNOVA Trial',
            to: '/en-GB/supernovatrial'
        },
        {
            title: 'About clinical trials',
            to: '/en-GB/aboutclinicaltrials'
        },
        {
            title: 'About COVID-19',
            to: '/en-GB/aboutcovid19'
        },
        {
            title: 'FAQs',
            to: '/en-GB/faqs'
        },
    ],
    apply: {
        to: '/en-GB/trialclosed',
        title: 'Sign Up Now'
    }
};

export const LanguageSwitchButton = {
    languageSwitch: {
        title: 'Idioma: Español',
        isHidden: true
    }
}

export const FooterButton = [
    {
        btnTitle: 'Sign Up Now',
        to:'/en-GB/trialclosed'
    }
];
export const FooterBottom = [
    {
        title: 'You may report side effects related to AstraZeneca products by clicking',
        linkTitle: 'here',
        to: 'https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/global/en/amp-form.html'
    },
    {
        title: 'Privacy Policy',
        to: '/en-GB/privacypolicy'
    }
];

export const HeaderNavigation = {
    links: [
        {
            title: 'Supernova Trial',
            to: '/en-GB/supernovatrial'
        },
        {
            title: 'About clinical trials',
            to: '/en-GB/aboutclinicaltrials'
        },
        {
            title: 'About COVID-19',
            to: '/en-GB/aboutcovid19'
        },
        {
            title: 'FAQs',
            to: '/en-GB/faqs'
        }
    ],
    apply: {
        to: '/en-GB/trialclosed',
        title: 'Sign Up Now'
    },
    menu: 'MENU',
    lang: 'EN',
}

