import React, {useEffect} from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  StyledBlock,
  SlideInner,
  SliderContainer,
  TextWrap,
  ImgWrap,
  Row,
  H1,
} from './styles';

const ClinicalTrialsSlider = ({slides, mobileTitle}) => {

  useEffect(() => {
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };


  return (
    <StyledBlock>
      <div className={'container'}>
        <SliderContainer>
          <H1>{mobileTitle}</H1>

          <Slider {...settings}>

            {slides.map((slide, key) => {
              return (
                <div key={key}>
                  <SlideInner>
                    <ImgWrap>
                      <img src={slide.image} alt=""/>
                    </ImgWrap>
                    <TextWrap>
                      <div>
                        <Row>
                          <span className={'number'}>0{key + 1}/0{slides.length}</span>
                        </Row>
                        <h2>{slide.title}</h2>
                        <p>{slide.copy}</p>
                      </div>
                    </TextWrap>
                  </SlideInner>
                </div>
              )
            })}
          </Slider>
        </SliderContainer>
      </div>
    </StyledBlock>
  );

}

export default ClinicalTrialsSlider;
