import React from 'react';

const LogoDesktop = () => {
  return (
    <svg width="236" height="61" viewBox="0 0 236 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.8404 8.59617C13.8404 4.55949 16.8396 1.47119 20.6991 1.47119C24.1777 1.47119 26.9556 3.76918 27.4719 7.14929H25.2102C24.8291 4.93641 23.01 3.36794 20.7114 3.36794C17.9704 3.36794 16.0406 5.56866 16.0406 8.59617C16.0406 11.6237 17.9704 13.873 20.7114 13.873C23.01 13.873 24.8291 12.2924 25.2102 10.0674H27.4719C26.9556 13.4718 24.1654 15.7576 20.6991 15.7576C16.8396 15.7576 13.8404 12.645 13.8404 8.59617Z" fill="black"/>
    <path d="M65.785 15.4658V1.763H70.1731C74.5981 1.763 77.1425 4.54733 77.1425 8.55969C77.1425 12.5721 74.4998 15.478 70.1731 15.478H65.785V15.4658ZM67.9114 13.6542H70.0748C73.2583 13.6542 74.9791 11.7088 74.9791 8.55969C74.9791 5.4106 73.2829 3.57464 70.0748 3.57464H67.9114V13.6542Z" fill="black"/>
    <path d="M129.726 36.634C130.304 34.9561 130.648 32.9135 130.734 30.7128H135.221C135.122 33.9105 134.164 37.2541 132.615 39.8318L138.552 46.4217H132.467L129.714 43.3699C127.108 45.7044 124.06 46.9081 120.876 46.9081C113.931 46.9081 109.482 42.8957 109.482 36.6462C109.482 32.7797 111.989 29.6793 115.812 27.8555C114.312 25.8493 113.587 24.0863 113.587 21.7883C113.587 17.1072 117.594 13.6663 122.954 13.6663C128.313 13.6663 132.123 17.01 132.221 21.9221H127.637C127.391 19.1012 125.326 17.5328 122.954 17.5328C120.446 17.5328 118.565 19.3444 118.565 21.8369C118.565 23.989 119.721 25.4724 121.995 28.05L129.726 36.634ZM121.282 42.9322C123.507 42.9322 125.621 42.069 127.219 40.5978L118.332 30.7128C115.874 31.7098 114.177 33.716 114.177 36.3422C114.177 39.7345 116.451 42.9322 121.282 42.9322Z" fill="black"/>
    <path d="M123.113 59.7598C106.802 59.7598 93.5396 46.6406 93.5396 30.5061C93.5396 14.3715 106.815 1.25232 123.113 1.25232C139.412 1.25232 152.687 14.3715 152.687 30.5061C152.687 46.6406 139.424 59.7598 123.113 59.7598ZM123.113 5.94556C109.433 5.94556 98.2964 16.9613 98.2964 30.4939C98.2964 44.0265 109.433 55.0422 123.113 55.0422C136.794 55.0422 147.93 44.0265 147.93 30.4939C147.93 16.9613 136.794 5.94556 123.113 5.94556Z" fill="black"/>
    <path d="M19.1012 45.7165C17.6016 46.3853 16.2987 46.6284 14.7623 46.6284C10.03 46.6284 6.7481 44.0994 6.7481 38.5065V24.9374H1.09393V20.8278H6.7481V13.3259H11.6279V20.8278H19.3471V24.9374H11.6156V37.4C11.6156 40.8896 13.3118 42.1297 15.9054 42.1297C17.0608 42.1297 18.081 41.9352 19.0889 41.461L19.1012 45.7165Z" fill="black"/>
    <path d="M28.8731 46.1543L23.9933 46.1664L23.9442 20.8643L28.4798 20.8521L28.4921 26.2019C30.2744 22.7124 34.1339 20.5117 38.3254 20.4995L38.3377 25.5089C32.831 25.2292 28.8362 27.6245 28.8485 33.2175L28.8731 46.1543Z" fill="black"/>
    <path d="M42.6766 46.1421V20.84H47.5564V46.1421H42.6766Z" fill="black"/>
    <path d="M70.0379 42.2271C68.44 45.0479 65.6007 46.6163 61.8271 46.6163C56.5171 46.6163 53.0386 43.6131 53.0386 38.9807C53.0386 34.02 57.0457 31.4423 64.6788 31.4423C66.1784 31.4423 67.3829 31.491 69.4602 31.7341V29.8739C69.4602 26.2384 67.4813 24.1958 64.1011 24.1958C60.5242 24.1958 58.3609 26.2992 58.2134 29.8252H53.7761C54.0219 24.1471 58.1273 20.3658 64.1011 20.3658C70.4313 20.3658 74.0942 23.904 74.0942 29.9225V46.1543H70.0379V42.2271ZM62.7982 43.1876C66.9036 43.1876 69.4602 40.6586 69.4602 36.7921V34.7373C67.5796 34.4941 66.2275 34.4455 64.9738 34.4455C60.094 34.4455 57.6848 35.8802 57.6848 38.8348C57.6725 41.4611 59.6515 43.1876 62.7982 43.1876Z" fill="black"/>
    <path d="M80.6088 46.1421V11.8547H85.5377V46.1421H80.6088Z" fill="black"/>
    <path d="M169.281 55.2733H163.971L167.634 45.7287L157.542 20.7548H162.803L170.24 40.0506L177.148 20.7548H182.076L169.281 55.2733Z" fill="black"/>
    <path d="M228.945 20.7548H233.824V46.0692H229.289V42.0568C227.937 44.6831 224.95 46.5433 221.471 46.5433C216.309 46.5433 212.732 43.5402 212.732 37.0353V20.7548H217.612V36.0869C217.612 40.4762 219.836 42.3 223.02 42.3C226.204 42.3 228.957 39.6251 228.957 35.6613L228.945 20.7548Z" fill="black"/>
    <path d="M195.991 24.2444C200.883 24.2444 204.865 28.1838 204.865 33.0229C204.865 37.8621 200.883 41.8015 195.991 41.8015C191.099 41.8015 187.116 37.8621 187.116 33.0229C187.116 28.1838 191.099 24.2444 195.991 24.2444ZM195.991 19.7457C188.579 19.7457 182.568 25.6913 182.568 33.0229C182.568 40.3546 188.579 46.3002 195.991 46.3002C203.402 46.3002 209.413 40.3546 209.413 33.0229C209.413 25.6913 203.402 19.7457 195.991 19.7457Z" fill="black"/>
    <path d="M36.3957 3.51385C39.235 3.51385 41.5582 5.79967 41.5582 8.62048C41.5582 11.4413 39.2473 13.7271 36.3957 13.7271C33.544 13.7271 31.2332 11.4413 31.2332 8.62048C31.2332 5.79967 33.5563 3.51385 36.3957 3.51385ZM36.3957 1.47119C32.4009 1.47119 29.1682 4.66892 29.1682 8.62048C29.1682 12.572 32.4009 15.7698 36.3957 15.7698C40.3904 15.7698 43.6231 12.572 43.6231 8.62048C43.6231 4.66892 40.3904 1.47119 36.3957 1.47119Z" fill="black"/>
    <path d="M51.4652 15.5509H49.1175L43.9919 1.75085H46.2781L50.3344 13.0827L54.4521 1.75085H56.5908L51.4652 15.5509Z" fill="black"/>
    <path d="M59.4425 15.5509V1.75085H61.5813V15.5509H59.4425Z" fill="black"/>
    </svg>
  )
}

export default LogoDesktop;
