import styled from 'styled-components';
import {
    colors,
    mediaQueriesSizes,
} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  background: ${colors.secondaryAlternate}; 
  padding:  60px 0;
  z-index: 1;
  //position: relative;
  .column{
    width: 100%;
    margin: 80px auto 20px;
    padding: 0;
  }
  
  a {
    color: inherit;
  }

  @media (min-width: ${mediaQueriesSizes.sm}px) {
    position: relative;
  }

    @media (min-width: ${mediaQueriesSizes.lg}px) {      
    .row{
      display:flex;
      justify-content: space-between;
    }
    
    .column{
        width: 45%;
        margin-top: 0;
        margin-left: 0;
        padding: 0;

        &--narrow {
          width: 39.2%;
          margin-left: auto;
          margin-right: 0
        }
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding-top: 115px;
    padding-bottom: 135px;
    
    .column:last-child {
     padding-top: 70px;
    }

    .column {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    .column {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    .column {
      margin-left: 0;
      margin-right: 0;
    }
  }    
`;

export const TitleBlock = styled.div`
  h2{
    font-size: 36px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0em;
    margin-bottom: 20px;
    
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 44px;
      line-height: 1.2;
      margin-bottom: 10px;
    }
    
    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 50px;
      font-weight: 400;
      line-height: 1.2;
    }

  }
  
  p{
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 15px;
    max-width: 460px;
    display: none;
    
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      display: block;
      font-size: 19px;
      line-height: 32px;
      margin-bottom:50px;
    }
    
    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 22px;
      line-height: 36px;
    }

    a{
      color: ${colors.primary};
      text-decoration: underline; 
    }
  }
  
  img{
    width: 100%;
    max-width: 400px;
    margin: -20px auto;
    display: block;

    &.desktop{
      display:none;
    }
    
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin: 0;
      &.desktop{
        display:block;
      }
      &.mobile{
        display:none;
      }
    }
  }

  a.desktop {
    display: none;

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      display: flex;
    }
  }

`;

export const Disclaimer = styled.div`
  background: #F6F9F9;
  padding:22px 26px;
  border-radius: 20px;
  
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  
  margin-bottom: 30px;
  
  
  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    font-size: 14px;
    line-height: 17px;    
    padding:22px 65px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    font-size: 16px;
    line-height: 19px;
  }

`;

export const ListWrap = styled.div`

  h3{
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
   

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 19px;
      line-height: 32px;
      margin-bottom:25px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 22px;
      line-height: 36px;
    }

  }

  ul{
    margin:0 0 35px;
    padding:0;
    
    li{
      list-style:none;
      padding: 55px 20px 55px 75px;
      background-image: url(${props => props.bgimg});
      background-repeat: no-repeat;
      background-position: center left;
      position:relative;
      width: 100%;
      background-size: 40px 40px;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;

      .line {
        background: ${colors.black};
        display: block;
        height: 1px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
      
      @media (min-width: ${mediaQueriesSizes.xl}px) {        
        font-size: 22px;
        font-weight: 300;
        line-height: 22px;
        padding: 60px 0 60px 75px;

        &:nth-of-type(1){
          .line {
            display: none
          }
        }
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 32px;
        line-height: 32px;        
      }
      
      span.info{
        width: 26px;
        height: 27px;
        background-image: url("${props => props.info}");
        background-position: center;
        background-size: contain;
        display: inline-block;
        margin:0px 0 -5px 5px;
        cursor: pointer;
        
        transition: 0.5s;
      
        
        @media (min-width: ${mediaQueriesSizes.xl}px) {
          position:relative;
        }

        
        &.active{
          &:after{
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 15px solid ${colors.secondary};
            margin-left: 3px;
            top: 65px;
            
            @media (min-width: ${mediaQueriesSizes.sm}px) {
              top: 45px;
            }
            @media (min-width: ${mediaQueriesSizes.xl}px) {
              display:none;
            }
          }
        }

        
      }
      
      span.speech-bubble{
      
        width: 100%;
        bottom: calc(100% - 65px);
        left: 0;
        background: ${colors.secondary};
        padding: 25px;
        border-radius: 15px;
        position: absolute;
        display:none;
        background-image: url(${props => props.close});
        background-position: 97% 14%;
        background-repeat: no-repeat;
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        
        &:after{
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 15px solid ${colors.secondary};
          margin-left: 3px;
          top: 100%;
          left: calc(50% - 10px);
          display:none;
        }

        
        @media (min-width: ${mediaQueriesSizes.sm}px) {
          bottom: calc(100% - 45px);
        }

        
        @media (min-width: ${mediaQueriesSizes.xl}px) {
          bottom: calc(100% + 15px);
          width: 420px;
          left: -200px;
          padding: 15px;
          display:none;
          &:after{
            display:block;
          }
        }
        
        
        
        
        &.active{
          display:block
        }
        
        
        
        
      }

      &.line-only {
        background-image: none;
        padding: 0;
      }
      
    }
  }

  .mobile {
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      display: none;
    }    

    margin-bottom: 20px;
  }
  
`
