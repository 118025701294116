import React, {useEffect, useState} from 'react';

import {
  Faq,
  FAQWrapper,
  StyledBlock,
  Container
} from './styles';

const MoreAboutCOVID19 = ({title, questions}) => {
  const [activeFAQ, setActiveFAQ] = useState(false);

  const createMarkUp = (content) => {
    return {__html: content};
  }

  const toggleFAQ = (key, e = false) => {
    if (!e || e.keyCode !== 9) {
      if (e) {
        e.preventDefault();
      }
      if(activeFAQ === key){
        setActiveFAQ(false);
      }else{
        setActiveFAQ(key);
      }
    }
  }

  useEffect(() => {
  }, []);

  return (
    <StyledBlock>
      <div className={'container'}>
      <Container>
          <h2>{title}</h2>

          <FAQWrapper>
          {questions.map((faq, key) => {
            return (
              <div key={key} onClick={() => { toggleFAQ(key) }} onKeyDown={(e) => { toggleFAQ(key, e) }} role="button" tabIndex="0" aria-controls={`faq${key}`} aria-expanded={key === activeFAQ}>
                <Faq id={`faq${key}`} data-cy={`more-about-covid_faq-${key}`} className={key === activeFAQ ? 'active' : ''}>
                  {/* <h3>{faq.title}</h3> */}
                  <h3 dangerouslySetInnerHTML={createMarkUp(faq.title)}></h3>
                  <span className={key === activeFAQ ? 'active info' : 'info'} data-cy="show-additional-info"></span>

                  <p dangerouslySetInnerHTML={createMarkUp(faq.copy)}></p>
                </Faq>
              </div>
            )
          })}

          </FAQWrapper>
        </Container>
      </div>

    </StyledBlock>
  );

}

export default MoreAboutCOVID19;
