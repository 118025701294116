import styled from 'styled-components';
import { Button } from '@lxlabs/faze-screener-components-library';
import TextInput from '../TextInput';

export const InputField = styled(TextInput)`
  margin-top: 30px;
`

export const CopyButton = styled(Button)`
  text-align: left;
  margin-top: 30px;
`