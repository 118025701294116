import React, { Children, cloneElement, useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Item from "./item";
import { Style } from "./styles";

gsap.registerPlugin(ScrollTrigger);

const Accordion = ({ children }) => {
  const [activeFAQ, setActiveFAQ] = useState(false);

  useEffect(() => {
    gsap.from(elementRefs, {
      scrollTrigger: {
        trigger: triggerRef.current,
        start: "center center+=700px",
        ease: "none",
      },
      opacity: 0,
      duration: 0.25,
      ease: "none",
      stagger: 0.25
    });
  }, []);

  const triggerRef = useRef();
  const elementRefs = [];

  const setRef = (ref) => {
    elementRefs.push(ref);
  }

  const toggleFAQ = (key, e = false) => {
    if (!e || e.keyCode !== 9) {
      if (e) {
        e.preventDefault();
      }
      if(activeFAQ === key){
        setActiveFAQ(false);
      }else{
        setActiveFAQ(key);
      }
    }
  }

  return (
    <Style ref={triggerRef}>
      {Children.map(children, (child, index) => cloneElement(child, {
        index,
        toggleFAQ,
        activeFAQ,
        ref: (ref) => setRef(ref)
      }))}
    </Style>
  )
}

Accordion.Item = Item;

export default Accordion;
