import styled from "styled-components";

import Typography from "../Typography";
import { mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const Style = styled.div`

`

export const AccordionItem = styled.div`
  border-bottom: solid 1px black;
  padding: 40px 0px;

  p {
    height: 0;
    overflow: hidden;
    margin: 0px;
  }

  &.active {
    h3 {
      span {
        transform: rotate(45deg);

        @media (min-width: ${mediaQueriesSizes.md}px) {
          transform: rotate(45deg);
        }
      }
    }
    p {
      height: auto;
      margin-top: 30px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 40px 25px;
  }
`

export const ItemTitle = styled(Typography)`
  cursor: pointer;
  display: flex;
  gap: 20px;
  align-items: center;

  span {
    margin-left: auto;
    transition: 0.25s;
    position: relative;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: block;
    border: black solid 1px;
    display: flex;
    flex-shrink: 0;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: calc(50% - 10px);
      width: 20px;
      height: 1px;
      display: block;
      background: black;
      content: "";
      transition: 0.25s;
    }

    &:before {
      transform: rotate(90deg);
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      border: none;
      top: 0;

      width: 30px;
      height: 30px;

      &:before,
      &:after {
        width: 30px;
        left: auto;
      }
    }
  }
`