import styled from 'styled-components';
import {
    colors,
    mediaQueriesSizes,
} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  background: linear-gradient(to bottom, 
      transparent 0%, 
      transparent 50%, 
      ${colors.secondaryAlternate} 50%, 
      ${colors.secondaryAlternate} 100%
  );
  position: relative;
  display: flex;
  align-items: center;
  padding: 115px 0;
  margin-top: -70px;
  z-index: 11;
  
  @media (min-width: ${mediaQueriesSizes.sm}px) {
    margin-top: -40px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    background-color: ${colors.light};
    margin-top: 0;
    height: 540px;
    min-height: 100vh;  
    padding: 150px 0; 
    z-index: 1; 
 }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    min-height: 100vh;    
  }

  a {
    width: 100%;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 320px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    overflow: visible
  }

  span.circle {
    background: ${colors.primaryAlternate};
    content: "";
    display: block;
    width: 680px;
    position: absolute;
    height: 680px;
    border-radius: 50%;
    top: 0;
    left: calc(50% - 340px);
    z-index: 0;

  //  @media (min-width: ${mediaQueriesSizes.sm}px) {
  //    width: 900px;
  //    height: 900px;
  //    left: -400px;
  //  }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 100%;
      height: 100%;
      left: 0;
      border-radius: 0;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      border-radius: 50%;
      margin-top: -75px;
      width: 1200px;
      height: 1200px;
      left: -650px;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 1342px;
      height: 1342px;
      left: -550px;
      margin-top: -90px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 1566px;
      height: 1566px;
      left: -565px;
      margin-top: -105px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      width: 1900px;
      height: 1900px;
      left: -700px;
      margin-top: -85px;
    }

  }

  .text-column {
    position: relative;
    z-index: 1;
    max-width: 620px;

    h2 {
      font-size: 36px;
      line-height: 36px;
      font-weight: 300;
      margin-bottom: 15px;


      @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 36px;
        line-height: 42px;
        width: 330px;

        html[lang=es-US] & {
          font-size: 30px;
          line-height: 1.17;
        }
      }

      @media (min-width: ${mediaQueriesSizes.xl}px)  {
        font-size: 38px;
        line-height: 45px;
        width: 416px;

        html[lang=es-US] & {
          font-size: 30px;
          line-height: 1.17;
        }        
      }

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        font-size: 50px;
        line-height: 60px; 
        width: 555px;

        html[lang=es-US] & {
          font-size: 44px;
          line-height: 1.2;
        }          
      }
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 32px;
      margin-bottom: 30px;

      @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 15px;
        line-height: 22px;
        width: 290px;
      }

      @media (min-width: ${mediaQueriesSizes.xl}px) {
        font-size: 24px;
        line-height: 34px;
        width: 460px;
      }

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        font-size: 32px;
        line-height: 46px;

        html[lang=es-US] & {
          font-size: 30px;
          line-height: 1.4375;
        }        
      }
    }
  }

  //
    // @media (min-width: ${mediaQueriesSizes.xl}px) {
  //   .row {
  //     display: flex;
  //     flex-direction: row-reverse;
  //     justify-content: space-between;
  //     padding: 70px 0;
  //   }
  //
  //   .text-column {
  //     padding-top: 75px;
  //     width: 450px;
  //
  //     h2 {
  //       font-size: 44px;
  //       font-weight: 400;
  //       line-height: 1.2;
  //     }
  //
  //     p {
  //       font-size: 28px;
  //       font-weight: 300;
  //       line-height: 40px;
  //       margin-bottom: 50px;
  //     }
  //   }
  // }
  //
    // @media (min-width: ${mediaQueriesSizes.xxl}px) {
  //   background-image: none;
  //   &:before {
  //     display: block;
  //     pointer-events: none;
  //   }
  //
  //   .text-column {
  //     width: 540px;
  //     width: 45%;
  //     margin-left: auto;
  //     margin-right: auto;
  //     padding-top: 95px;
  //
  //     h2, p {
  //       max-width: 540px;
  //     }
  //   }
  //
  //   .image-container {
  //     width: 39.2%;
  //     margin-left: auto;
  //     margin-right: auto;
  //   }
  // }
  //
    // @media (min-width: ${mediaQueriesSizes.xxxl}px) {
  //   .text-column {
  //     margin-left: 20px;
  //     padding-top: 115px;
  //
  //     h2 {
  //       font-size: 50px;
  //       line-height: 1.2;
  //     }
  //
  //     p {
  //       font-size: 32px;
  //       line-height: 46px;
  //     }
  //   }
  // }
  //

`;

export const CirlcleContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  pointer-events: none;
  display: none;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: block;
  }

  span {
    
    background: ${colors.primary};
    display: block;
    position: absolute;
    right: -13%;
    border-radius: 0 0 65vh 65vh;
    &:nth-last-of-type(3) {
      border: solid 2px ${colors.primary};
      background: transparent;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        height: 33%;
        width: 65vh;
        &:nth-last-of-type(2) {
          top: 33vh;
        }
        &:nth-last-of-type(3) {
          top: 66vh;
        }

    }


  }
`
