import React, { Children, cloneElement, useRef, useEffect } from "react";
import Slider from "react-slick";
import { Style, Slide } from "./styles";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const CardSlider = ({children}) => {
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    gsap.from(elementRefs, {
      scrollTrigger: {
        trigger: triggerRef.current,
        start: "bottom bottom+=200px",
        ease: "none",
      },
      opacity: 0,
      duration: 1,
      ease: "none",
      stagger: 0.25
    });
  }, []);

  const triggerRef = useRef();
  const elementRefs = [];

  const setRef = (ref) => {
    elementRefs.push(ref);
  }

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    initialSlide: 0,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Style  ref={triggerRef}>
      <Slider {...settings} className="card-slider">
        {Children.map(children, (child) => (
          <Slide ref={setRef}>
            {cloneElement(child)}
          </Slide>
        ))}
      </Slider>
    </Style>
  )
}

export default CardSlider;