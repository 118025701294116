import React from "react";
import {MDXProvider} from "@mdx-js/react";
import GlobalStyles from './../assets/styles/GlobalStyles';

import ScreenerControl from "./Screener/ScreenerControl";
import FAQSHeroComponent from '../components/FAQs/Hero';
import ClinicalTrialsSlider from '../components/AboutClinicalTrials/ClinicalTrialsSlider';
import AboutCOVID19Header from '../components/AboutCOVID19/AboutCOVID19Header';
import TypesOfTrial from '../components/AboutClinicalTrials/TypesOfTrial';
import SingleColumnText from '../components/AboutClinicalTrials/SingleColumnText';
import MoreAboutCOVID19 from './AboutCOVID19/MoreAboutCOVID19';
import VideoComponent from './AboutCOVID19/VideoComponent';
import WhyWeNeedCOVID19Treatments from './AboutCOVID19/WhyWeNeedCOVID19Treatments';
import TypicalPhases from '../components/AboutClinicalTrials/TypicalPhases';
import TextBubbleWithImage from '../components/AboutTheTrial/TextBubbleWithImage';
import TextWithBubbleGroups from '../components/TextWithBubbleGroups';
import SafetySlider from '../components/AboutClinicalTrials/SafetySlider';
import TrialClosed from '../components/TrialClosed';
import NotFound from '../components/NotFound';
import CountrySelector from '../components/CountrySelector';
import Loader from '../components/Loader';
import PrivacyPolicy from '../components/PrivacyPolicy';
import Unsubscribe from '../components/Unsubscribe';
import FooterController from './Footer.jsx'
import Header from './Header'
import AboutClinicalTrial from './Homepage/AboutClinicalTrials';
import BulletListWithImage from './Homepage/BulletListWithImage';
import JoinSimpleSteps from './Homepage/JoinSimpleSteps';
import TextComponentTwoColumns from './Homepage/TextComponentTwoColumns';
import BulletList from './BulletList';
import PhaseSlider from "./AboutClinicalTrials/PhaseSlider";
import FullScreenMedia from "./FullScreenMedia";
import ReferenceNote from '../components/AboutTheTrial/ReferenceNote';
import BubbleHero from '../components/BubbleHero';
import Typography from "./Typography";
import SiteShare from "./SiteShare";
import Section from "./Section";
import CardSlider from "./CardSlider";
import Card from "./Card";
import TwoColumns from "./TwoColumns";
import Accordion from "./Accordion";
import { Button } from "@lxlabs/faze-screener-components-library";
import Process from "./Process";
import FooterBubbles from "./FooterBubbles";

const wrapRootElement = ({children}) => {

  return(
    <>
      <GlobalStyles/>
      <MDXProvider components={{
        AboutClinicalTrial: AboutClinicalTrial,
        AboutCOVID19Header : AboutCOVID19Header,
        BulletListWithImage: BulletListWithImage,
        BulletList: BulletList,
        ClinicalTrialsSlider : ClinicalTrialsSlider,
        CountrySelector: CountrySelector,
        FAQSHeroComponent : FAQSHeroComponent,
        FooterController: FooterController,
        FullScreenMedia: FullScreenMedia,
        Header: Header,
        JoinSimpleSteps: JoinSimpleSteps,
        Loader: Loader,
        MoreAboutCOVID19 : MoreAboutCOVID19,
        NotFound : NotFound,
        PhaseSlider : PhaseSlider,
        SafetySlider : SafetySlider,
        ScreenerControl: ScreenerControl,
        SingleColumnText : SingleColumnText,
        TextBubbleWithImage: TextBubbleWithImage,
        TextComponentTwoColumns: TextComponentTwoColumns,
        TextWithBubbleGroups: TextWithBubbleGroups,
        TrialClosed : TrialClosed,
        TypesOfTrial : TypesOfTrial,
        TypicalPhases : TypicalPhases,
        Unsubscribe: Unsubscribe,
        VideoComponent : VideoComponent,
        WhyWeNeedCOVID19Treatments : WhyWeNeedCOVID19Treatments,
        PrivacyPolicy : PrivacyPolicy,
        ReferenceNote : ReferenceNote,
        BubbleHero: BubbleHero,
        Typography: Typography,
        SiteShare: SiteShare,
        Section: Section,
        CardSlider: CardSlider,
        Card: Card,
        TwoColumns: TwoColumns,
        Accordion: Accordion,
        AccordionItem: Accordion.Item,
        Button: Button,
        Process: Process,
        ProcessStep: Process.Step,
        FooterBubbles: FooterBubbles
      }}>
        <div>
          {children}
        </div>
      </MDXProvider>
    </>
)};

export default wrapRootElement;
