import React, {useEffect} from 'react';

import {
  StyledBlock,
  VideoContainer,
  Container
} from './styles';

const VideoComponent = ({copy,subCopy,videoID}) => {

  useEffect(() => {
  }, []);

  const createMarkUp = (content) => {
    return {__html: content};
  }

  return (
    <StyledBlock>

      <div className={'container'}>
        <Container>
          <p dangerouslySetInnerHTML={createMarkUp(copy)}></p>
          <p className={'small'} dangerouslySetInnerHTML={createMarkUp(subCopy)}></p>

          {videoID != "" ? (
              <VideoContainer>
                <iframe width="560" height="315" src={"https://player.vimeo.com/video/816871658?h=3504b2fe1e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=" + videoID}  frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="AZ Imagine a World-RzeBFNkr1Cg-1080p-1655064775073"></iframe>
              </VideoContainer>
          ) : (
              <></>
          )}
        </Container>
      </div>

    </StyledBlock>
  );

}

export default VideoComponent;
