import React, {useEffect, useState} from 'react';
import {Button} from "@lxlabs/faze-screener-components-library";
import { gsap, Sine } from "gsap";

import {
  Card,
  Cards,
  Circle,
  Bubble,
  Wrapper,
  CardWrap,
  BubbleWrap,
  ContentWrap,
  ButtonWrap
} from './styles';

const TypicalPhases = ({title, steps, buttonLink, buttonType, buttonLabel}) => {

  /*
  useEffect(() => {
    elementRefs.forEach(bubble => {
      gsap.set(bubble, {
        x: randomXY(-1),
        y: randomXY(1),
      });

      moveX(bubble, 1);
      moveY(bubble, -1);
    });
  }, []);

  const randomXY = random(1, 10);
  const randomTime = random(3, 4);

  function moveX(target, direction) {
    gsap.to(target, randomTime(), {
      x: randomXY(direction),
      ease: Sine.easeInOut,
      onComplete: moveX,
      onCompleteParams: [target, direction * -1]
    });
  }

  function moveY(target, direction) {
    gsap.to(target, randomTime(), {
      y: randomXY(direction),
      ease: Sine.easeInOut,
      onComplete: moveY,
      onCompleteParams: [target, direction * -1]
    });
  }

  function random(min, max) {
    const delta = max - min;
    return (direction = 1) => (min + delta * Math.random()) * direction;
  }
  */
  const [selected, setSelected] = useState(0);

  const elementRefs = [];

  const setRef = (ref) => {
    elementRefs.push(ref);
  }

  const createMarkUp = (content) => {
    return {__html: content};
  }

  return (
    <Wrapper className={'how-trial-works desktop'}>
      <div className={'container'}>
        <ContentWrap>
          <CardWrap>

            <Cards>
              {steps.map((step, key) => {

                return (
                  <Card key={key}
                        className={
                          key === selected ? 'active' : 'in-active'
                        }
                        onClick={() => {
                          setSelected(key);
                        }}
                  >
                    <div>
                      <img src={step.image} alt=""/>
                      <h2 dangerouslySetInnerHTML={createMarkUp(step.title)}></h2>
                      <p dangerouslySetInnerHTML={createMarkUp(step.copy)}></p>
                    </div>

                  </Card>
                )
              })}

            </Cards>
          </CardWrap>
          <BubbleWrap>


            {steps.map((step, key) => {

              return (
                <Bubble
                  ref={setRef}
                  key={key}
                  className={
                    key === selected ? 'active' : 'in-active'
                  }
                >

                  <img alt="" src={step.image}/>

                </Bubble>
              )
            })}

          </BubbleWrap>

        </ContentWrap>

        <ButtonWrap>
          <Button
            className={'about-btn'}
            size={'fill'}
            href={buttonLink}
            type={buttonType}
            label={buttonLabel}
            cyAttribute={'typical-phases_supernova-trail-button'}
          />
        </ButtonWrap>

      </div>
    </Wrapper>
  );
}

export default TypicalPhases;
