import styled from "styled-components";
import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const Style = styled.div`
  align-items: start;
  background: ${colors.white};
  border-radius: 12px;
  display: flex;  
  justify-content: flex-start;
  padding: 30px;
  width: 296px;
  height: 100%;
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: auto;
    height: 100%;
    border-radius: 0px 0px 30px 0px;
    padding: 30px 40px 50px 30px;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    border-radius: 0px 0px 40px 0px;
    padding: 50px 50px 60px 40px;
  }  

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    border-radius: 0px 0px 50px 0px;
    padding: 60px 60px 70px 50px;
  }  
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;

  /* img {
    height: 140px;    
    width: 140px;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      height: 160px;    
      width: 160px;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      height: 180px;    
      width: 180px;
    } 

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      height: 200px;    
      width: 200px;
    }      
  } */
`;