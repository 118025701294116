import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {
  StyledBlock,
  BackgroundBubble,
  CirclesContainer
} from './styles';

const TextBubbleWithImage = ({title, copy, image, imageAlt}) => {

  useEffect(() => {

  }, []);

  return (
    <StyledBlock>
      <div className={'background'}>
        <BackgroundBubble bubble1 />
        <BackgroundBubble bubble2 />
      </div>    
      <div className={'container'}>
        <div className={'row'}>        
          <div className={'column text-column'}>
            <h1>{title}</h1>
            <p>{copy}</p>
          </div>
          <div className={'column image-container'}>
            <img src={image} alt="" />
          </div>                    
        </div>
      </div>
      <CirclesContainer />
    </StyledBlock>
  );

}

TextBubbleWithImage.propTypes = {
   image: PropTypes.string,
   imageAlt: PropTypes.string,
   title: PropTypes.string,
   copy: PropTypes.string,
};

TextBubbleWithImage.defaultProps = {};

export default TextBubbleWithImage;
