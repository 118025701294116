import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {
  StyledBlock,
  TextBubble
} from './styles';
import axios from "axios";

const Unsubscribe = ({title, copy}) => {

  useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id')
    console.log(id);

    if (id !== null) {
      unsubscribe(id);
    }
  });

    const unsubscribe = async (id) => {

      let apiUrl = process.env.GATSBY_SCREENER_API_URL;
      let apiSuccess = true;

      try {
        const response = await axios.post(apiUrl + '/webhooks/unsubscribe-emails', {
          id
        });
      } catch (err) {
        console.error(err);
        apiSuccess = false;
      } finally {
        if (apiSuccess === true) {
          console.log('API CALL SUCCESSFUL');
        }
      }
    }

  return (
    <StyledBlock>
      <div>
        <div className={'row'}>
          <div className={'column text-column'}>
            <TextBubble>
              <h1>{title}</h1>
              <p>{copy}</p>
            </TextBubble>
            <div className={'circle-left'}>
              <span></span>
            </div>
          </div>
          
        </div>
      </div>
      <div className={'column circles-container'}>
        <div className={'circle-1'}></div>
        <div className={'circle-2'}></div>
        <div className={'circle-3'}></div>
        <div className={'circle-4'}></div>
      </div>
    </StyledBlock>
  );

}

Unsubscribe.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string
};

Unsubscribe.defaultProps = {};

export default Unsubscribe;
