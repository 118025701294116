import React from "react";

import { Style, Inner } from "./styles";

const Card = ({ children }) => {
  return (
    <Style>
      <Inner>
        {children}
      </Inner>
    </Style>
  )
}

export default Card;