import styled from 'styled-components';
import {
    colors,
    mediaQueriesSizes,
} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  padding:  50px 0 40px;
  background-color: ${colors.light};
  
  .column{
    margin-bottom: 20px;
  }
  
  @media (min-width: ${mediaQueriesSizes.xl}px) {

    padding:  210px 0 210px;

    .row{
      display:flex;
      justify-content: space-between;
      gap: 40px;

      .column:nth-child(1){
        width: 45%;
      }
      .column:nth-child(2){
        width: 40%;
      }
    }
    
    
  
  
  }

`;
export const ColumnContent = styled.div`
  
  position: relative;  

  h2{
  
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
  
  }
  
  p{
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 30px;
    position: relative;  
    z-index: 1;
  }

  img{
    margin-bottom: 10px;
    height: 60px;
  }
  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
  
    h2{
      font-size: 44px;
      font-weight: 400;
      line-height: 1.2;
      text-align: left;
    }
    
    p{
      font-size: 19px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    
    img{
      margin-bottom: 10px;
      height: 80px;
    }

  
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
  
  h2{
    font-size: 50px;
  }
  
  p{
    font-size: 22px;
    line-height: 36px;
  }
}

`;
export const Bubble = styled.div`
  width: 31vw;
  height: 31vw;
  position: absolute;
  border-radius: 50%;
  right: -15vw;
  bottom: 7%;  
  max-width: 200px;
  max-height: 200px;
  background: ${colors.primary};
  transform: rotate(5.98deg);
  display: none;

  @media (min-width: ${mediaQueriesSizes.sm}px) {
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    display: none
  }
`;

export const TextComponentInner = styled.div`
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
