import React, {useState, useContext, useEffect, useRef} from 'react';

import {Button} from "@lxlabs/faze-screener-components-library";
import Logo from '../Logo';
import {
    LanuageWrap,
    ButtonStyled,
    HeaderWrap,
    BurgerWrap,
    MobileMenu,
    NavContainer,
    NavWrap,
    LeftNav,
    Burger,
    Nav,
    CountryMenu,
    MenuMask,
    ApplyMobileSticky
} from './styles';
import {PageContext} from './../../Context/pageContext';
import countries from "../../../data/countries";
import {navigate} from "gatsby";
import {setCountry} from "../../utils/location";

const Header = ({ hideNav, logoBlack, logoWhite, hideLanguageSwitcher, lineColour, additionalClass, hideSignup}) => {

    const pageContext = useContext(PageContext);
    let contentCopy;
    try {
        contentCopy = require(`../../../data/navigation/${pageContext.language}.js`);
    } catch (e) {
        contentCopy = require(`../../../data/navigation/en-US.js`);
    }

    const logo = contentCopy.Config.logo;
    const links = contentCopy.HeaderNavigation;
    let languageSwitch = contentCopy.LanguageSwitchButton.languageSwitch;
    const [burgerOpenState, setBurgerOpenState] = useState(false);
    const [countryMenuOpenState, setCountryMenuOpenState] = useState(false);
    const [menuShrink, setMenuShrink] = useState(false);
    const [hideLanguageBar, setHideLanguageBar] = useState(false);
    const [menuBackground, setMenuBackground] = useState(false);
    const prevScrollY = useRef(0);

    if (!hideLanguageSwitcher) {
        hideLanguageSwitcher = languageSwitch.isHidden;
    }

    useEffect(() => {
        updateVh();
        const handleScroll = () => {
            updateVh();

            const currentScrollY = window.scrollY;

            if (!hideLanguageSwitcher && window.scrollY > 20 && window.innerWidth >= 1260) {
                setHideLanguageBar(true);
            } else {
                setHideLanguageBar(false);
            }

            if (prevScrollY.current < currentScrollY && !menuShrink && currentScrollY > 20) {
                setMenuShrink(true);
            }
            if (prevScrollY.current > currentScrollY) {
                setMenuShrink(false);
            }

            if (currentScrollY > 20) {
                setMenuBackground(true)
            } else if (!menuShrink) {
                setMenuBackground(false);
            }

            prevScrollY.current = currentScrollY;
        };
        window.addEventListener("scroll", handleScroll, {passive: true});
        return () => window.removeEventListener("scroll", handleScroll);
    }, [menuShrink]);

    // set the slide out menus to 100vh, taking into account browser address and navigation bars, update on scroll
    const updateVh = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const openFullMenu = () => {
        setMenuShrink(false);
    }

    const toggleBurgerState = () => {
        toggleBodyScrollingLock(!burgerOpenState);
        setBurgerOpenState(!burgerOpenState);
    }

    const toggleCountryMenuState = () => {
        toggleBodyScrollingLock(!countryMenuOpenState);
        setCountryMenuOpenState(!countryMenuOpenState);
    }

    const toggleBodyScrollingLock = (scrollingLock) => {
        if (scrollingLock) {
            document.body.classList.add('scrolling-lock');
            document.documentElement.classList.add('scrolling-lock');
        } else {
            document.body.classList.remove('scrolling-lock');
            document.documentElement.classList.remove('scrolling-lock');
        }
    }

    // save the country code to local storage and redirect to where the user wants to go
    const saveCountry = (event, country) => {
        event.preventDefault();
        const redirectTo = '/' + country;
        setCountry(country);
        toggleCountryMenuState();
        navigate(redirectTo);
    }

    // create links for the country menu
    const countryLinks = countries.map((country, i) =>
        <li key={i}><a type="button" href={`/${country.url}`}
                       className={pageContext.language === country.url ? 'active' : ''}
                       onClick={(event) => saveCountry(event, country.url)}>{country.title}</a></li>
    )

    const homeLink = pageContext.homepagePath;

    let LogoDivider = '';

    if (lineColour) {
        LogoDivider = 'divider-' + lineColour;
    }

    let headerClass = '';
    let scrollHeadClass = '';

    if(additionalClass){
        headerClass += additionalClass+' ';
    }

    if (menuShrink) {
        headerClass = 'shrink-mobile';
        scrollHeadClass = 'scrolled'
    } else if (menuBackground) {
        headerClass = 'menu-solid-background';
        scrollHeadClass = 'scrolled'
    }

    if(hideNav) {
        headerClass += ' center-nav-mobile'
    }

    if(hideLanguageSwitcher) {
        LogoDivider += ' noLangBarPosition'
    }

    if(logoBlack) {
        scrollHeadClass += ' logo-black'
    }

    if(logoWhite) {
        LogoDivider += ' logo-white'
    }

    // if this is not the homepage then force the mobile scrolled state
    if (pageContext.homepagePath.substring(0, window.location.pathname.length) !== window.location.pathname) {
        scrollHeadClass = 'scrolled'
    }

    const burgerStateClass = burgerOpenState === true ? 'active' : '';

    return (
        <>

            <Logo className={`desktop ${LogoDivider}`} logo={logo ? logo : 'combined'} link={homeLink}/>

            <NavContainer className={hideLanguageBar === true ? 'hidden' : ' '}>
                {!hideLanguageSwitcher &&
                <LanuageWrap className={'language-bar'}>
                    <ButtonStyled
                        className={countryMenuOpenState === true ? 'language-btn active' : 'language-btn'}
                        type={'outline'}
                        size={'fill'}
                        id={'country-menu-button'}
                        ariaLabel={'Country and language selector'}
                        ariaControls={'country-menu'}
                        ariaHaspopup={true}
                        cyAttribute={'country-menu-open'}
                        label={languageSwitch.title}
                        href={pageContext.languageSwitchPath}
                    />
                    </LanuageWrap>
                }



                <HeaderWrap className={headerClass}>



                        {!hideNav &&
                            <>
                                <BurgerWrap
                                    className={'burger-wrapper'}>
                                    <Burger
                                        id={'mobile-menu-button'}
                                        aria-label={'Site navigation'}
                                        aria-controls={`mobile-menu`}
                                        aria-haspopup={true}
                                        aria-expanded={burgerOpenState}
                                        className={`${burgerStateClass} ${scrollHeadClass}`}
                                        data-cy="mobile-menu-button"
                                        onClick={() => {
                                            toggleBurgerState();
                                        }}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </Burger>

                                </BurgerWrap>
                                <MobileMenu id="mobile-menu" aria-labelledby="mobile-menu-button"
                                    className={burgerOpenState === true ? 'active' : ''}
                                >
                                    <ul>
                                        {links.links.map((link, key) => {
                                            return (
                                                <li key={key}><a className={'active'} href={link.to}>{link.title}</a></li>
                                            )
                                        })}
                                    </ul>

                                    {!hideLanguageSwitcher &&
                                        <ButtonStyled
                                            className={'language-btn-mobile-menu'}
                                            type={'outline'}
                                            size={'fill'}
                                            id={'country-menu-button'}
                                            ariaLabel={'Country and language selector'}
                                            ariaControls={'country-menu'}
                                            ariaHaspopup={true}
                                            cyAttribute={'country-menu-open'}
                                            label={languageSwitch.title}
                                            href={pageContext.languageSwitchPath}
                                        />
                                    }

                                    {!hideSignup &&
                                    <Button
                                        className={'apply-now-btn-mobile-menu'}
                                        href={links.apply.to}
                                        size={'fill'}
                                        label={links.apply.title}/>
                                    }
                                </MobileMenu>
                            </>
                        }

                        <Logo className={`mobile ${scrollHeadClass}`} logo={logo ? logo : 'combined'} link={homeLink}/>
                        <LeftNav>
                            <NavWrap className={menuShrink === true ? 'nav-wrap shrink' : 'nav-wrap '}>

                                {!hideNav &&
                                    <>
                                        <Nav className={menuShrink === true ? 'shrink' : ''}>
                                            {links.links.map((link, key) => {
                                                return (
                                                    <li key={key}>
                                                        <a
                                                            data-cy={'nav-link-'+key}
                                                            className={window.location.pathname === link.to ? 'active' : ''}
                                                            href={link.to}>{link.title}</a>
                                                    </li>
                                                )
                                            })}
                                            <li className={'full'}><Button
                                                className={'menu-btn'}
                                                cyAttribute={'nav_menu-button'}
                                                onClick={() => openFullMenu()}
                                                size={'fill'}
                                                type={'light'}
                                                label={'MENU'}/></li>
                                        </Nav>
                                        {!hideSignup &&
                                        <Button
                                            cyAttribute={'nav_sign-up-now-button'}
                                            className={'apply-now-btn'}
                                            href={links.apply.to}
                                            size={'fill'}
                                            label={links.apply.title}/>
                                        }
                                    </>
                                }
                            </NavWrap>

                        </LeftNav>

                        <CountryMenu id="country-menu" aria-labelledby="country-menu-button" className={countryMenuOpenState === true ? 'active' : ''}>
                            <ul>
                                {countryLinks}
                            </ul>
                        </CountryMenu>

                        <MenuMask className={countryMenuOpenState === true ? 'active' : ''} onClick={() => {
                            toggleCountryMenuState()
                        }}/>

                </HeaderWrap>
            </NavContainer>
            {!hideNav && !hideSignup &&
                <ApplyMobileSticky>
                    <Button
                        cyAttribute={'mobile-apply-now-btn'}
                        className={'apply-now-btn'}
                        href={links.apply.to}
                        size={'fill'}
                        label={links.apply.title}/>
                </ApplyMobileSticky>
            }
        </>
    )
};

export default Header;
