import styled from 'styled-components';
import { mediaQueriesSizes } from '../../assets/styles/GlobalStyles';

export const Style = styled.div`
  display: grid;
  width: 100%;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    grid-template-columns: auto 1fr;
  } 
`;

export const Main = styled.div`
  margin-left: auto;
  width: 100%;

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    max-width: 750px;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    max-width: 850px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    max-width: 950px;
  }    
`
