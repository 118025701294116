import React from 'react';

import {
  StyledBlock,
  LoadingIcon,
  LoadingSpinner
} from './styles';

const Loader = () => {

  return (
    <StyledBlock>
      <LoadingIcon>
        <LoadingSpinner>
          <div></div>
        </LoadingSpinner>
      </LoadingIcon>
    </StyledBlock>
  );

}

export default Loader;
