import React, {useEffect} from 'react';
import { Button } from "@lxlabs/faze-screener-components-library";

import {
  StyledBlock,
  ButtonWrap,
  Container
} from './styles';

const WhyWeNeedCOVID19Treatments = ({subtitle, title, copy, button}) => {

  useEffect(() => {
  }, []);

  const createMarkUp = (content) => {
    return {__html: content};
  }

  return (
    <StyledBlock>
      <div className={'container'}>
        <Container>
          <h2>{subtitle}</h2>
          <h3 dangerouslySetInnerHTML={createMarkUp(title)}></h3>
          <p dangerouslySetInnerHTML={createMarkUp(copy)}></p>

          <ButtonWrap>
          <Button
            type={'outline'}
            label={button.copy}
            href={button.link}
            cyAttribute={'why-we-need-treatments_find-out-more-button'}
          />
          </ButtonWrap>
        </Container>
      </div>

    </StyledBlock>
  );

}

export default WhyWeNeedCOVID19Treatments;
