import styled from 'styled-components';

import {mediaQueriesSizes,colors} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`

  background: ${colors.secondaryAlternate};
  padding: 60px 0 0px;
  padding-bottom: 50px;

  p{
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom:30px;
    max-width: 900px;
    &.small{
      font-size: 16px;
      font-weight: 300;
      line-height: 32px;
    }
  }
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 140px 0 0px;
    padding-bottom: 100px;

    p,
    p.small{
      font-size: 28px;
      font-weight: 300;
      line-height: 40px;     
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {

    p,
    p.small{
      font-size: 32px;
      line-height: 46px;
      max-width: 1065px;
    }
  }


`;

export const VideoContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin-top: 35px;
  }

  &:before {
    display: block;
    content: "";
    padding-top: 56.25%;
  }

  iframe{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
