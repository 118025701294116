import React from 'react';
import { BubbleInner, LeftBubble, RightBubble, Style } from './styled';

const FooterBubbles = ({ leftBubble, rightBubble }) => {
  return (
    <Style>
      {leftBubble && <LeftBubble>
        <BubbleInner className="container">
          {leftBubble}
        </BubbleInner>
      </LeftBubble>}
      {rightBubble && <RightBubble>
        <BubbleInner  className="container">
          {rightBubble}
        </BubbleInner>
      </RightBubble>}
    </Style>
  )
}

export default FooterBubbles;
