import React from 'react';
import { Theme } from "@lxlabs/faze-screener-components-library";
import Wrapper from './src/components/Wrapper';
import './src/styles/global.css'

export const wrapRootElement = ({ element, pageContext }) => {

    return <Theme theme="COVID-19">
      <Wrapper>{element}</Wrapper>
    </Theme>;
};


export const shouldUpdateScroll = ({
    routerProps: { location },
  }) => {
    if (!location.hash) {
      window.scrollTo(0, 0)
      return false
    }
}

export const onRouteUpdate = ({ location }) => {
  // Check if the global dataLayer object exists
  if (window.dataLayer) {
    // Push the event to the dataLayer
    window.dataLayer.push({
      event: 'gatsby-route-change',
      location: location,
    });
  }
};
