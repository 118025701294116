import {ScreenerEntrance} from "@lxlabs/faze-screener-components-library";
import {navigate} from "@reach/router";
import React, {useEffect} from 'react';
import FooterController from "../Footer";
import {resetUserStatus} from "./UserProgress";

const ScreenerEntranceComponent = ({contentCopy}) => {

  useEffect(() => {
    resetUserStatus();
  }, [])

  return (
    <>
      <ScreenerEntrance
        copyData={contentCopy.entrance.copyData}
        buttons={[{
          label: contentCopy.buttonLabels.entrance,
          onClick: () => {
            navigate(`/${contentCopy.config.lang}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.question}/1`)
          } 
        }]}
        logo={{
          style: contentCopy.logo,
          link: contentCopy.logoLink
        }}
      />
      <FooterController addLowerMargin={false} button={false}/>
    </>
  )
};

export default ScreenerEntranceComponent;