import styled from 'styled-components';

import {mediaQueriesSizes,colors} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  padding: 40px 0 0;
  position: relative;
  background: ${colors.primary};
  h2{
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom:30px;
  }
    
  h3{
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom:30px;
    sup{
      font-size:10px;
    }
  }
  
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
  }
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 150px 0;

    h2{
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.1em;
      text-align: left;
      text-transform: uppercase;
    }
    
    h3{
      font-size: 44px;
      font-weight: 400;
      line-height: 53px;
      letter-spacing: 0em;
      text-align: left;
      max-width:1000px;
      margin-bottom: 80px;

      sup{
        font-size:0.5em;
      }
    }
    
    p{
      max-width:1000px;
      font-size: 28px;
      font-weight: 300;
      line-height: 40px;
      text-align: left;
      margin-left: auto;
    }
    
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    h2{
      font-size: 16px;
      line-height: 19px;
    }
    
    h3{
      font-size: 50px;
      line-height: 60px;
      margin-bottom: 95px;
    }
    
    p{
      font-size: 32px;
      line-height: 46px;
    }
    
  }
`;

export const ButtonWrap = styled.div`
  text-align:center;
  padding: 50px 0;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    
      max-width:1000px;
      margin-left: auto;
      text-align: left;

  }

  
  
`;


export const Bubble = styled.div`
  display: none;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(180deg, #DAF5FE 0%, rgba(233, 239, 241, 0) 100%);
  transform: rotate(77.86deg);

  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display: block;    
  }

  ${props =>
    props.bubble1 &&
    `
    right: 10vw;
    top: 30px;
    width: 10.8vw;
    height: 10.8vw;
`}

${props =>
    props.bubble2 &&
    `
    background: linear-gradient(180deg, ${colors.secondaryAlternate} 0%, rgba(233, 239, 241, 0) 100%);
    transform: rotate(-67.16deg);    
    left: -5vw;
    top: 600px;
    width: 18vw;
    height: 18vw;
`}

${props =>
    props.bubble3 &&
    `
    transform: rotate(-17.75deg);
    right: 8.8vw;
    bottom: 0;
    width: 8.2vw;
    height: 8.2vw;
`}
  
  
`;

export const Container = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
