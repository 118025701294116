import styled from "styled-components";
import {createGlobalStyle} from "styled-components";
import {colors, mediaQueriesSizes, containerWidths} from "../../assets/styles/GlobalStyles.js";

export const BodyStyle = createGlobalStyle`
  body {
    background: ${colors.secondaryAlternate};
  }

  html {
    scroll-padding-top: 150px;
  }

  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    body {
      overflow: visible;

    }

    html,
    body {
      scroll-behavior: smooth;
      scroll-padding-top: 172px;
    }
  }
`;

export const StyledBlock = styled.div`
  padding: 88px 0;
  background: ${colors.secondaryAlternate};

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding-top: 160px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    padding-top: 272px;
  }

  .row {

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      display: flex;
      width: 100%;
    }
  }

  .links-column {

    position: fixed;
    z-index: 8;
    top: 135px;
    width: calc(100% - 40px);

    @media (min-width: ${mediaQueriesSizes.sm}px) {
      width:${containerWidths.sm}px;
    }
  
  
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width:${containerWidths.md}px;
    }
  
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      width:${containerWidths.lg}px;
    }
   

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 40%;
      position: static;
      padding-top: 120px;
    }

 

    ol {
      background: ${colors.white};
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      counter-reset: section;
      font-size: 14px;
      list-style: none;
      line-height: 1.2;
      margin: 0;
      padding: 0 24px 10px;
      display: none;
      height: 300px;
      overflow-y: scroll;

      &.active {
        display: block;
      }

      &::-webkit-scrollbar {
        background: transparent;
        width: 12px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
        border-radius: 0;
        border: 0;
      }

      @media (min-width: ${mediaQueriesSizes.xl}px) {
        border-radius: 20px;
        display: block;
        height: auto;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        padding: 32px;
        position: sticky;
        top: 100px;
        z-index: 150;
      }

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        font-size: 19px;
        padding: 48px 64px 32px;
      }

      li {
        list-style-position: inside;
        padding: 16px 0;
        position: relative;

        &::before {
          border-top: 1px solid #C4C4C4;
          content: '';
          display: block;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          @media (min-width: ${mediaQueriesSizes.xl}px) {
            display: none;
          }
        }
      }

      a {
        border-bottom: 1px solid transparent;
        color: ${colors.black};
        padding: 2px 0;
        position: relative;
        text-decoration: none;
        transition: border-color 0.1s ease;

        &::before {
          counter-increment: section;
          content: counter(section) '.';
          display: inline-block;
          margin-right: 4px;
        }

        &.is-active,
        &:focus,
        &:hover {
          border-bottom-color: ${colors.black};
          text-decoration: none;
        }
      }
    }
  }

  .text-column {
    font-size: 16px;
    /* font-weight: 400; */
    line-height: 1.6;
    padding: 20px 0;
    position: relative;
    z-index: 1;
    width: 100%;
    word-break: break-word;

    .table-container {
      display: block;
      overflow-x: scroll;
    }

    table {
      border-collapse: collapse;
      width: 800px;
      
      @media (min-width: ${mediaQueriesSizes.md}px) {
        width: 100%;
      }

      thead {
        th {
          &:first-of-type {
            border-top-left-radius: 35px;
          }

          &:last-of-type {
            border-top-right-radius: 35px;
          }

          border-top: none;
          background: ${colors.primaryAlternate};
          text-align: center;
          font-weight: bold;
          padding: 30px 0px;
        }
      }

      tbody {
        tr:last-of-type {
          td {
            &:first-of-type {
              border-bottom-left-radius: 35px;
            }

            &:last-of-type {
              border-bottom-right-radius: 35px;
            }
          }
        }
      }

      td {
        border: 1px solid black;
        vertical-align: top;

        width: 33.3333%;

        ul {
          padding-left: 18px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      tr:first-child th {
        border-top: 0;
      }

      tr:last-child td {
        border-bottom: 0;
      }

      tr td:first-child,
      tr th:first-child {
        border-left: 0;
      }

      tr td:last-child,
      tr th:last-child {
        border-right: 0;
      }

      td {
        padding:15px 10px;
        @media (min-width: ${mediaQueriesSizes.xl}px) {
          padding: 25px 30px;
        }        
        /* width: 50%; */
        background: ${colors.primary};
      }
    }

    a {
      a {
        pointer-events: none;
      }
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      margin-left: auto;
      padding-top: 0;
      width: 50%;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 20px;
    }

    h1,
    h2,
    h3 {
      margin-bottom: 18px;
      margin-top: 32px;
    }

    h1 {
      font-size: 38px;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 18px;
      margin-top: 40px;

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        font-size: 50px;
        line-height: 1.2;
        margin-top: 0px;
      }
    }

    h2 {
      font-size: 20px;
      line-height: 1.2;

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        font-size: 32px;
        margin-top: 80px;
      }
    }

    h3 {
      font-size: 18px;
      line-height: 1.2;

      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        font-size: 22px;
      }
    }

    li {
      line-height: 1.6;
      margin-bottom: 6px;
    }

    p {
      margin: 0 0 30px;
    }

    strong {
      font-weight: 500;
    }

    a {
      color: inherit;

      &:focus,
      &:hover {
        text-decoration: none;
      }

      // margin-top: 70px;
      // width: 100%;

        // @media (min-width: ${mediaQueriesSizes.md}px) {
      //   margin-top: 80px;
      //   width: 320px;
      // }
    }
  }
`;

export const MobileLinksTitle = styled.div`
  background: ${colors.white};
  border-radius: 10px;
  padding: 20px 24px;
  position: fixed;
  top: 80px;
  font-size: 14px;
  cursor: pointer;
  width: calc(100% - 40px);
  z-index: 8;

  @media (min-width: ${mediaQueriesSizes.sm}px) {
    width:${containerWidths.sm}px;
  }


  @media (min-width: ${mediaQueriesSizes.md}px) {
    width:${containerWidths.md}px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    width:${containerWidths.lg}px;
  }



  span {
    transition: 0.25s;
    position: absolute;
    top: 50%;
    right: 20px;
    width: 15px;
    height: 15px;
    display: block;
    display: flex;
    cursor: pointer;
    transform: translateY(-50%);
    transform-origin: center;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        transform: scale(1.5) translateY(-50%);
      }
    }

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 0;
      width: 15px;
      height: 1px;
      display: block;
      background: black;
      content: "";
      transition: 0.25s;
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    span {
      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    display: none;
  }
`
