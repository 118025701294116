import {createGlobalStyle} from 'styled-components';
import './fonts.css';

export const colors = {
    primary: '#B8E4E3',
    primaryAlternate: '#B1D9D8',
    secondary: '#FF5500',
    secondaryAlternate: '#F1F9FC',
    light: '#F1F9FC',
    navy: '#140D4F',
    purple: '#230342',


    //Not Changed May Still be in use
    dark: 'rgb(57,57,57)',
    white: 'white',
    black: 'black',
    grey: '#808080',
}

export const mediaQueriesSizes = {
    desktopNav:   1260,
    xxxl:         1900,
    xxl:         1600,
    xl:         1169,
    lg:         900,
    md:         700,
    sm:         500,
};
export const containerWidths = {
    xxl:        1560,
    xl:         1139,
    lg:         870,
    md:         670,
    sm:         470,
};


const GlobalStyles = createGlobalStyle`

    html {
        overflow-x: hidden;
        -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

        
        &.scrolling-lock {
            overflow: hidden;
        }        
    }

    .divider-secondary{
        hr{
            background: ${colors.secondary}; 
        }
    }

    #CookiebotWidget {
        box-shadow: none !important;
    }

    .CookiebotWidget-logo {
        background: url('../images/Cookie-icon.svg') center no-repeat !important;
        background-size: 40px 40px !important;
        background-color: white !important;
        border-radius: 40px;
        svg {
            opacity: 0 !important;
            width: 44px !important;
            height: 44px !important;
        }
    }

    body {
        font-family: 'PolySans';
        font-size: 16px;
        line-height: 1.2;
        background-color: ${colors.white};
        margin:0;
        -webkit-font-smoothing: antialiased; /* Chrome, Safari */
        -moz-osx-font-smoothing: grayscale; /* Firefox */
        font-weight:300;
        position:relative;
        overflow-x: hidden;

        &.scrolling-lock {
            overflow: hidden;
        }
    }
    
    h1,h2,h3,h4,h5,h6,p{
        margin:0;
        font-weight:300;
    }
    
    h1{
        font-size:36px;
        line-height:1;
    }
    
    h2{
        font-size: 36px;
        font-weight: 300;
        line-height: 1;
        letter-spacing: 0em;
    }
    
    h4{
        font-size: 16px;
        font-weight: 300;
        line-height: 1.1875;
    }
    
    h5{
        font-size:16px;
        line-height: 1.188;
    }

    a {
        color: inherit;
    }

    sup{
        line-height:0;
    }

    .reference{
    
    }

    select {
        font-family: 'PolySans';
    }

    hr {
        border: none;
        border-bottom: 1px solid black;
        margin: 50px 0px;
    }
  
    * {
        box-sizing: border-box;
    }
    
    #CookiebotWidget {
        @media (max-width: ${mediaQueriesSizes.sm}px) {
            bottom: 90px !important;
       }
    }
    
    .container{
        width:100%;
        padding:0 20px;
        margin:0 auto;
        
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            padding:0 15px;
            width:${containerWidths.sm}px;
        }
        @media (min-width: ${mediaQueriesSizes.md}px) {
            width:${containerWidths.md}px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            width:${containerWidths.lg}px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            width:${containerWidths.xl}px;
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            width:${containerWidths.xxl}px;
        }
        
        
        @media (min-width: ${mediaQueriesSizes.sm}px) {
            width:${containerWidths.sm}px;
        }
        @media (min-width: ${mediaQueriesSizes.md}px) {
            width:${containerWidths.md}px;
        }
        @media (min-width: ${mediaQueriesSizes.lg}px) {
            width:${containerWidths.lg}px;
        }
        @media (min-width: ${mediaQueriesSizes.xl}px) {
            width:${containerWidths.xl}px;
        }
        @media (min-width: ${mediaQueriesSizes.xxl}px) {
            width:${containerWidths.xxl}px;
        }
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }

    .skip-link {
        height: 1px;
        left:-999px;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: 1px;
        z-index:-999;

        &:focus,
        &:active {
            background-color: ${colors.dark};
            color: ${colors.white};
            left: 0;
            width: auto;
            height: auto;
            overflow: auto;
            margin: 10px;
            padding: 5px;
            text-align:center;
            z-index:999;
        }
    }


    .width-70 {
        width: 100%;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            width: 70%;
        }
    }

    .float-right {
        float: right;
    }
        
    .mt-50 {
        margin-top: 50px;
    }

    .no-background-image--mobile {
        @media (max-width: ${mediaQueriesSizes.xl}px) {
            background-image: none !important;
        }
    }

    select.location {
        padding-right: 50px !important;
    }    
`;

export default GlobalStyles;
