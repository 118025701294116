import styled from "styled-components";
import { mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const Title = styled.h1`
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  margin: 30px 0;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    font-size: 16px;
    line-height: 18px;
  }  
`

export const Heading1 = styled.p`
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;
  margin: 30px 0;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    font-size: 40px;
    line-height: 50px;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    font-size: 50px;
    line-height: 60px;
  }
`

export const Heading2 = styled.p`
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    font-size: 26px;
    line-height: 26px;    
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    font-size: 32px;
    line-height: 32px;    
  }
`

export const Heading3 = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    font-size: 26px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0em;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    font-size: 32px;
    line-height: 46px;    
  }
`;

export const Heading4 = styled.p`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    font-size: 22px;
    line-height: 36px;
  }
`;

export const Body = styled.p`
  font-size: 16px;
  line-height: 18px;
  margin: 20px 0 0;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    line-height: 26px;
  }
`