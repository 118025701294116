import styled from "styled-components";
import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const StyledBlock = styled.div`
  background-color: ${colors.secondaryAlternate};
  height: 1350px;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin-bottom: -12vw;
    height: auto;
    position: relative;
    top: 0;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    top: -5vw;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`

export const Wrapper = styled.div`
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;

  @media (max-width: ${mediaQueriesSizes.lg}px) {
    width: 100% !important;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    flex-wrap: wrap;
  }
`

const Bubble = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  aspect-ratio: 1 / 1;

  img { 
    width: 50%;
    margin: 0 auto;
    display: block;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      display: none;
    }
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {

  }
`

export const BubbleInner = styled.div`
  margin: 0px;
`

export const LeftBubble = styled(Bubble)`
  background-color: ${colors.primaryAlternate};
  z-index: 29;
  height: 686px;
  width: 686px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;

  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    width: 75vw;
    height: 75vw;
    position: relative;
    transform: none;
    left: -10vw;
    top: -20vw;
    padding-left: 20vw;
    padding-right: 15vw;
    padding-top: 20vw;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding-top: 20vw;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    padding-top: 20vw;
  }

  h1 {
    font-size: 14px;
    line-height: 1.14;
    margin: 3.57em 0 0;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin: 0;
      font-size: 16px;
      line-height: 1.1875;
    }
  }

  p {
    font-size: 20px;
    line-height: 1.2;
    margin: 1em 0 0;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      margin: 0.64em 0 0;
      font-size: clamp(30px, 2.6vw, 50px);
      line-height: 1.2;
    }    
  }

  img {
    width: 250px;
  }

  .container {
    max-width: 400px;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      max-width: 100%;
    }
  }
`;

export const RightBubble = styled(Bubble)`
  background-color: ${colors.primary};
  z-index: 25;
  width: 848px;
  height: 848px;
  padding: 25px 0px;
  margin-left: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 500px;
  padding-top: 200px;


  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 0 8vw;
    align-self: flex-end;
    margin-top: 0;
    left: auto;
    right: 1vw;
    bottom: 200px;
    top: auto;
    transform: none;
    z-index: 30;
    width: 44.16vw;
    height: 44.16vw;    
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 0 10vw;
  }

  .heading2 {
    font-size: 36px;
    font-weight: 300;
    line-height: 1;
    margin: 0;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: clamp(22px, 1.875vw, 36px);
    }
  }

  .paragraph {
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    margin: 2em 0 0;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: clamp(16px, 1.146vw, 22px);
      line-height: 1.63;
      margin: 1em 0 0;
    }    
  }

  .container {
    max-width: 400px;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      max-width: 100%;
    }
  }  
`;