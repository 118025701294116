import React from 'react';

import {
  Title,
  StyledBlockWrapper,
  StyledBlock,
  TrialsWrapper,
  TrialsContainer
} from './styles';

const TypesOfTrial = ({ title, children}) => {
  return (
    <StyledBlockWrapper>
      <StyledBlock>
        <div className={'container'}>
          <TrialsContainer>
            <Title type="heading1">{title}</Title>

            <TrialsWrapper>
              {children}
            </TrialsWrapper>
          </TrialsContainer>
        </div>
      </StyledBlock>
    </StyledBlockWrapper>
  );
}

export default TypesOfTrial;
