import React, {useEffect, useState} from 'react';

import {colors} from "../../../assets/styles/GlobalStyles";

import {
  StyledBlock,
  TextContainer
} from './styles';

const SingleColumnText = ({title,copy, bgColour}) => {

  const createMarkUp = (content) => {
    return {__html: content};
  }

  const [backgroundColour,setBackgroundColour] = useState('');

  useEffect(()=>{
    if(bgColour == null){
      setBackgroundColour(colors.secondaryAlternate);
    }else{
      setBackgroundColour(bgColour);
    }
  },[])

  return (
    <StyledBlock background={backgroundColour}>
      <div className={'container'}>
        <TextContainer>
          <h2>{title}</h2>
          <p dangerouslySetInnerHTML={createMarkUp(copy)}></p>
        </TextContainer>
      </div>

    </StyledBlock>
  );

}

export default SingleColumnText;
