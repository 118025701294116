import React from 'react';
import { Title, Heading1, Heading2, Heading3, Heading4, Body } from './styles';

const Typography = ({ type, as, children, ...allProps }) => {
  const types = {
    title: (props) => <Title {...props}>{ children }</Title>,
    heading1: (props) => <Heading1 {...props}>{ children }</Heading1>,
    heading2: (props) => <Heading2 {...props}>{children}</Heading2>,
    heading3: (props) => <Heading3 {...props}>{children}</Heading3>,
    heading4: (props) => <Heading4 {...props}>{children}</Heading4>,
    body: (props) => <Body {...props}>{children}</Body>
  }
  
  let Response = types[type];

  if (!Response) {
    Response = types['body'];
  }

  return <Response {...allProps} as={as} />
}

export default Typography;
