import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import { Button } from "@lxlabs/faze-screener-components-library";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {
    Step,
    StepDot,
    Wrapper,
    DotWrapper,
    StepsWrapper,
    ButtonWrapper,
    JoinSimpleStepsInner
} from './styles';

gsap.registerPlugin(ScrollTrigger);

const JoinSimpleSteps = ({title, steps, buttonLink, buttonLabel, buttonType}) => {

    const [selected, setSelected] = useState(0);

    useEffect(() => {
        gsap.from(elementRefs, {
            scrollTrigger: {
                trigger: elementRefs[0],
                start: "top 80%"
            },
            opacity: 0,
            duration: 0.5,
            ease: "none",
            stagger: 0.25
        });
    }, []);

    const elementRefs = [];

    const setRef = (ref) => {
        elementRefs.push(ref);
    }

    return (
        <div>
            <Wrapper className={'join-simple-steps'}>
                <div className={'container'}>
                    <JoinSimpleStepsInner>
                        <h2>{title}</h2>

                        <DotWrapper>
                            {steps.map((step, key) => {

                                let stepNumber = key + 1;

                                return (
                                    <StepDot
                                        key={key}
                                        onClick={() => {
                                            setSelected(key);
                                        }}
                                        className={key === selected ? 'active' : ''}
                                    >{stepNumber}</StepDot>
                                )
                            })}
                        </DotWrapper>

                        <StepsWrapper>

                            {steps.map((step, key) => {

                                let stepNumber = key + 1;
                                stepNumber = '0' + stepNumber;

                                return (
                                    <Step ref={setRef} key={key}
                                          className={key === selected ? 'active' : 'in-active'}
                                    >
                                        <div className={'imageWrap'}>
                                            <img src={step.image} alt="" loading="lazy"/>
                                        </div>
                                        <h3><span>{stepNumber}</span> {step.title}</h3>
                                        <p dangerouslySetInnerHTML={{ __html: step.copy }}></p>

                                    </Step>
                                )
                            })}

                        </StepsWrapper>
                        <ButtonWrapper>
                            <Button
                                cyAttribute={'join-simple-steps_about-button'}
                                href={buttonLink}
                                type={buttonType}
                                label={buttonLabel}
                            />
                        </ButtonWrapper>
                    </JoinSimpleStepsInner>
                </div>
            </Wrapper>
        </div>
    );
}

export default JoinSimpleSteps;
