import React, {useContext, useEffect, useState} from "react";

import {
  useParams,
} from '@reach/router';

import { navigate } from "gatsby"

import {
  PointsCalculatorQuestion,
  SingleAnswerQuestion,
  SingleAnswerWithTextInput,
  MultiAnswerQuestion,
  TextInputAnswer,
  MultiCheckboxAnswer,
  DropDownSelectAnswer
} from "@lxlabs/faze-screener-components-library";


import {PageContext} from "../../Context/pageContext";
import FooterController from "../Footer";
import {getUserQuestion} from "./UserProgress";

const QuestionController = ({contentCopy, logoLink}) => {

  const pageContext = useContext(PageContext);
  let questions;

  try {
    questions = require(`../../../data/screener-questions/${pageContext.language}.js`).default;
  } catch (e) {
    questions = require(`../../../data/screener-questions/en-US.js`).default;
  }

  const params = useParams();
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [lastAction, setLastAction] = useState('');

  useEffect(() => {
    let questionIndex = questions.findIndex( ({url}) => url === params.questionID);
    if (questionIndex === -1) {
      questionIndex = 0;
    }
    setCurrentQuestionNumber(questionIndex);
    //setCurrentQuestionNumber(parseInt(params.questionID) - 1);
    setTotalQuestions(questions.length);
    setCurrentQuestion(questions[currentQuestionNumber]);

  }, [params.questionID,currentQuestion,setCurrentQuestionNumber,setTotalQuestions]);

  useEffect(() => {
    checkUserProgress();

    setCurrentQuestion(questions[currentQuestionNumber]);

  }, [questions, currentQuestionNumber, setCurrentQuestion]);

  const checkUserProgress = () => {
    let userProgress = getUserQuestion();

    let url_string = window.location.href; //window.location.href
    let url = new URL(url_string);
    let override = url.searchParams.get("forcevisible");

    if (currentQuestionNumber + 1 > userProgress && override != 'true') {

      if(lastAction !== 'skip'){
        let newUrl = questions[parseInt(userProgress) - 1].url;
        navigate(`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.question}/${newUrl}`);
      }
    }
  }

  const HandleAnswerSubmission = (action,inputs = []) => {
    setLastAction(action);

    let newQuestionNumber = currentQuestionNumber + 2;

    if (newQuestionNumber === (totalQuestions+1)) {
      // Once all of the questions have been answered, see if the user is eligible or not.
      const answers = JSON.parse(localStorage.getItem('submittedAnswers'));
      const results = Object.values(answers).map((answer) => answer.value);

      // If the user has been rejected on any of the questions, show the rejected form.
      if (results.includes('reject')) {
        navigate(`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.noteligible}/${contentCopy.config.urls.yourdetails}`);
      } else {
        // Otherwise, show the location form
        navigate(`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.eligible}`);
      }
    } else {
      let newUrl = questions[currentQuestionNumber + 1].url;
      navigate(`/${pageContext.language}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.question}/${newUrl}`);
    }
  }



  if (currentQuestion && currentQuestion.questionType === 'YesOrNo') {

    return (
      <>
        <SingleAnswerQuestion
          currentQuestionNumber={currentQuestionNumber + 1}
          totalQuestions={totalQuestions}
          question={currentQuestion.question}
          answer={currentQuestion.answer}
          stepValue={currentQuestion.url}
          button={{
            labelNext: contentCopy.buttonLabels.next,
            handle: HandleAnswerSubmission
          }}
          logo={{
            style: contentCopy.logo,
            link: contentCopy.logoLink
          }}
        />

        <FooterController addLowerMargin={true} button={false}/>
      </>
    )

  }
  if (currentQuestion && currentQuestion.questionType === 'MultipleChoice') {

    return (
      <>
        <MultiAnswerQuestion
          currentQuestionNumber={currentQuestionNumber + 1}
          totalQuestions={totalQuestions}
          question={currentQuestion.question}
          answer={currentQuestion.answer}
          answerArrangement={currentQuestion.answerArrangement}
          stepValue={currentQuestion.url}
          button={{
            labelNext: contentCopy.buttonLabels.next,
            labelSelect: contentCopy.buttonLabels.select,
            handle: HandleAnswerSubmission
          }}
          logo={{
            style: contentCopy.logo,
            link: contentCopy.logoLink
          }}
        />
        <FooterController addLowerMargin={true} button={false}/>
      </>
    )

  }

  if (currentQuestion && currentQuestion.questionType === 'PointsCalculator') {
    return (
      <>
        <PointsCalculatorQuestion
          question={currentQuestion.question}
          answer={currentQuestion.answer}
          totalQuestions={totalQuestions}
          currentQuestionNumber={currentQuestionNumber + 1}
          stepValue={currentQuestion.url}
          button={{
            labelNext: contentCopy.buttonLabels.next,
            handle: HandleAnswerSubmission
          }}
          logo={{
            style: contentCopy.logo,
            link: contentCopy.logoLink
          }}
        />
        <FooterController addLowerMargin={true} button={false}/>
      </>
    )
  }

  if (currentQuestion && currentQuestion.questionType === 'YesNoWithTextInput') {
    return (
      <>
        <SingleAnswerWithTextInput
          totalQuestions={totalQuestions}
          currentQuestionNumber={currentQuestionNumber + 1}
          question={currentQuestion.question}
          answer={currentQuestion.answer}
          textInput={currentQuestion.textInput}
          stepValue={currentQuestion.url}
          button={{
            labelNext: contentCopy.buttonLabels.next,
            handle: HandleAnswerSubmission
          }}
          logo={{
            style: contentCopy.logo,
            link: contentCopy.logoLink
          }}
        />
        <FooterController addLowerMargin={true} button={false}/>
      </>
    )
  }

  if (currentQuestion && currentQuestion.questionType === 'TextInput') {
    return (
      <>
        <TextInputAnswer
          totalQuestions={totalQuestions}
          currentQuestionNumber={currentQuestionNumber + 1}
          question={currentQuestion.question}
          answer={currentQuestion.answer}
          stepValue={currentQuestion.url}
          button={{
            labelNext: contentCopy.buttonLabels.next,
            handle: HandleAnswerSubmission
          }}
          logo={{
            style: contentCopy.logo,
            link: contentCopy.logoLink
          }}
        />
        <FooterController addLowerMargin={true} button={false}/>
      </>
    )
  }

  if (currentQuestion && currentQuestion.questionType === 'MultiCheckbox') {
    return (
      <>
        <MultiCheckboxAnswer
          totalQuestions={totalQuestions}
          currentQuestionNumber={currentQuestionNumber + 1}
          question={currentQuestion.question}
          answer={currentQuestion.answer}
          stepValue={currentQuestion.url}
          button={{
            labelNext: contentCopy.buttonLabels.next,
            handle: HandleAnswerSubmission,
          }}
          logo={{
            style: contentCopy.logo,
            link: contentCopy.logoLink
          }}
        />
        <FooterController addLowerMargin={true} button={false}/>
      </>
    )
  }

  if (currentQuestion && currentQuestion.questionType === 'DropDownSelect') {
    return (
      <>
        <DropDownSelectAnswer
          totalQuestions={totalQuestions}
          currentQuestionNumber={currentQuestionNumber + 1}
          question={currentQuestion.question}
          subQuestions={currentQuestion.subQuestions}
          stepValue={currentQuestion.url}
          button={{
            labelNext: contentCopy.buttonLabels.next,
            handle: HandleAnswerSubmission
          }}
          logo={{
            style: contentCopy.logo,
            link: contentCopy.logoLink
          }}
        />
        <FooterController addLowerMargin={true} button={false}/>
      </>
    )
  }

  return (
    <></>
  )

}


export default QuestionController;
