import {Location} from "@lxlabs/faze-screener-components-library";
import React, {useEffect} from "react";
import {navigate} from "@reach/router";
import FooterController from "../Footer";

const CandidateEligible = (contentCopy) => {

  contentCopy = contentCopy.contentCopy;

  return (
    <>
      <Location
        copyData={contentCopy.successPage.copyData}
        buttonLabel={{
          find: contentCopy.buttonLabels.findSite,
          search: contentCopy.buttonLabels.searchAgain,
          select: contentCopy.buttonLabels.select,
          next: contentCopy.buttonLabels.next
        }}
        error={{
          zipcode: contentCopy.errorCopy.zipcode,
          searchRadius: contentCopy.errorCopy.searchRadius
        }}
        distances={contentCopy.distances}
        langCode= {contentCopy.config.lang}
        studyCode= {contentCopy.config.studyCode}
        unit= {contentCopy.config.unit}
        apiUrl={process.env.GATSBY_SCREENER_API_URL}
        handle={(siteID) => {
          localStorage.setItem('selectedSite',JSON.stringify(siteID));
          navigate(`/${contentCopy.config.lang}/${contentCopy.config.urls.questionnaire}/${contentCopy.config.urls.eligible}/${contentCopy.config.urls.yourdetails}`);
        }}
        logo={{
          style: contentCopy.logo,
          link: contentCopy.logoLink
        }}
      />
      <FooterController addLowerMargin={true} button={false}/>
    </>
  )

}

export default CandidateEligible;