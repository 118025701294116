import styled from 'styled-components';

import {mediaQueriesSizes,colors} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  width:100%;
  overflow:hidden;
  position:relative;
  background-color: ${colors.secondaryAlternate};
  &:after{
    position: absolute;
    top: 165px;
    left: 50%;
    width: 250%;
    height: 100%;
    background: ${colors.primaryAlternate};
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    content: "";
    z-index: 0;
    -webkit-transform: translate(-50%,0px);
    -ms-transform: translate(-50%,0px);
    transform: translate(-50%,0px);
    pointer-events:none;
  }
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    &:after{
      background: ${colors.primaryAlternate};
      top: 210px;
      height: 125vw;
      width: 125vw;
    }
  }

`;

export const TextBlock = styled.div`
  width:100%;
  padding-bottom: 25px;
  z-index: 1;
  .container{
    max-width:760px;
    margin:0 auto;
  }
  h1{
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom:15px;
    
    
  }
  p{
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding-bottom: 10px;
    h1{
      font-size: 44px;
      font-weight: 400;
      line-height: 53px;
      text-align:center;
    }
    p{
      font-size: 28px;
      font-weight: 300;
      line-height: 40px;
      text-align: center;
    }
  }
  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    h1{
      font-size: 50px;
      line-height: 60px;
    }
    p{
      font-size: 32px;
      line-height: 46px;
    }
  }
  
`;

export const SecondaryComponent = styled.div`
`;

export const ImageWrap = styled.div`
  img{
    background: white;
    padding: 15px;
    border-radius: 50%;
    width:auto;
    max-width:100%;
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    align-items: center;
    display: flex;
    width:400px;
    height:400px;
    background:${colors.white};
    border-radius:50%;
    
    img{
      background: transparent;
      border-radius: 0;
      padding: 0;
      max-width:70%;
      margin:0 auto;
    }
    
  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    width:550px;
    height:550px;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width:670px;
    height:670px;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width:790px;
    height:790px;
  }    
`;

export const LowerBlock = styled.div`
  margin:50px 0;
  z-index: 1;
  position: relative;

  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin:150px 0;
  }
`;

export const RightColumn = styled.div`
  padding-top:50px;
  padding-bottom:50px;
  position: relative; 
  h2{
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom:15px;
  }
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
  }
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    width:45%;
    
    h2{
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom:25px;
    }
    
    p{
      font-size: 28px;
      font-weight: 300;
      line-height: 40px;
      max-width: 505px;
    }
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {  
    h2{
      font-size: 16px;
      line-height: 19px;
      margin-right: -100px;
      padding-left: 100px;
    }
    
    p{
      font-size: 32px;
      line-height: 46px;
      margin-right: -100px;
      padding-left: 100px;
      max-width: 600px;
    }
  }
  
`;

export const UpperBlock = styled.div`
    display: flex;
    margin-top: 390px;
    @media (min-width: ${mediaQueriesSizes.lg}px) {  
      margin-top: 470px;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {  
      margin-top: 500px;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {  
      margin-top: 530px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {  
      margin-top: 560px;
    }
`;


export const Bubble = styled.div`
  width: 140px;
  height: 140px;
  position: absolute;
  top: 120px;
  left: 15px;
  border-radius: 50%;
  background: white;
  display:flex;
  align-items:center;
  z-index: 15;
  padding: 10px;
  p{
    text-align:center;
    width:100%;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    width: 215px;
    height: 215px;    
    top: 190px;
    left: 5%;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    width: 215px;
    height: 215px;    
    top: 220px;
    left: 5%;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    left: 10%;
    top: 250px;
    p {
      font-size: 14px;
      line-height: 23px;    
    }
  }
  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 245px;
    height: 245px;       
    left: 8%;
    top: 280px;
    p {
      font-size: 16px;
      line-height: 26px;
    }
  }

`;

export const ROW = styled.div`
`;


export const Container = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;


