import styled from "styled-components";

import { colors } from "../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  align-items: center;
  background: ${colors.secondaryAlternate};
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100%;
`;

export const LoadingIcon = styled.div`
  width: 150px;
  height: 150px;
  display: inline-block;
  overflow: hidden;
  background: ${colors.secondaryAlternate};
`;

export const LoadingSpinner = styled.div`  
  backface-visibility: hidden;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  transform-origin: 0 0;
  width: 100%;  

  div {
    position: absolute;
    animation: loading-spinner 1s linear infinite;
    width: 120px;
    height: 120px;
    top: 15px;
    left: 15px;
    border-radius: 50%;
    box-shadow: 0 3px 0 0 #ffffff;
    transform-origin: 60px 61.5px;    
    box-sizing: content-box;
  }

  @keyframes loading-spinner {
    0% { transform: rotate(0deg) }
    50% { transform: rotate(180deg) }
    100% { transform: rotate(360deg) }
  }  
`;