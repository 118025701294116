  import styled from 'styled-components';

import {mediaQueriesSizes, colors} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`

  padding-top: 80px;
  background: ${colors.secondaryAlternate};
  overflow:hidden;
  padding-bottom: 70px;
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding-top: 100px;

  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding-bottom: 100px;

  }
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding-bottom: 105px;
    padding-top: 150px;
  }
  
  .slick-arrow{
    display: block;
    position: absolute;
    right: 0;
    display: block;
    bottom: 0;
    z-index: 50;
    transform: translate(0, 0);
    cursor:pointer;
    transition:0.25s;
    width: 30px;
    height: 30px;
    background-size:contain;
    background-repeat: no-repeat;
    left: auto;
    top: calc(100vw - 20px); 

    @media (min-width: ${mediaQueriesSizes.sm}px) {
      top: calc(100vw - 60px);
    }

    @media (min-width: ${mediaQueriesSizes.md}px) {
      top: calc(100vw - 120px);

    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      top: auto;
      bottom: 0;
    }
    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      top: auto;
      width: 65px;
      height: 65px;
    } 


    &.slick-prev{
      right: 40px;
      background-image: url('/static-images/global/arrow-left-black.svg');
      background-size: contain;
      
      @media (min-width: ${mediaQueriesSizes.xxl}px) {
        right: 85px;
      }
      
      &:hover{
        background-image: url('/static-images/global/arrow-left-black.svg');
        background-size: contain;
      }
    }

    &.slick-next {
      background: url('/static-images/global/arrow-right-black.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }

    background-image: url('/static-images/global/arrow-right-black.svg');
    background-size: contain;

    &:hover{
      background-image: url('/static-images/global/arrow-right-black.svg');
      background-size: contain;
    }
    
    &:before{
      display:none;
    }
    
    &.slick-disabled{
      opacity: 0.3;
      pointer-events: none;
    }
  }
`;

export const H1 = styled.h1`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display:none;
  }

`;

export const SlideInner = styled.div`
  display:flex;
  flex-direction: column;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    flex-direction: row;  
  }
`;

export const SliderContainer = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;

export const TextWrap = styled.div`

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display:flex;
    width: calc( 100% - 300px);
    align-items: center;
    padding-left: 50px;
    position:relative;
  }
  
  @media (min-width: ${mediaQueriesSizes.xl}px) {
    width: calc( 100% - 450px);
  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: calc( 100% - 700px);
    padding-left: 70px;
  }
  
  .number{
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      position: absolute;
      bottom: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.1em;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 16px;
      line-height: 19px;      
    }
    
  }
  
  h2{
    padding-top: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom:15px;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 44px;
      line-height: 53px;
    }
    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 50px;
      line-height: 60px;
    }
  }
  
  p{
    font-size: 16px;
    line-height: 32px;
    @media (min-width: ${mediaQueriesSizes.xl}px) {
      font-size: 28px;
      line-height: 40px;
    }
    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 32px;
      line-height: 46px;
    }    
  }

`;

export const Row = styled.div`

`;

export const ImgWrap = styled.div`
  background: ${colors.white};
  width: 100%;
  display:flex;
  align-items: flex-start;
  margin-top:20px;
  margin-bottom: 30px;
  border-radius: 10px;
  border-top-right-radius: 95px;
  border-bottom-left-radius: 95px;
  overflow: hidden;
  padding-bottom: 10px;
  
  img{
    width: 90%;
    margin: 0 auto 10px;

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      margin: 0 auto;
    }    
  }
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    height: 300px;
    width: 300px;
    margin-bottom: 0;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    height: 450px;
    width: 450px;
    border-top-right-radius: 28.5%;
    border-bottom-left-radius: 28.5%;
  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    height: 700px;
    width: 700px;     
  }

`;

