import React, { forwardRef } from "react";

import { AccordionItem, ItemTitle } from "./styles";
import Typography from "../Typography";

const Item = forwardRef(({ children, title, toggleFAQ, index, activeFAQ }, ref) => {
  return (
    <AccordionItem id={`faq${index}`} className={index === activeFAQ ? 'active' : ''} ref={ref}>
      <ItemTitle
        type="heading4"
        as="h3"
        onClick={() => {toggleFAQ(index)}} onKeyDown={(e) => { toggleFAQ(index, e) }}
        role="button"
        tabIndex="0"
        aria-controls={`faq${index}`}
        aria-expanded={index === activeFAQ}
        data-cy={`faq-${index}`}
      >{title}<span></span></ItemTitle>
      <Typography>
        {children}
      </Typography>
    </AccordionItem>
  )
});

export default Item;
