const countries = [
  
  {
    url: 'en-US',
    title: 'USA English',
    country: ['US'],
    language: ['en-US'],
    img: '/images/global/flags/US.png',
    alt: 'USA flag linking to our US English website',
    cookieID: '43d8dcdb-b623-4ed3-b5e2-7c0d67c37eb3',
    logo: 'combined'
  },
  {
    url: 'es-US',
    title: 'USA Español',
    country: ['US'],
    language: ['eS-US'],
    img: '/images/global/flags/US.png',
    alt: 'USA flag linking to our US Spanish website',
    cookieID: '43d8dcdb-b623-4ed3-b5e2-7c0d67c37eb3',
    logo: 'combined'
  },
  {
    url: 'en-GB',
    title: 'UK English',
    country: ['GB'],
    language: ['en-GB'],
    img: 'images/global/flags/UK.png',
    alt: 'UK flag linking to our UK English website',
    cookieID: '9dfdd2fe-4410-446d-92fc-b0bc5e752083',
    logo: 'combined'
  }  
];

export default countries;
