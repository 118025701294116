import styled from 'styled-components';

import {mediaQueriesSizes, containerWidths, colors} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  overflow: visible;
  padding: 0 0 25px;
  background: ${colors.primary};
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 65px 0;
  }

  h3{
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 25px;
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 16px;
      line-height: 19px;      
    }
  }
  
  p{
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    width: auto;
    max-width: 850px;

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: 28px;
      font-weight: 300;
      line-height: 40px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 32px;
      line-height: 46px;
    }    
  }

`;


export const PhaseButtonsWrapper = styled.div`
    overflow-x: hidden;
    width: 100vw;
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      overflow-x: hidden;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      width: 100%;
    }
`;

export const PhaseButtons = styled.div`
  min-width: 600px;
  overflow-x: scroll;
  margin-bottom: 25px;

  @media (min-width: ${mediaQueriesSizes.sm}px) {
    overflow-x: hidden;
    margin-bottom: 50px;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    width: 100%;
  }
  
  display: flex;
  justify-content: space-between;
  background: rgba(255,255,255,0.6);
  border-radius: 35px;

  &.active {
    background: ${colors.white};
  }
  button{
  
    font-size: 14px;
    line-height: 14px;
    padding: 15px;
    min-width: 150px;
    width: 20%;
    font-family: PolySans;
    font-style: normal;
    font-weight: 300;
    border: none;
    border-radius: 35px;
    background: transparent;
    transition: 0.25s;
    cursor: pointer;
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding: 7px 0px;
      font-size: 19px;
      line-height: 32px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 22px;
      line-height: 36px;      
    }
    
    &:hover{
      opacity: 0.2;
    }
    
    &.active{
      background: ${colors.primaryAlternate};
      font-weight: 500;
      &:hover{
        opacity:1;
      }
    }
 }
  
`;

export const Slide = styled.div`
  
  display:flex;
  position:relative;
  outline: 0 !important;
  
  .image-wrap{
    width: 100%;
    height: 100%;
    display:flex;
    align-items:center;
    background: ${colors.white};
    border-bottom-right-radius: 45px;
    
    img{
      width: 87%;
      margin: 0 auto;
    }  

    
    @media (min-width: ${mediaQueriesSizes.md}px) {
      width:100%;
      height: ${containerWidths.md}px;
      border-bottom-right-radius: 80px;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      width:49%;
      height: 550px;
      border-bottom-right-radius: 100px;
    }
  }
  
  .row{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top:15px;
    
    .number{
      display: block;
      font-size: 14px;
      font-weight: 300;
      line-height: 16px;
      
      @media (min-width: ${mediaQueriesSizes.lg}px) {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.1em;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 16px;
        line-height: 19px;        
      }
    }
    
    img{
      width:30px;
      display:inline-block;
      margin-left:8px;
      cursor: pointer;
      &.disabled{
        cursor: auto;
        opacity:0.2;
      }

      @media (min-width: ${mediaQueriesSizes.lg}px) {
        margin-left:10px;
        width: 40px;
      }
    }
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      position: absolute;
      bottom:0;
      right:0;
      width:45%;
    }
  }
  
`;
export const NavWrap = styled.div`
`;

export const SlideInner = styled.div`
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    display:flex;
    justify-content: space-between;
  }
`;

export const TextWrap = styled.div`


  h3{
    margin-top:30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-transform: initial;
    letter-spacing: 0;
  }
  
  ul{
    margin:0;
    padding:0;
    
    li{
      list-style:none;
      display:flex;
      padding: 15px 0;

      &:nth-last-of-type(3) {
        .circle-wrap {
          img {
            height: 12px;
          }
        }
      }

      .circle-wrap{
        width:40px;
        height: 40px;
        display: flex;
        align-items: center;
        background: white;
        border-radius: 50%;
        text-align: center;
        margin-right:30px;

        img{
          width: auto;
          height: 15px;
          margin: 0 auto;
        }
      }
      
      .text-wrap{
        width: calc(100% - 80px);
        @media (min-width: ${mediaQueriesSizes.lg}px) {
          width: 100%;
        }

        h4{
          font-size: 16px;
          font-weight: 500;
          line-height: 16px;
          margin-bottom:5px;
        }
        p{
          font-size: 14px;
          font-weight: 300;
          line-height: 19px;
        }
      }
    }
  }
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    width: 45%;
    display: flex;
    align-items: center;
        
    h3{
      font-size: 28px;
      font-weight: 300;
      line-height: 40px;
    }
        
    ul{
      display: flex;
      width: 100%;
      li{
        width:25%;
        flex-direction: column;
        border-right: solid 1px ${colors.white};
        padding-right: 15px;
        margin-right: 15px;
        
        &:last-of-type{
          border-right: none;
          padding-right: 0;
          margin-right: 0;

        }
        
        .circle-wrap{
          margin-bottom: 20px;
        }
        
        .text-wrap{
          h4{
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            margin-bottom:7px;
          }
          p{
            font-size: 12px;
            font-weight: 300;
            line-height: 17px;
          }
        }        

      }
    }
    
  }
  
  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    h3{
      font-size: 32px;
      line-height: 46px;
    }  
    ul {
      li {
        .text-wrap{
          h4{
            font-size: 16px;
            line-height: 16px;
          }
          p{
            font-size: 14px;
            line-height: 19px;
          }
        }        
      }
    }
  }

  

`;

export const PhaseContainer = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
