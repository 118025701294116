import styled from 'styled-components';
import {mediaQueriesSizes, colors} from "../../assets/styles/GlobalStyles.js";
import {Button} from "@lxlabs/faze-screener-components-library";
// import {country} from "../../templates/page";

export const ButtonStyled = styled(Button)`
  width: auto;
  margin: 10px 10px;
  padding: 10px 60px 10px 20px;
  border-color: white;
  background-image: url('/static-images/right-arrow-no-circle-white.svg');
  background-repeat: no-repeat;
  background-position: right 24px center;
  background-size: 17px;

  &:hover {
    background-image: url('/static-images/right-arrow-no-circle-black.svg') !important;
    background-repeat: no-repeat !important;
    background-position: right 24px center !important;
    background-size: 17px !important;
  }
  

  span {
    color: white;
    text-transform: initial;
  }

  &:hover {
    background: white;

    span {
      color: black;
    }
  }
`

export const ScrollUpBtn = styled.div`
  position: fixed;
  bottom: 100px;
  background: white;
  width: 57px;
  height: 57px;
  border-radius: 50%;
  right: 15px;
  border: solid 1px black;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.6s;
  z-index: 5000;
  opacity: 1;
  pointer-events: initial;
  &.disabled{
    opacity:0;
    pointer-events: none;
  }
  
  &:hover{
    background-color: ${colors.primary};
  }
  
  img{
    pointer-events: none;
    width:23px;
    margin: 0 auto;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    bottom: 250px;
  }
`;

export const FooterStyles = styled.footer`
    background-color: ${colors.navy};  
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:35px 22px 155px 21px;
    
   .screener-footer {margin-bottom: 80px;} 

   &.hide-nav {
       padding-top: 20px;
       padding-bottom: 35px;
   }
    
    &.screener-footer{
      margin-bottom:80px;
      padding:35px 22px 75px 21px;
      @media (min-width: ${mediaQueriesSizes.lg}px) {
      padding:35px 22px 35px 21px;
      }
    }
    
    hr {
        width:100%;
        margin-block-start: 18px;
        margin-block-end: 18px;
        border: none;
        border-bottom:1px dashed ${colors.secondary};
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        margin-bottom:0px;

        &.hide-nav {
            padding: 55px 40px;

            .report {
                margin-top: 0;
            }
        }        
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        padding:55px 40px 33px 40px;

        hr {
            margin-block-start: 41px;
            margin-block-end: 30px;
        }

        .divider {
            display: none;
        }
    }
`;

export const FooterContainer = styled.div`
    color: ${colors.white}; 
    width:100%;
    display:flex;
    align-items:flex-start;
    flex-direction:${props => props.columnReverse ? 'column-reverse' : 'column'} ;
    justify-content:space-between;

    button { margin-bottom:35px;}

    a {
        color: ${colors.white}; 
        text-decoration:none;
        margin-top:17px;
        font-size:14px;
    }

    .report {
        margin-top:15px;
        font-size:14px;
        a {
            color: ${colors.white};
            border-bottom: solid 1px ${colors.secondary};
            text-decoration: none;
            padding-bottom: 0;
        }
    }

    .apply-now-btn {
        display: none;
    }

    .language-switch-btn {
        display: inline-block;
        width: 100%;
        margin: 0;
        text-align: left;
        min-width: 217px;
        span {
            font-size: 16px;
        }
    }

    

    @media (min-width: ${mediaQueriesSizes.xl}px) {
        flex-direction: row;
        align-items:center;

        button {margin-bottom:0;}
        a {
            margin-top:0;
            font-size:14px;
            text-decoration:none;
        }
        .report {
            margin-top:15px;
            font-size:14px;
        }
        .apply-now-btn {
            display: inline-block;
            width: 320px;
        }

        .language-switch-btn {
            padding: 16px 20px;
            margin-right: 20px;
            display: inline-block;
            width: 258px;
        }
        
    }

    @media (min-width: 1406px) {
        .language-switch-btn {
            margin-right: auto;
        }
    }    
`;

export const Nav = styled.div`
    display: flex;
    flex-direction: column;

    span {
        padding-right:31px;
        display:none;
    }

    a {
        color: ${colors.white}; 
        padding: 0;
        text-decoration:none;
        font-size:14px;
        position: relative;

        @media (min-width: ${mediaQueriesSizes.lg}px) {
            padding-left: 40px;
            padding-right: 20px;

            &.active {
                &:before {
                    content: '';
                    position: absolute;
                    left: 15px; 
                    top: 3px;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    background: ${colors.white};  
                }
            }
        }
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        flex-direction: row;
        a {font-size:16px;}
    }
    @media (min-width: ${mediaQueriesSizes.xl}px) {
        span {display:inherit;}
    }
`;
