import styled from "styled-components";

import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  align-items: center;
  background: ${colors.secondaryAlternate};
  display: flex;
  justify-content: center;
  padding: 100px 0;
  position: relative;
  overflow: hidden;

  @media (min-width: ${mediaQueriesSizes.md}px) {      
    min-height: calc(100vh);
  }

  .background {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .row {
    align-items: center;    
    display: flex;
    justify-content: center;
  }

  .column {
    align-items: center;        
    background: ${colors.white};
    border-radius: 20px;    
    display: flex;
    justify-content: center;
    max-width: 1220px;
    padding: 80px 25px;
    position: relative;
    width: 100%;
    z-index: 1;

    .content {
      max-width: 660px;
      width: 100%;
    }

    h1 {
      font-size: 36px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 45px;
      text-align: left;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 44px;
        font-weight: 400;
        line-height: 53px;
        margin-bottom: 5px;
        text-align: center;
      }

      @media (min-width: ${mediaQueriesSizes.xl}px) {
        font-size: 50px;
        line-height: 60px;
        margin-left: auto;
        margin-right: auto;
        max-width: 540px;
      }      
    }

    p {      
      font-size: 16px;
      line-height: 32px;      
      margin: 20px 0 0;
      text-align: left;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 14px;
        line-height: 23px;         
        text-align: center;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 16px;
        line-height: 26px;
      }       
    }

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 20px;
      margin: 50px 0 0;
      padding: 0;
      justify-content: center;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        flex-direction: row;
      }

      li {
        margin-top: 10px;
        width: 100%;

        @media (min-width: ${mediaQueriesSizes.md}px) {
          width: calc(50% - 10px);
        }

        button {
          width: 100%;

          span {
            text-transform: initial;
          }
        }
      }
    }
  }
`;

export const BackgroundBubble = styled.div`  
  border-radius: 50%;
  position: absolute;

  ${props => props.bubble1 && `
  background: transparent;
  border: 2px solid #FF7733;
  height: 500px;
  left: -210px;
  top: -111px;
  width: 500px;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    height: 1177px;
    left: -368px;
    top: -446px;
    width: 1177px;
  }
`}

${props => props.bubble2 && `
  background: transparent;
  border: 2px solid #FF7733;  
  height: 500px;
  top: 54%;
  left: 40%;
  width: 500px;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
  height: 974px;
  top: 34%;
  left: 70%;
  width: 974px;
  
`}

`;
