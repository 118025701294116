import React, { Children, cloneElement } from 'react';
import Step from './step';
import { Style } from './style';

const Process = ({ children, className }) => {
  return (
    <Style className={className}>
      {Children.map(children, (child, index) => cloneElement(child, {
        count: index + 1,
      }))}
    </Style>
  )
}

Process.Step = Step;

export default Process;
