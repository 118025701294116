import styled from "styled-components";
import { colors } from "../../assets/styles/GlobalStyles";

export const Style = styled.input`
  background: transparent;
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;

  &:active, &:focus {
    outline: none;
    border-color: ${colors.light};
  }
`