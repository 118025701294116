import styled from "styled-components";

import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  background: ${colors.secondaryAlternate};  
  padding: 50px 0 30px;
  position: relative;
  overflow: hidden;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding-bottom: 70px;
    padding-top: 150px;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding-top: 170px;
  } 

  .row {
    border-radius: 20px;
    padding: 50px 0;
  }

  .text-column { 
      display: flex;
      justify-content: center;
      padding: 0;   
      width: 100%;      
      padding-top: 15px;
  }

  .text-container {
    display: block;
    position: relative;
    width: 100%;
    text-align: center;

    @media (min-width: ${mediaQueriesSizes.md}px) {        
      max-width: 910px
    }
  }
`;

export const Bubble = styled.div`
    align-items: center;    
    /* background: linear-gradient(180deg, ${colors.primary} 0%, rgba(255, 255, 255, 0.1) 100%); */
    background: ${colors.primaryAlternate};
    border-radius: 50%;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 300vw;
    z-index: 0;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 118vw;
      top: 20px;
    }

    &:before {
        content: '';
        display: inline-block;
        height: 0;
        padding-bottom: 100%;
        width: 0;
    }
`;
