import styled from "styled-components";
import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const Style = styled.div`
  overflow: hidden;
  background-color: ${colors.light};
  position: relative;
`;

export const Bubble = styled.div`
  background-color: ${colors.primaryAlternate};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    border-radius: 100%;
    aspect-ratio: 1 / 1;
  }
`

export const BubbleInner = styled.div`
  z-index: 2;

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    max-width: 450px;
  }
`

export const LeftBubble = styled(Bubble)`
  float: left;
  padding: 100px 0px; 
  
  margin-top: 100px;
  padding-top: 0px;

  &:before {
    content: '';
    display: block;
    width: 110%;
    margin: 0 auto;
    height: 20vh;
    background: inherit;
    position: absolute;
    top: -10vh;
    border-radius: 100% 100% 0% 0%;
    z-index: 0;
  }

  @media (min-width: ${mediaQueriesSizes.lg}px) {
    margin-top: 0px;
    max-width: 845px;
    margin-left: 5%;
    margin-bottom: 50px;
    padding: 0px;

    &:before {
      display: none;
    }
  }
`

export const RightBubble = styled(Bubble)`
  float: right;
  margin-bottom: 0px;
  padding-bottom: 100px;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    max-width: 1200px;
    margin-bottom: -30%;
    margin-right: 3%;
    padding-bottom: 20%;
  }
`