import styled from 'styled-components';

import {mediaQueriesSizes,colors} from "../../../assets/styles/GlobalStyles.js";

export const MobileSliderWrap = styled.div`
    background-color: ${colors.secondaryAlternate};
    padding-bottom: 70px;

    @media (min-width: ${mediaQueriesSizes.lg}px) { 
      display:none
    }

    .slick-arrow{
      display: block;
      position: absolute;
      right: 0;
      display: block;
      bottom: 0;
      z-index: 50;
      transform: translate(0, 0);
      cursor:pointer;
      transition:0.25s;
      width: 30px;
      height: 30px;
      background-size:contain;
      left: auto;
      top: 150px;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        top: 250px;
    }
    
    &.slick-prev{
      left:0;   
      background-image: url('/static-images/global/arrow-left-black.svg');
      background-size: contain;
      
      &:hover{
        background-image: url('/static-images/global/arrow-left-black.svg');
        background-size: contain;
      }
    }
    
    background-image: url('/static-images/global/arrow-right-black.svg');
    background-size: contain;

    &:hover{
      background-image: url('/static-images/global/arrow-right-black.svg');
      background-size: contain;

    }
    
    
    &:before{
      display:none;
    }
    
    &.slick-disabled{
      opacity: 0.3;
      pointer-events: none;
    }
    
  }

`;

export const Slide = styled.div`
  .number{
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom:20px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 12px;
      line-height: 14px;      
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 14px;
      line-height: 16px;      
    }
  } 
  h3{
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom:15px;
    
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 32px;
      font-weight: 300;
      line-height: 32px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 36px;
      line-height: 36px      
    }
    
  }
  p{
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 19px;
      font-weight: 400;
      line-height: 32px;
    }
    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 22px;
      line-height: 36px;      
    }

  }
`;

export const FixedTextInner = styled.div`
  h3{
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom:30px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 32px;
      line-height: 32px;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 36px;
      line-height: 36px;
    }
  }
  p{
    font-size: 22px;
    font-weight: 400;
    line-height: 36px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      font-size: 19px;
      font-weight: 300;
      line-height: 32px; 
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 22px;
      line-height: 36px;      
    }    
  }
  .button-wrap{
    margin-top: 50px;
  }
  
`;

export const ImageWrap = styled.div`
  display: flex;
  align-items: center;
  height: 300px;
  margin-bottom:20px;

  .img-container {
    padding: 45px 20px;
    border-radius: 50%;
    background-color: ${colors.white};
    max-width:75%;
    width: auto;
    margin:0 auto;

    img{
    width: auto;
    max-width:75%;
    margin:0 auto;
    }
  }

  @media (min-width: ${mediaQueriesSizes.sm}px) {
    margin: 30px 0;

    .img-container{
      padding: 55px 20px;
    }
  }
  

  @media (min-width: ${mediaQueriesSizes.md}px) {
    height: 500px;
    margin: 30px 0;

    .img-container{
      padding: 70px 20px;
    }
  }
  
`;

export const ButtonWrapper = styled.div`
  a{
    display: block;
    margin: 40px auto;
    
  }  
`;

export const DesktopWrapper = styled.div`
  background-color: ${colors.secondaryAlternate};
  position:relative;
  display:none;
  overflow: hidden;

  @media (min-width: ${mediaQueriesSizes.lg}px) { 
    display:block
  }

`;

export const CircleWrapper = styled.div`
  width: 370px;
  height: 370px;
  background: ${colors.white};
  border-radius: 50%;
  
  @media (min-width: ${mediaQueriesSizes.xl}px) { 
    width: 500px;
    height: 500px;
  }
  @media (min-width: ${mediaQueriesSizes.xxl}px) { 
    width: 600px;
    height: 600px;
  }
  @media (min-width: ${mediaQueriesSizes.xxxl}px) { 
    width: 754px;
    height: 754px;
  }
  display: flex;
  align-items: center;

  img{
    width: 68%;
    margin:0 auto;
  }
`;


export const CircleFixedWrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  pointer-events: none;
  &.active{
    position:fixed;
    top:0;
    left:0;
    width:100%;
  }
  
  &.active-bottom{
    position: absolute;
    top: auto;
    bottom: 0;
    width:100%;
  }
`;

export const Wrap = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  
  .row{
    display:flex;
    justify-content: space-between;

    width: 100%;

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 92%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      width: 100%;
    }

    .column{
      width: 45%;
      display: flex;
      align-items: center;
    }
    
  }
  
`;

export const CircleContainer = styled.div`
  width: 100%;
  pointer-events: none;
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
