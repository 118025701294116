import React, { forwardRef } from "react";

import { Style } from './styles';

const TextInput = forwardRef((props, ref) => {
  return (
    <Style {...props} ref={ref} />
  )
})

export default TextInput;
