import React from 'react';

import { Style, Main } from './style';
import Section from '../Section';

const TwoColumns = ({ children, side, ...props }) => {
  return (
    <Section {...props}>
      <Style>
        <div>
          {side}
        </div>
        <Main>
          {children}
        </Main>
      </Style>
    </Section>
  )
}

export default TwoColumns;
