import styled from "styled-components";
import { mediaQueriesSizes } from "../../assets/styles/GlobalStyles";

export const Style = styled.div`
  width: 320px;
  

  @media (min-width: ${mediaQueriesSizes.md}px) {
    width: 100%;
  }

  .card-slider {
    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;

      > div {
        height: 100%;
      }
    }
  }
`

export const Slide = styled.div`
  height: 100%;
  padding: 0 14px 0 0;

  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 0 20px 0 0;
  }

  @media (min-width: ${mediaQueriesSizes.xl}px) {
    padding: 0 28px 0 0;
  }

  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    padding: 0 34px 0 0;
  }

`