import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import {
  Reference
} from './styles';

const createMarkUp = (content) => {
    return {__html: content};
}

const ReferenceNote = ({copy}) => {

    return (
        <Reference>
            <p dangerouslySetInnerHTML={createMarkUp(copy)}></p>
        </Reference>
    );
}


ReferenceNote.propTypes = {
    copy: PropTypes.string,
};
 
ReferenceNote.defaultProps = {};
 
 export default ReferenceNote;
 