import React from 'react';

import { BubbleInner, LeftBubble, RightBubble, StyledBlock, Wrapper } from './styles';

const BubbleHero = ({ leftBubble, rightBubble }) => {
  return (
    <StyledBlock>
      <Wrapper>
        <LeftBubble>
          <BubbleInner className="container">
            {leftBubble}
          </BubbleInner>
        </LeftBubble>
        <RightBubble>
          <BubbleInner className="container">
            {rightBubble}
          </BubbleInner>
        </RightBubble>
      </Wrapper>
    </StyledBlock>
  )
}

export default BubbleHero;
