
import styled from "styled-components";

import {
  colors,
  mediaQueriesSizes,
} from "../../../assets/styles/GlobalStyles.js";

export const Reference = styled.div`
    position: relative;
    height: 100px;
    width: 100%;
    margin-top: -20px;
    padding: 0 20px;
    background-color: ${colors.primary};
    p {
        font-size: 14px;
        line-height: 16px;
        max-width: 450px;
        margin: 0 auto;
    }   

    @media (min-width: ${mediaQueriesSizes.md}px) {
        margin-top: -200px;
        height: 200px;
        background-color: transparent;  
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
        margin-top: -80px;
        height: 80px;
        p {
            margin-left: 5%;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                width: 290px;

            }
        }

    }
}
`;