import React, { useContext, useEffect } from "react";
import { ThankYouScreen } from "@lxlabs/faze-screener-components-library";
import { PageContext } from "../../Context/pageContext";
import FooterController from "../Footer";

const CandidateAlreadyRegistered = ({ contentCopy, location }) => {
    let pageContext = useContext(PageContext);

    // The 'location' prop is passed from the 'navigate' function in the 'errorHandle' prop in CandidateForm component
    let response = null;

    if (location && location.state) {
        response = location.state.response;
    }

    let referenceNumber = '';

    if (response && response.error && response.error.duplicationReport.candidates.length > 0) {
        referenceNumber = response.error.duplicationReport.candidates[0]?.idCode;
    }

    return (
        <>
            <ThankYouScreen
                logo={{
                    style: contentCopy.logo,
                    link: contentCopy.logoLink
                }}
                copyData={contentCopy.alreadyRegistered.copyData}
                type={contentCopy.alreadyRegistered.type}
                button={{
                    homePageLabel: contentCopy.buttonLabels.backToHome,
                    homePageLink: pageContext.homepagePath
                }}
                referenceNumber={referenceNumber}
            />
            <FooterController addLowerMargin={true} button={false} />
        </>
    )
};

export default CandidateAlreadyRegistered;