import React, {useRef, useState} from 'react';
import Slider from "react-slick";
import { gsap } from "gsap";

import {
  Wrap,
  Slide,
  ImageWrap,
  CircleWrapper,
  ButtonWrapper,
  FixedTextInner,
  DesktopWrapper,
  CircleFixedWrap,
  MobileSliderWrap,
  CircleContainer
} from './styles';

import { Button } from "@lxlabs/faze-screener-components-library";

const SafetySlider = ({slides, button}) => {

  const sliderRef = useRef();
  const [circleImage,setCircleImage] = useState(slides[0].image);
  const [circleImageClass,setCircleImageClass] = useState('');
  const imageRef = useRef();
  let tempCircleImage = circleImage;
  let changingImage = false;

  const handleScroll = () => {

    const element = document.querySelector('#safety-slider-wrap');
    let elemData = element.getBoundingClientRect();
    let html = document.documentElement.clientHeight;

    if (elemData.y <= 0) {

      if ((elemData.height - html + elemData.y) < 0) {
        setCircleImageClass('active-bottom');
      } else {
        setCircleImageClass('active');

        let newImage;
        if (((elemData.top * -1) / elemData.height) < 0.125) {
          newImage = slides[0].image;
        } else if (((elemData.top * -1) / elemData.height) < 0.375) {
          newImage = slides[1].image;
        } else if (((elemData.top * -1) / elemData.height) < 0.625) {
          newImage = slides[2].image;
        } else {
          newImage = slides[3].image;
        }

        if (newImage !== tempCircleImage && changingImage === false) {
          changingImage = true;
          gsap.to(imageRef.current, {
            x: -50,
            opacity: 0,
            duration: 0.5,
            onComplete: () => {
              setCircleImage(newImage);
              tempCircleImage = newImage;
            }
          });

          gsap.to(imageRef.current, {
            x: 0,
            opacity: 1,
            delay: 0.75,
            onComplete: () => {
              changingImage = false
            }
          });


        }

      }

    } else {
      setCircleImageClass('');
    }


  }

  window.addEventListener("scroll", handleScroll, {passive: true});

  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
  };

  return (
    <>
      <MobileSliderWrap className={'hello-world'}>
        <div className={'container'}>

          <Slider {...settings} ref={sliderRef}>

            {slides.map((slide, key) => {
              return (
                <Slide key={key}>
                  <ImageWrap>
                    <div className={'img-container'}>
                      <img src={slide.image} alt=""/>
                    </div>
                  </ImageWrap>
                  <span className={'number'}>0{key + 1}/0{slides.length}</span>
                  <h3>{slide.title}</h3>
                  <p>{slide.copy}</p>
                  <ButtonWrapper>
                    <Button
                      label={button.label}
                      href={button.href}
                      type={'outline'}
                    />
                  </ButtonWrapper>
                </Slide>
              )
            })}
          </Slider>
        </div>
      </MobileSliderWrap>
      <DesktopWrapper id={'safety-slider-wrap'}>
        <CircleFixedWrap className={circleImageClass}>
          <div className={'container'}>
            <CircleContainer>
              <CircleWrapper>
                <img ref={imageRef} src={circleImage} alt=""/>
              </CircleWrapper>
            </CircleContainer>
          </div>
        </CircleFixedWrap>

        {slides.map((slide, key) => {
          return (
            <Wrap key={key}>
              <div className={'container'}>

                <div className={'row'}>
                  <div className={'column'}>

                  </div>

                  <div className={'column'}>
                    <FixedTextInner>
                      <h3>{slide.title}</h3>
                      <p>{slide.copy}</p>
                      {slide.button ?
                          <div className={'button-wrap'}>
                            <Button
                                label={slide.button.label}
                                href={slide.button.href}
                                type={'outline'}
                                cyAttribute={'safety-slider_supernova_trial-button'}
                            />
                          </div>
                        :
                        <></>
                      }

                    </FixedTextInner>
                  </div>
                </div>
              </div>
            </Wrap>
          )
        })}


      </DesktopWrapper>
    </>
  );

}

export default SafetySlider;
