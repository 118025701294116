  import styled from 'styled-components';

import {mediaQueriesSizes,colors} from "../../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  background: ${props => colors[props.background]};
  padding: 40px 0 35px;
  
  @media (min-width: ${mediaQueriesSizes.lg}px) {
    padding: 115px 0 0;
  }

  h2{
    font-size: 36px;
    font-weight: 300;
    line-height: 36px;
    margin-bottom: 25px;
    
    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 16px;
      line-height: 19px;      
    }
  }
  
  p{
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    width: auto;
    max-width: 850px;

    span {
      font-size: 14px;
      line-height: 16px;
      display: inline-block ;
    }

    @media (min-width: ${mediaQueriesSizes.lg}px) {
      font-size: 28px;
      font-weight: 300;
      line-height: 40px;

      span {
        line-height: 19px;
      }
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      font-size: 32px;
      line-height: 46px;      
    }
  }

`;

export const TextContainer = styled.div`
  width: 100%;
  
  @media (min-width: ${mediaQueriesSizes.xxl}px) {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${mediaQueriesSizes.xxxl}px) {
    width: 100%;
  }  
`;
