import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lxlabs/faze-screener-components-library';

import {
  StyledBlock,
  Bubble,
  BackgroundBubble
} from './styles';

const TrialClosed = ({title, copy, image, imageAlt, button}) => {

  useEffect(() => {

  }, []);

  return (
    <StyledBlock>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'column text-column'}>
            <h1>{title}</h1>
            <p>{copy}</p>
            <Button
              label={button.label}
              type={'outline'}
              onClick={() => window.open(button.href)}
            />            
          </div>
          <div className={'column image-container'}>
            <Bubble>
              <img src={image} alt={imageAlt} />
            </Bubble>
            <Button
              label={button.label}
              type={'outline'}
              onClick={() => window.open(button.href)}
            />             
          </div>          
        </div>
      </div>
    </StyledBlock>
  );

}

TrialClosed.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  copy: PropTypes.string,
  button: PropTypes.object,
};

TrialClosed.defaultProps = {};

export default TrialClosed;
