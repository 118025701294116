import styled from "styled-components";

import { colors, mediaQueriesSizes } from "../../assets/styles/GlobalStyles.js";

export const StyledBlock = styled.div`
  background: ${colors.primaryAlternate};
  min-height: 100vh;
  padding: 20px 0;
  position: relative;
  overflow: hidden;

  .background {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;      

    @media (min-width: ${mediaQueriesSizes.md}px) {      
      display: block;
    }
  }

  .row {
    background: ${colors.white};
    border-radius: 20px;
    margin-top: 70px;
    padding: 50px 20px 40px 20px;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      align-items: center;
      background: none;
      border-radius: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 100px;
      padding: 0;
    }

    @media (min-width: ${mediaQueriesSizes.xl}px) {
      width: 100%;
    } 

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 92%;
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: ${mediaQueriesSizes.xxxl}px) {
      width: 100%;
    }

  }

  .text-column {
    padding: 20px 0 0;
    position: relative;
    z-index: 1;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      width: 480px;
    }

    h1 {
      font-size: 36px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 45px;
      text-align: center;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 44px;
        font-weight: 400;
        line-height: 53px;
        margin-bottom: 5px;
        text-align: left;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 50px;
        line-height: 60px;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 32px;
      margin-bottom: 0;
      text-align: center;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        margin-bottom: 40px;
        text-align: left;
      }

      @media (min-width: ${mediaQueriesSizes.xxxl}px) {
        font-size: 16px;
        line-height: 26px;
      }
    }

    a, button {
      display: none;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        width: 320px;
        display: block;
      }
    }
  }

  .image-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px;
    position: relative;
    z-index: 1;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      flex-direction: row;
      padding: 0;
      width: 45%;
    }

    @media (min-width: ${mediaQueriesSizes.xxl}px) {
      width: 53%;
      padding-right: 8%;
    }

    a, button {
      margin-top: 20px;

      @media (min-width: ${mediaQueriesSizes.md}px) {
        display: none
      }
    }
  }
`;

export const Bubble = styled.div`
  background: ${colors.white};
  border-radius: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    display: inline-block;
    height: 0;
    padding-bottom: 100%;
    width: 0;
  }

  img {
    width: 100%;

    @media (min-width: ${mediaQueriesSizes.md}px) {
      max-width: 80%;
    }
  }
`;

export const BackgroundBubble = styled.div`  
  border-radius: 50%;
  position: absolute;

  ${props => props.bubble1 && `
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(233, 239, 241, 0) 100%);  
  height: 197px;
  left: 34%;  
  top: 120px;    
  transform: rotate(-17.75deg);
  width: 197px;
`}

${props => props.bubble2 && `
  background: linear-gradient(180deg, ${colors.white} 0%, rgba(233, 239, 241, 0) 100%);
  height: 245px;
  left: 75%;
  top: -51px; 
  transform: rotate(77.86deg);
  width: 245px;
`}

${props => props.bubble3 && `
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(233, 239, 241, 0) 100%);
  height: 442px;
  left: 80%;
  bottom: -200px;
  transform: rotate(146.26deg);
  width: 442px;
`}

`;
