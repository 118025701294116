const screenerQuestions = [
  {
    url: "1",
    questionType: "MultipleChoice",
    question: {
      subtitle: "Question 1 of 14",
      copy: "Do any of the following statements apply to you today?",
      defaultCopy: "Do any of the following statements apply to you today?",
      description: "Please select all that apply",
      metaTitle: "Question 1 of 14",
      metaDescription: "Question 1 of 14",
    },
    answerArrangement: "separate-multi-answer",
    answer: {
      data: [
        {
          copy: `I have cancer`,
          value: "I have cancer",
          result: "proceed",
        },
        {
          copy: "I have had an organ transplant or stem cell transplant",
          value: "I have had an organ transplant or stem cell transplant",
          result: "proceed",
        },
        {
          copy: "I am taking medicines that make my immune system weaker (\"immunosuppressive medicines\")",
          value: "I am taking medicines that make my immune system weaker (\"immunosuppressive medicines\")",
          result: "proceed",
        },
        {
          copy: "I have received CAR-T therapy",
          value: "I have received CAR-T therapy",
          result: "proceed",
        },
        {
          copy: "I have received B-cell depleting therapy such as rituximab (Rituxan®), ocrelizumab (Ocrevus®), ofatumumab (Kesimpta®), or alemtuzumab (Lemtrada®) within the past year",
          value: "I have received B-cell depleting therapy such as rituximab (Rituxan®), ocrelizumab (Ocrevus®), ofatumumab (Kesimpta®), or alemtuzumab (Lemtrada®) within the past year",
          result: "proceed",
        },
        {
          copy: "My immune system does not function properly because I have a condition such as DiGeorge syndrome, Wiskott-Aldrich syndrome, immunodeficiency,  agammaglobulinemia, or I am on haemodialysis",
          value: "My immune system does not function properly because I have a condition such as DiGeorge syndrome, Wiskott-Aldrich syndrome, immunodeficiency,  agammaglobulinemia, or I am on haemodialysis",
          result: "proceed",
        },
        {
          copy: "None of the above",
          value: "None of the above",
          result: "reject",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "2",
    questionType: "YesOrNo",
    question: {
      subtitle: "Question 2 of 14",
      copy: "Do you have HIV (human immunodeficiency virus)?",
      defaultCopy: "Do you have HIV (human immunodeficiency virus)?",
      metaTitle: "Question 2 of 14",
      metaDescription: "Question 2 of 14",
    },
    answer: {
      arrangement: "inline",
      data: [
        {
          copy: "Yes",
          value: "Yes",
          result: "reject",
        },
        {
          copy: "No",
          value: "No",
          result: "proceed",
        },
      ],
    },
  },

  {
    url: "3",
    questionType: "MultipleChoice",
    question: {
      subtitle: "Question 3 of 14",
      copy: "Do any of the following statements apply to you?",
      defaultCopy: "Do any of the following statements apply to you?",
      description: "Please select all that apply",
      metaTitle: "Question 3 of 14",
      metaDescription: "Question 3 of 14",
    },
    answerArrangement: "separate-multi-answer",
    answer: {
      data: [
        {
          copy: `I have asthma`,
          value: "I have asthma",
          result: "proceed",
        },
        {
          copy: "I have COPD",
          value: "I have COPD",
          result: "proceed",
        },
        {
          copy: "I have multiple sclerosis (MS)",
          value: "I have multiple sclerosis (MS)",
          result: "proceed",
        },
        {
          copy: "I have arthritis",
          value: "I have arthritis",
          result: "proceed",
        },
        {
          copy: "I have ankylosing spondylitis (AS)",
          value: "I have ankylosing spondylitis (AS)",
          result: "proceed",
        },
        {
          copy: `I have psoriatic arthritis`,
          value: "I have psoriatic arthritis",
          result: "proceed",
        },
        {
          copy: `I have rheumatoid arthritis (RA)`,
          value: "I have rheumatoid arthritis (RA)",
          result: "proceed",
        },
        {
          copy: `I have plaque psoriasis`,
          value: "I have plaque psoriasis",
          result: "proceed",
        },
        {
          copy: `I have lupus`,
          value: "I have lupus",
          result: "proceed",
        },
        {
          copy: `I have Crohn’s disease`,
          value: "I have Crohn’s disease",
          result: "proceed",
        },
        {
          copy: `I have ulcerative colitis (UC)`,
          value: "I have ulcerative colitis (UC)",
          result: "proceed",
        },
        {
          copy: `I have chronic kidney disease (CKD)`,
          value: "I have chronic kidney disease (CKD)",
          result: "proceed",
        },
        {
          copy: `I have type 2 diabetes`,
          value: "I have type 2 diabetes",
          result: "proceed",
        },
        {
          copy: `I have cardiovascular disease`,
          value: "I have cardiovascular disease",
          result: "proceed",
        },
        {
          copy: `I have non-alcoholic steatohepatitis (NASH)`,
          value: "I have non-alcoholic steatohepatitis (NASH)",
          result: "proceed",
        },
        {
          copy: "I have none of the above",
          value: "I have none of the above",
          result: "proceed",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "4",
    questionType: "YesOrNo",
    question: {
      subtitle: "Question 4 of 14",
      copy: "Do you weigh at least 40 kg (88 lb)?",
      defaultCopy: "Do you weigh at least 40 kg (88 lb)?",
      metaTitle: "Question 4 of 14",
      metaDescription: "Question 4 of 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "YES",
          value: "YES",
          result: "proceed",
        },
        {
          copy: "NO",
          value: "NO",
          result: "reject",
        },
        {
          copy: "NOT SURE",
          value: "NOT SURE",
          result: "reject",
        },
      ],
    },
  },
  {
    url: "5",
    questionType: "YesOrNo",
    question: {
      subtitle: "Question 5 of 14",
      copy: "Have you ever had an adverse (bad) reaction to treatment with a monoclonal antibody?",
      defaultCopy: "Have you ever had an adverse (bad) reaction to treatment with a monoclonal antibody?",
      description: "Monoclonal antibody treatments are used to treat cancer, autoimmune disease and infectious diseases, and tend to end in 'mab' (such as infliximab or rituximab).",
      metaTitle: "Question 5 of 14",
      metaDescription: "Question 5 of 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "YES",
          value: "YES",
          result: "reject", 
        },
        {
          copy: "NO",
          value: "NO",
          result: "proceed",
        },
        {
          copy: "NOT SURE",
          value: "NOT SURE",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "6",
    questionType: "YesOrNo",
    question: {
      subtitle: "Question 6 of 14",
      copy: 'Has a medical professional taken one unit or more <span style="white-space: nowrap;">(450 mL / 1 pt)</span> blood from you within the last 30 days?',
      defaultCopy: 'Has a medical professional taken one unit or more <span style="white-space: nowrap;">(450 mL / 1 pt)</span> blood from you within the last 30 days?',
      metaTitle: "Question 6 of 14",
      metaDescription: "Question 6 of 14",
    },
    answer: {
      arrangement: "inline",
      data: [
        {
          copy: "Yes",
          value: "Yes",
          result: "reject", 
        },
        {
          copy: "No",
          value: "No",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "7",
    questionType: "MultiCheckbox",
    question: {
      subtitle: "Question 7 of 14",
      copy: "Do any of the following statements apply to you today?",
      defaultCopy: "Do any of the following statements apply to you today?",
      description: "Check all that apply",
      metaTitle: "Question 7 of 14",
      metaDescription: "Question 7 of 14",
    },
    answer: {
      arrangement: "separate-multi-answer",
      data: [
        {
          title: "I am pregnant",
          value: "I am pregnant",
          result: "reject",
        },
        {
          title: "I am breastfeeding",
          value: "I am breastfeeding",
          result: "reject",
        },
        {
          title: "I am able to become pregnant and I am not using an effective method of contraception or abstaining from sexual intercourse",
          value: "I am able to become pregnant and I am not using an effective method of contraception or abstaining from sexual intercourse",
          result: "reject",
        },
        {
          title: "None of the above",
          value: "None of the above",
          result: "proceed",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "8",
    questionType: "MultipleChoice",
    question: {
      subtitle: "Question 8 of 14",
      copy: "Do any of the following statements apply to you today?",
      defaultCopy: "Do any of the following statements apply to you today?",
      description: "Choose all that apply",
      metaTitle: "Question 8 of 14",
      metaDescription: "Question 8 of 14",
    },
    answerArrangement: "separate",
    answer: {
      data: [
        {
          copy: "I have received a COVID-19 vaccine within the last 3 months",
          value: "I have received a COVID-19 vaccine within the last 3 months",
          result: "reject",
        },
        {
          copy: "I have had COVID-19 within the last 3 months (confirmed by a home or laboratory test)",
          value: "I have had COVID-19 within the last 3 months (confirmed by a home or laboratory test)",
          result: "reject",
        },
        {
          copy: "I have received convalescent COVID-19 plasma treatment in the past 6 months",
          value: "I have received convalescent COVID-19 plasma treatment in the past 6 months",
          result: "reject",
        },
        {
          copy: "I have received monoclonal antibody treatment for COVID-19 (for example, Evusheld®, REGEN-COV® or Actemra®) in the last 6 months",
          value: "I have received monoclonal antibody treatment for COVID-19 (for example, Evusheld®, REGEN-COV® or Actemra®) in the last 6 months",
          result: "reject",
        },
        {
          copy: "I have received a COVID-19 antiviral for prophylaxis within at least 2 weeks",
          value: "I have received a COVID-19 antiviral for prophylaxis within at least 2 weeks",
          result: "reject",
        },        
        {
          copy: "None of the above",
          value: "None of the above",
          result: "proceed",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "9",
    questionType: "YesOrNo",
    question: {
      subtitle: "Question 9 of 14",
      copy: "Do you have a bleeding disorder or a history of significant bleeding or bruising after injections?",
      defaultCopy: "Do you have a bleeding disorder or a history of significant bleeding or bruising after injections?",
      metaTitle: "Question 9 of 14",
      metaDescription: "Question 9 of 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "YES", 
          value: "YES",
          result: "reject", 
        },
        {
          copy: "NO",
          value: "NO",
          result: "proceed",
        },
        {
          copy: "NOT SURE",
          value: "NOT SURE",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "10",
    questionType: "YesOrNo",
    question: {
      subtitle: "Question 10 of 14",
      copy: "Are you receiving regular immunoglobulin (IV/SC) treatment or any blood products?",
      defaultCopy: "Are you receiving regular immunoglobulin (IV/SC) treatment or any blood products?",
      metaTitle: "Question 10 of 14",
      metaDescription: "Question 10 of 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "YES",
          value: "YES",
          result: "reject", 
        },
        {
          copy: "NO",
          value: "NO",
          result: "proceed",
        },
        {
          copy: "NOT SURE",
          value: "NOT SURE",
          result: "proceed",
        },
      ],
    },
  },    
  {
    url: "11",
    questionType: "MultipleChoice",
    question: {
      subtitle: "Question 11 of 14",
      copy: "Do any of the following statements apply to you today?",
      defaultCopy: "Do any of the following statements apply to you today?",
      description: "Choose all that apply",
      metaTitle: "Question 11 of 14",
      metaDescription: "Question 11 of 14",
    },
    answerArrangement: "separate",
    answer: {
      data: [
        {
          copy: "I have taken a trial drug within the last 90 days",
          value: "I have taken a trial drug within the last 90 days",
          result: "reject",
        },
        {
          copy: "I plan to take a trial drug within the next 16 months",
          value: "I plan to take a trial drug within the next 16 months",
          result: "reject",
        },
        {
          copy: "None of the above",
          value: "None of the above",
          result: "proceed",
          action: "clear",
        },
      ],
    },
  },
  {
    url: "12",
    questionType: "YesOrNo",
    question: {
      subtitle: "Question 12 of 14",
      copy: "This trial will run for 15 months. Will you still be able to attend the clinic during this time?",
      defaultCopy: "This trial will run for 15 months. Will you still be able to attend the clinic during this time?",
      metaTitle: "Question 12 of 14",
      metaDescription: "Question 12 of 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "YES",
          value: "YES",
          result: "proceed",
        },
        {
          copy: "NO",
          value: "NO",
          result: "proceed",
        },
        {
          copy: "NOT SURE",
          value: "NOT SURE",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "13",
    questionType: "YesOrNo",
    question: {
      subtitle: "Question 13 of 14",
      copy: "How far are you willing to travel to a trial site for appointments?",
      defaultCopy: "How far are you willing to travel to a trial site for appointments?",
      metaTitle: "Question 13 of 14",
      metaDescription: "Question 13 of 14",
    },
    answer: {
      arrangement: "separate",
      data: [
        {
          copy: "Up to 20 miles",
          value: "0-20 miles",
          result: "proceed",
        },
        {
          copy: "Up to 40 miles",
          value: "20-40 miles",
          result: "proceed",
        },
        {
          copy: "Up to 60 miles",
          value: "40-60 miles",
          result: "proceed",
        },
        {
          copy: "Up to 80 miles",
          value: "60-80 miles",
          result: "proceed",
        },
        {
          copy: "More than 80 miles",
          value: "80+ miles",
          result: "proceed",
        },
        {
          copy: "I cannot travel",
          value: "I cannot travel",
          result: "proceed",
        },
      ],
    },
  },
  {
    url: "14",
    questionType: "TextInput",
    question: {
      subtitle: "Question 14 of 14",
      copy: "What is your reason for wanting to join a clinical trial? (optional)",
      defaultCopy: "What is your reason for wanting to join a clinical trial? (optional)",
      metaTitle: "Question 14 of 14",
      metaDescription: "Question 14 of 14",
    },
    answer: {
      placeholder: "Type your answer here",
      result: "proceed",
      name: "textarea",
      validation: {
        maxLength: {
          value: 250,
          error: `Please note, your answer can't be more than 250 characters`,
        },
      },
    },
  },
];

export default screenerQuestions;
