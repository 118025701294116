import React, {useEffect, useState, useRef} from 'react';

import { Button } from "@lxlabs/faze-screener-components-library";
import Info from "./assets/info.svg";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {
    StyledBlock,
    Disclaimer,
    TitleBlock,
    ListWrap,
} from './styles';

gsap.registerPlugin(ScrollTrigger);

const BulletListWithImage = ({title, copy, image, button, list}) => {

    const [infoSelected, setInfoSelected] = useState(false);
    const infoSVG = decodeURIComponent(Info);

    const createMarkUp = (content) => {
        return {__html: content};
    }

    useEffect(() => {
        gsap.from(elementRefs, {
            scrollTrigger: elementRefs[0],
            scaleX: 0,
            duration: 1,
            transformOrigin: "left center",
            ease: "ease-in-out",
            stagger: 0.3
        });
    }, []);

    const addInfoBtn = (item, key) => {
        if (item.info) {
            return (
                <span

                    className={
                        key === infoSelected ? 'info active' : 'info'
                    }

                    onClick={() => {
                        if (infoSelected === key) {
                            setInfoSelected(false)
                        } else {
                            setInfoSelected(key)
                        }
                    }}>
          <span
              className={
                  key === infoSelected ? 'speech-bubble active' : 'speech-bubble'
              }
          >{item.info}</span>
        </span>
            )
        } else {
            return (<></>);
        }
    };

    const elementRefs = [];

    const setRef = (ref) => {
        elementRefs.push(ref);
    }

    return (
        <StyledBlock className={'component-BulletListWithImage'}>
            <div className={'container'}>
                <div className={'row'}>
                    <TitleBlock className={`column`}>
                        <img src={image} alt="" className={'desktop'} loading="lazy"/>
                        <h2>{title}</h2>
                        <p dangerouslySetInnerHTML={createMarkUp(copy)}/>
                        <Button
                            cyAttribute={'BulletListWithImage-button'}
                            className={'desktop'}
                            label={button.label}
                            href={button.link}
                            type={'primary'}
                            icon={'ArrowDiagonalUpRight'}
                            cyAttribute={'bullet-list-with-image_sign-up-now-button'}
                        />

                        <img src={image} alt="" className={'mobile'} loading="lazy"/>
                    </TitleBlock>
                    <ListWrap className={`column column--narrow`}
                              bgimg={'/static-images/tick.png'}
                              close={'/static-images/close.png'}
                              info={infoSVG}>
                        <h3>{list.title}</h3>
                        <ul>
                            {list.items.map((item, key) => {

                                return (
                                    <li key={key}>
                                        <span className={'line'} ref={setRef}></span>
                                        <span dangerouslySetInnerHTML={createMarkUp(item.copy)}/>
                                        {addInfoBtn(item, key)}
                                    </li>
                                )
                            })}
                            <li className={'line-only'}><span className={'line'} ref={setRef}></span></li>
                        </ul>
                        {list.disclaimer !== undefined
                            ? <Disclaimer>{list.disclaimer}</Disclaimer>
                            : <></>
                        }

                        <p className={'mobile'} dangerouslySetInnerHTML={createMarkUp(copy)}/>
                        <Button
                            cyAttribute={'BulletListWithImage-button'}
                            className={'mobile sign-up-button'}
                            label={button.label}
                            href={button.link}
                            type={'primary'}
                            icon={'ArrowDiagonalUpRight'}
                        />
                    </ListWrap>
                </div>
            </div>
        </StyledBlock>
    );
}

export default BulletListWithImage;
