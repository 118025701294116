import styled from "styled-components";
import { colors, mediaQueriesSizes } from '../../assets/styles/GlobalStyles';

export const Style = styled.div`
  background: ${({background}) => colors[background] || background};
  padding: 50px 0px;
  
  @media (min-width: ${mediaQueriesSizes.md}px) {
    padding: 100px 0px;
  }

  & + & {
    padding-top: 0.1px;
  }
`