import React, {useRef, useState, useEffect} from 'react';
import Slider from "react-slick";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import {
    Slide,
    NavWrap,
    TextWrap,
    SlideInner,
    StyledBlock,
    PhaseButtons,
    PhaseButtonsWrapper,
    PhaseContainer
} from './styles';

gsap.registerPlugin(ScrollTrigger);

const PhaseSlider = ({phases}) => {

    useEffect(() => {
        gsap.from(buttonsRef.current, {
            scrollTrigger: {
                trigger: buttonsRef.current,
                start: "top 30%",
                ease: "bottom top",
                toggleClass: 'active',
                toggleActions: 'play none reverse none'
            },
        });
    }, []);

    const [activeSlide, setActiveSlide] = useState(0);

    const sliderRef = useRef();
    const buttonsRef = useRef();
    const buttonsWrapperRef = useRef();

    const handleSlideSelect = index => {
        sliderRef.current.slickGoTo(index);
        setActiveSlide(index);
        scrollButtons(index);
    };

    const handleNext = (index) => {
        sliderRef.current.slickNext();
        scrollButtons(index + 1);
    }

    const handlePrev = (index) => {
        sliderRef.current.slickPrev();
        scrollButtons(index - 1);
    }

    const scrollButtons = index => {
        if (index > 1) {
            buttonsWrapperRef.current.scroll({
                left: 500,
                top: 0,
                behavior: 'smooth'
            })
        } else {
            buttonsWrapperRef.current.scroll({
                left: 0,
                top: 0,
                behavior: 'smooth'
            })
        }
    }

    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    swipe: false,
                }
            }
        ],
        afterChange: (index) => {
            setActiveSlide(index);
        }

    };

    return (
        <StyledBlock>
            <div className={'container'}>
                <PhaseContainer>
                    <PhaseButtonsWrapper ref={buttonsWrapperRef}>
                        <PhaseButtons ref={buttonsRef}>
                            {phases.map((slide, key) => {
                                return (
                                    <button
                                        data-cy={`${slide.title}-button`}
                                        className={key === activeSlide ? 'active' : ''}
                                        key={key} onClick={() => {
                                        handleSlideSelect(key);
                                    }}>{slide.title}
                                    </button>
                                )
                            })}
                        </PhaseButtons>
                    </PhaseButtonsWrapper>


                    <Slider {...settings} ref={sliderRef}>

                        {phases.map((slide, key) => {
                            return (
                                <Slide key={key}>
                                    <SlideInner>
                                        <div className={'column image-wrap'}>
                                            <img src={slide.image} alt=""/>
                                        </div>
                                        <div className={'row'}>
                                            <span className={'number'}>0{key + 1}/0{phases.length}</span>
                                            <NavWrap>
                                                <img
                                                    alt={'Previous'}
                                                    className={key === 0 ? 'disabled' : ''}
                                                    onClick={() => {
                                                        handlePrev(key);
                                                    }}
                                                    src={'/static-images/global/arrow-left-black.svg'}
                                                    data-cy="previous-button"/>
                                                <img
                                                    alt={'Next'}
                                                    className={key + 1 === phases.length ? 'disabled' : ''}
                                                    onClick={() => {
                                                        handleNext(key);
                                                    }} src={'/static-images/global/arrow-right-black.svg'}
                                                    data-cy="next-button"/>
                                            </NavWrap>
                                        </div>
                                        <TextWrap>
                                            <div>

                                                <h3>{slide.title}</h3>

                                                <ul>
                                                    {slide.list.map((item, listKey) => {
                                                        return (
                                                            <li key={listKey}>
                                                                <div className={'circle-wrap'}>
                                                                    <img src={item.icon} alt=""/>
                                                                </div>
                                                                <div className={'text-wrap'}>
                                                                    <h4>{item.title}</h4>
                                                                    <p>{item.copy}</p>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>

                                        </TextWrap>
                                    </SlideInner>
                                </Slide>
                            )
                        })}
                    </Slider>
                </PhaseContainer>
            </div>
        </StyledBlock>
    );

}

export default PhaseSlider;
