import React, {useEffect, useState, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
// import { Button } from "@lxlabs/faze-screener-components-library";

import {
  BodyStyle,
  StyledBlock,
  MobileLinksTitle
} from './styles';

const PrivacyPolicy = ({title, linksTitle, links, children}) => {

  // const createMarkUp = (content) => {
  //   return {__html: content};
  // }

  useEffect(() => {

  }, []);

  useLayoutEffect(() => {
    const sections = document.querySelectorAll('[data-scrollspy]');    
    
    if ('IntersectionObserver' in window) {

      for (let section of sections) {

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if(entry.isIntersecting) {

                const { target } = entry;
                const menuItems = document.querySelectorAll('[data-scrollspy-nav] a');
                const menuItemTarget = document.querySelector(`[href="#${target.id}"]`);
            
                menuItems.forEach((item) => {
                    item.classList.remove('is-active');
                });
            
                menuItemTarget.classList.add('is-active');

              }
            });
          },
          {
            rootMargin: "0px 0px -50% 0px",
            threshold: 1
          }
        );
        observer.observe(section);
      }

    }

  }, []);

  const [expandingClass, setExpandingClass] = useState(false);
  // const expandingRef = useRef();

  const toggleExpanding = () => {

    if(expandingClass){
      setExpandingClass(false);
    }else{
      setExpandingClass(true);
    }
  }

  return (
    <StyledBlock>
      <BodyStyle />
      <div className={'container'}>
      <MobileLinksTitle onClick={() => {
                  toggleExpanding()
                }} className={expandingClass ? 'active' : ''}>
        {linksTitle}
        <span></span>
      </MobileLinksTitle>
        <div className={'row'}>
          <div className={'column links-column'}>
            
            <ol className={expandingClass ? 'active' : ''} data-scrollspy-nav>
              {links && links.map((item, key) => {
                  return (
                    <li onClick={() => {
                      toggleExpanding()
                    }} key={key}><a href={`#${item.link}`}>{item.title}</a></li>
                  )
                })
              }
            </ol>
          </div>
          <div className={'column text-column'}>
            <h1>{title}</h1>
            {children}
          </div>         
        </div>
      </div>
    </StyledBlock>
  );

}

PrivacyPolicy.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string
};

PrivacyPolicy.defaultProps = {};

export default PrivacyPolicy;